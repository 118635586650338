import React from "react";
import axios from "axios";
import { userLogout } from "../pages/Login/redux/actions";
import CryptoJS from "crypto-js";

//const BASE_URL = 'http://13.48.115.95:8888/api'; //dev server ip
// const BASE_URL = "http://192.168.151.130:8888/api"; // rahul pc ip
// const BASE_URL = 'http://13.48.82.111:8888/api';     // alpha-staging server ip
const BASE_URL = "https://prod-alb.leadx.no/api"; //prod build
//  const BASE_URL = "https://dev-alb.leadx.no/api";

axios.defaults.withCrendentails = true;

const hashCode = s => {
  return s.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

var axiosInstance = axios.create({
  withCredentials: true,
  timeout: 15000,
  baseURL: BASE_URL,
  "Access-Control-Allow-Credentials": true
  /* other custom settings */
});

axiosInstance.interceptors.request.use(
  async config => {
    // perform a task before the request is sent
    config.headers["Authorization"] = await localStorage.getItem(
      "_access_token_"
    );
    config.headers["phk"] = hashCode(JSON.stringify(config.data));

    // extending the timeout for sales news (add & update) and profile updation for image
    if (
      config.url === `/salesNews/add` ||
      config.url === `/salesNews/update` ||
      config.url === `/user/updateAvatar` ||
      config.url === `/login/checkTokenExist`
    ) {
      config.timeout = 30000;
      return config;
    } else {
      let data = config.data;
      config.data = JSON.stringify({
        sar: CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_CRYPTO_KEY).toString()
      });
      config.headers["Content-Type"] = "application/json;charset=UTF-8";
    }

    return config;
  },
  error => {
    // handle the error
    console.log("error error error",error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  config => {
    // perform a task before the request is sent

    if (config.data.statusCode == 403 || config.data.statusCode == 406) {
      userLogout();
      // ReactDOM.render(<Redirect to="/login" />,"root")
      window.location.replace("/");
    }

    if (config.data.statusCode === 200) {
      let data = config.data.data;
      let bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_CRYPTO_KEY);
      config.data.data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }

    return config;
  },
  error => {
    // handle the error
    console.log("error error error",error);

    return Promise.reject(error);
  }
);
export default axiosInstance;
