import * as types from "./types";

const initialState = {
  salesFeeds: null,
  errorLoadingSalesFeeds: false,
  totalSalesFeed: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INITIALIZE_SALES_FEEDS_SUCCESS:
      return {
        ...state,
        errorLoadingSalesFeeds: false,
        salesFeeds: action.data,
        totalSalesFeed: action.totalSalesFeed
      };

    case types.INITIALIZE_SALES_FEEDS_FAIL:
      return {
        ...state,
        errorLoadingSalesFeeds: true,
        salesFeeds: null
      };

    case types.LOAD_MORE_SALES_FEEDS_SUCCESS:
      return {
        ...state,
        errorLoadingSalesFeeds: false,
        salesFeeds: [...state.salesFeeds, ...action.data]
      };
    case 'MARK_AS_READ_SUCCESS':
      return{
        ...state
      }
    case 'MARK_AS_READ_ERROR':
      return{
        ...state,
      }
    default:
      return state;
  }
};

export default reducer;
