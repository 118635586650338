export const HANDLE_LOADING = 'HANDLE_LOADING';


///resgular expressions
export const emailPattern =    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const imageType = /\.(gif|jpg|jpeg|tiff|png|jfif)$/i      //(imageType).test(gridItem.user.user_avatar) 

//export const name_regex = /^(?![ \'-]*$)[ a-zA-Z]*\'{0,1}[ a-zA-Z-]+$/;

//export const firstName_regex = /^[A-Za-zÆæØøÅåÄäÖöÜü-]+$/;
export const firstName_regex = /^[a-zA-ZøØæÆåÅüÜöÖäÄ]+(\-*[a-zA-ZøØæÆåÅüÜöÖäÄ])*$/;

export const middleName_regex = /^[A-Za-zÆæØøÅåÄäÖöÜü-]*$/;

// export const lastName_regex = /^[A-Za-zÆæØøÅåÄäÖöÜü'-]+$/;
export const lastName_regex = /^([a-zA-ZøØæÆåÅüÜöÖäÄ]+)?([[a-zA-ZøØæÆåÅüÜöÖäÄ'-]{0,1})?[a-zA-ZøØæÆåÅüÜöÖäÄ]+$/;

//export const companyLeadName_regex = /^[a-zA-ZæÆøØåÅ](?:\([a-zA-ZæÆøØåÅ '.-]+\)|\[[a-zA-ZæÆøØåÅ '.-]+]|[a-zA-ZæÆøØåÅ '.-])*$/;

export const companyLeadName_regex = /^[a-zA-Z0-9øØæÆåÅüÜöÖäÄ ',()!@$#%&*+=<>.?|":;\[\]\\\/-]+$/;

export const companyCode_regex = /^[a-zA-Z0-9-_]+$/;

export const zipCode_regex = /^[a-zA-Z0-9]*$/;

export const projectLocation_regex = /^[a-zA-ZøØæÆåÅüÜöÖäÄ]+([a-zA-ZøØæÆåÅüÜöÖäÄ\-\.\,\' ])*$/;

export const leadValue_regex = /^[0-9]+(\.[0-9]{1,2})?$/;