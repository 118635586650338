import React from "react";
import style from "./NewsCard.module.css";
import moment from "moment";
import {imageType} from '../../common/common-types'
import {
  Card, CardActionArea, CardMedia, CardContent,
  Typography, CardActions, Button, Grid
} from '@material-ui/core'

const NewsCard = (props) => {
  return (
    <Card className={style.cardContainer}>
      <Grid container className={style.gridInnerHead}>
          <Grid container  direction="row" xs={12} md={12} lg={12} sm={12} item>
            
            <div className={style.wrapProfileImage} >
                { imageType.test(props.userAvatar) ?
                  <img
                  className={style.profileImage}
                  src={
                    props.userAvatar.slice(0, props.userAvatar.lastIndexOf('/')) + "/small_" +
                    props.userAvatar.substring(props.userAvatar.lastIndexOf("/") + 1)
                  }
                  alt={null}
                /> :
                <img
                  className={style.profileImage}
                  src={require("../../../assets/userProfile.jpeg")}
                  alt={null}
                />
                }
                <Typography className={style.gridUserName} variant="body1">{props.userName}</Typography>
                <Typography className={style.createdAt} variant="caption">{(moment(props.newsTime).fromNow()=="in a few seconds" || moment(props.newsTime).fromNow()=="a few seconds ago" )? "a seconds ago" :moment(props.newsTime).fromNow()}</Typography>
            </div>
            
            
            {/* <Grid  direction="row"  xs={7} md={7} lg={7} sm={7} className={style.wrapProfileImage} item>
                <img
                  className={style.profileImage}
                  src={props.userAvatar}
                  alt={null}
                />
              
                <Typography className={style.gridUserName} variant="body1">{props.userName}</Typography>
            
             
              </Grid>
              <Grid  xs={12} md={4} lg={5} sm={12} className={style.wrapProfileImage} item>
                  <Typography className={style.createdAt} variant="caption">{(moment(props.newsTime).fromNow()=="in a few seconds" || moment(props.newsTime).fromNow()=="a few seconds ago" )? "a seconds ago" :moment(props.newsTime).fromNow()}</Typography>
              </Grid> */}
        </Grid>
      <Grid  item xs={12} md={12} lg={12} sm={12} className={style.gridBanner}>
        <CardMedia
          className={style.salesNewsImage}
          image={
            props.imagePath.slice(0, props.imagePath.lastIndexOf('/')) + "/medium_" +
            props.imagePath.substring(props.imagePath.lastIndexOf("/") + 1)
          }
          width="100%"
          alt={null}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} sm={12} >
        <Typography style={{"WebkitBoxOrient": "vertical"}} className={style.gridText} variant="body1">{props.title}</Typography>
      </Grid>
      {
        props.cardAction && 
          <Grid item xs={12} md={12} lg={12} sm={12} className={style.btnContainer} container>
      
            {props.editButton && <Grid item xs={props.buttonAlignment} md={props.buttonAlignment} lg={props.buttonAlignment} sm={props.buttonAlignment}>
            <Button className={style.btnAll} fullWidth onClick={(e) => {
              e.stopPropagation(); 
              props.editNews({ ...props })
              }}>{props.editButtonText}</Button>

            </Grid>}
          {  props.deleteButton && <Grid item xs={props.buttonAlignment} md={props.buttonAlignment} lg={props.buttonAlignment} sm={props.buttonAlignment}>
            <Button className={style.btnAll} fullWidth onClick={(e) => {e.stopPropagation(); props.deleteNews(props.id) }}>{props.deleteButtonText}</Button>

            </Grid>}
            {props.publishButton && <Grid item xs={props.buttonAlignment} md={props.buttonAlignment} lg={props.buttonAlignment} sm={props.buttonAlignment}>
            <Button className={style.btnAll}  fullWidth onClick={(e) => {e.stopPropagation(); 
              props.PublishNews({ ...props })
            }}>{props.publishButtonText}</Button>

            </Grid>}
          </Grid>
      }
            </Grid>

    </Card>
  )
}

export default NewsCard
NewsCard.defaultProps = {
  editNews: () => console.log("default editNews function"),
  deleteNews: () => console.log("default deleteNews function"),
  PublishNews: () => console.log("default PublishNews function"),
  title: "Demo title",
  newsTime: "2019-06-19T10:15:02.000Z",
  imagePath: "https://lead-x.s3.eu-west-2.amazonaws.com/news1.png",
  userName: "Mr. Alexander Joseph",
  userAvatar: "https://lead-x.s3.eu-west-2.amazonaws.com/pro-0.jpg",
  cardAction: true,
  editButton:true,
  deleteButton:true,
  publishButton:true,
  editButtonText:"Edit",
  deleteButtonText:"Delete",
  publishButtonText:"Publish",
  buttonAlignment:4
}
