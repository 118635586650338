import React, { Component } from "react";
import moment from "moment";
import { Card, CardContent, Grid } from "@material-ui/core";
import Breadcrumbs from "@material-ui/lab/Breadcrumbs";
import { Link } from "react-router-dom";
import styles from "./SalesFeedComponent.module.scss";
import {imageType} from '../../../common/common-types'

class SalesFeedComponent extends Component {
  
  render() {
  const {status, name, namecontent, companyName, salesFeedItem, sameCreator, leadCreated} = this.props.history.location.state;

  let short_name = salesFeedItem.lead.currency ? " "+salesFeedItem.lead.currency.short_name : "";
  let lead_value = salesFeedItem.lead.lead_value ? salesFeedItem.lead.lead_value+short_name : "";
  let is_confidential = salesFeedItem.lead.is_confidential ? true : false;
  let category = salesFeedItem.lead && salesFeedItem.lead.category ? salesFeedItem.lead.category.name.toLowerCase() : "product/service";  
  
  const discription =(status ==1) ? (<><p className={styles.salesFeedDiscription}>
    Congratulate<b>{" "+salesFeedItem.user.name+" "}</b>for signing a contract with
    <b>{" "+salesFeedItem.contact_company.company_name}!</b> The {category} to be delivered is<b>{" "+salesFeedItem.lead.lead_title}</b>.
    
    {/* {salesFeedItem.lead && salesFeedItem.lead.expected_closing_date ?
       (" "+salesFeedItem.user.name+" will work for "+salesFeedItem.contact_company.company_name+" for minimum "
       +moment(salesFeedItem.lead.expected_closing_date).endOf('day').fromNow(true)+".") : null } */}

    {lead_value ? ( is_confidential == true ? "  Value of the contract is confidential." : "  Value of the contract is " +lead_value+".") : null} 
   
    </p>
    
    {
      sameCreator ?
      null :
      <p><br/>The Lead was entered by <b>{leadCreated}</b>.</p>
      
    }
    
    </>
    ) : (status ==2) ? (<p className={styles.salesFeedDiscription}>
    <b>{salesFeedItem.user.name+" "}</b>has entered a new lead with<b>{" "+salesFeedItem.contact_company.company_name}</b>. The {category} to be delivered is<b>{" "+salesFeedItem.lead.lead_title}</b>.
   
    {/* {salesFeedItem.lead && salesFeedItem.lead.expected_closing_date ?
       (" "+salesFeedItem.user.name+" will work for "+salesFeedItem.contact_company.company_name+" for minimum "
       +moment(salesFeedItem.lead.expected_closing_date).endOf('day').fromNow(true)+".") : null } */}
    {lead_value ? ( is_confidential == true ? "  Value of the contract is confidential." : "  Value of the contract is " +lead_value+".") : null} 
  </p>) :
  (<p className={styles.salesFeedDiscription}>
    <b>{salesFeedItem.user.name+" "}</b>has entered a new lead with<b>{" "+salesFeedItem.contact_company.company_name+" "}</b>to be approved by sales. The {category} to be delivered is<b>{" "+salesFeedItem.lead.lead_title}</b>.
    
    {/* {salesFeedItem.lead && salesFeedItem.lead.expected_closing_date ?
       (" "+salesFeedItem.user.name+" will work for "+salesFeedItem.contact_company.company_name+" for minimum "
       +moment(salesFeedItem.lead.expected_closing_date).endOf('day').fromNow(true)+".") : null } */}
    
    {lead_value ? ( is_confidential == true ? "  Value of the contract is confidential." : "  Value of the contract is " +lead_value+".") : null} 
  </p>)
    return (
      <div className={`${styles.main_wrapper}`}>
        <Grid container>
          <Grid item xs={12} md={12} lg={12} sm={12} className="breadcrumbs">
              <h2 className="heading">SALES FEED</h2>
              <div className="breadcrumbs_container">
                <Breadcrumbs separator="›" arial-label="Breadcrumb">
                  <Link to="/sales-news">Home</Link>
                  <span>{status ==1  ?(name +' '+namecontent+' '+companyName+"!") : status ==2 ?(name +' '+namecontent+' '+companyName+".") : status ==3 ? (salesFeedItem.user.name +' '+namecontent+' Sales.') : "NEW lead to be approved by Sales."}</span>
                </Breadcrumbs>
              </div>
          </Grid>

          <Grid item xs={12} md={12} lg={12} sm={12} style={{  marginTop:" 27px"}}>
         
                <Card className={styles.sales_feed}>
                  <div className={status==1 ?`${styles.sales_feed_header} ${styles.notification}`:`${styles.sales_feed_header}`}>
                    <div className={styles.sales_feed_icon_container}>
                      {imageType.test(salesFeedItem.user.user_avatar) ? <img alt="profile"
                        src={
                          salesFeedItem.user && 
                          salesFeedItem.user.user_avatar.slice(0, salesFeedItem.user.user_avatar.lastIndexOf('/')) + "/small_" +
                          salesFeedItem.user.user_avatar.substring(salesFeedItem.user.user_avatar.lastIndexOf("/") + 1)
                        }
                      /> : <img alt="profile"
                      src={require("../../../../assets/userProfile.jpeg")}
                    />}
                    </div>
                    <div className={styles.title_timing}>
                      <p className={styles.heading}>
                        {
                          salesFeedItem.action_type === 3 ?
                          <>
                            <span className={styles.bold_title}>{salesFeedItem.user.name}</span>
                            <span> {namecontent}</span>
                            <span className={styles.bold_title}> Sales</span>
                          </> :
                          <>
                          {name && <span className={styles.bold_title}>{name}</span>}
                        {" "+namecontent+" "}
{                        companyName && <span className={styles.bold_title}>{companyName+(status !=1 ? "." : "!")}</span>} </>
                        }
                      </p>
                      <p className={styles.timing}>{moment(salesFeedItem.created_at).fromNow()}</p>
                    </div>
                  </div>
                  <div className={styles.sales_feed_content}>
                    {discription}
                  </div>
                </Card>
              
          </Grid>


          <Grid>

          </Grid>

        </Grid>
           
            
      </div>
    );
  }
}

export default SalesFeedComponent;
