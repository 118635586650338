import React from 'react'
import styles from './NewLead.module.scss';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import DatePicker from "react-date-picker";
import moment from "moment";
import handshake from '../../../../assets/images/content-images/grey-handshake.png'  //beforeHandshake.png';
import handshake1 from '../../../../assets/images/content-images/one-green-handshake.png';//afterHandshake.png';
import handshake2 from '../../../../assets/images/content-images/two-green-handshake.png';
import { openSnackbar } from '../../../common/Snackbar/snackbar';
import { FormErrors } from '../../../common/formErrors/formErrors';
import CircularProgress from '@material-ui/core/CircularProgress';
import AutoComplete from '../../../common/AutoComplete/autocomplete';
import AutoCompleteWithAdd from '../../../common/AutoCompleteWithAdd/autocompletewithadd';
import Stepper from '../../../common/Stepper/stepper';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Dialog from '@material-ui/core/Dialog';
import LeadDetailPopup from '../../LeadDetailPopup/component/lead-detail-popup';
import add_icon from '../../../../assets/images/content-images/lead_add_icon.png';
import axios from "../../../common/api-config";
import cx from "classnames";
import FormHelperText from "@material-ui/core/FormHelperText";
import { companyLeadName_regex, projectLocation_regex, leadValue_regex } from "../../../common/common-types";

const DialogTitle = withStyles(theme => ({
    root: {
        width: '100%',
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        width: '100%',
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        width: '100%',
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit,
    },
}))(MuiDialogActions);

const getData = (nextProps) => {
    if (window.location.pathname == '/sales-news') {
        const requestBody = {
            arrayFilters: [
                {
                    is_deleted: 0
                }
            ],
            selectFilters: ["created_by", "action_type", "created_at"],
            sort: {
                field: "created_at",
                sortOrder: "DESC"
            },
            paginate: {
                page: 0,
                limit: 6
            },
            // "user_id": nextProps.currentUser.user_id
        };
        nextProps.initializeSalesFeeds(requestBody);
    }
    else if (window.location.pathname == '/sales-community') {
        // let params = {
        //     // "user_id": nextProps.currentUser && nextProps.currentUser.user_id ? nextProps.currentUser.user_id : '',
        //     "from": "sales_community",
        //     "arrayFilters": [{}],
        //     "selectFilters": ["id", "lead_title", "expected_closing_date", "lead_value",
        //         "is_confidential", "notes", "is_hand_over", "is_won", "is_bell_ringed", "project_location"],
        //     "sort": {
        //         "field": "id",
        //         "sortOrder": "DESC"
        //     },
        //     "paginate": {
        //         "page": '',
        //         "limit": ''
        //     }
        // }
        //nextProps.getCommunityLeadList(params)
        let reqParams = {
            revenueType: "sales_community"
        };
        nextProps.getRevenueCommunity(reqParams);
    }
    else if (window.location.pathname == '/my-pipeline') {
        //handled get lead api in my-pipeline with respect to filters

        const revenueParams = {
            "revenueType": "my_pipeline",
            // "user_id": nextProps.currentUser && nextProps.currentUser.user_id ? nextProps.currentUser.user_id : ''
        }
        nextProps.getRevenue(revenueParams);
    }
}

class NewLead extends React.Component {

    formErrors = {
        lead_title: '', company_id: '', contact_id: '', contact_person_phone: '', lead_source_id: '',
        expected_closing_date: '', lead_value: '', is_confidential: '', notes: '', category_id: ''
    };

    constructor(props) {
        super(props);
        this.textInput = null;
        this.state = {
            lead_id: null,
            lead_title: '',
            company_id: '',
            contact_id: '',
            contact_person_country_code: '',
            contact_person_phone: '',
            lead_source_id: '',
            //category
            category_id: '',
            newCategory: '',
            expected_closing_date: '',//moment(new Date()).format('YYYY-MM-DD'),
            lead_value: '',
            currency_id: this.props.currentUser && this.props.currentUser.currency.id,
            is_confidential: 0,
            project_location: '',
            is_hand_over: 0,
            asssignedTo_id: '',
            notes: '',
            lead_status: '',
            lead_status_id: '',
            is_won: null,
            formValid: false,
            errorMessage: [],
            selectedYESRadio: '',
            selectedNORadio: '',
            handshakeImg: handshake,
            stepper1: '',
            stepper2: '',
            stepper3: '',
            stepper4: '',
            stepper5: '',
            currentUser: '',
            selectedWinBtn: '',
            selectedLossBtn: '',
            selectedParkBtn: '',
            open: false,
            personList: [],
            resetPerson: false,
            NoPersonFlag: false
        };
    }


    componentDidMount() {
        this.textInput.focus();
        //get lead source master
        let params = {
            "arrayFilters": [{}],
            "selectFilters": [],
            "sort": {
                "field": "name",
                "sortOrder": "ASC"
            },
            "paginate": {
                "page": '',
                "limit": ''
            }
        };

        this.props.getLeadSources(params);

        // let categoryParams = {
        //     "arrayFilters": [
        //         {

        //         }
        //     ],
        //     "selectFilters": ["id", "name"],
        //     "sort": {
        //         "field": "name",
        //         "sortOrder": "ASC"
        //     },
        //     "paginate": {
        //         "page": 0,
        //         "limit": 100
        //     }
        // };

        // this.props.getCategorySources(categoryParams);


        // this.getCategorySources();




        let reqParams = {
            "arrayFilters": [{}],
            "selectFilters": [],
            "sort": {
                "field": "short_name",
                "sortOrder": "ASC"
            },
            "paginate": {
                "page": '',
                "limit": ''
            }
        }
        this.props.getCurrencyMaster(reqParams);

        if (this.state.is_confidential) {
            this.addClass('yes');
        } else {
            this.addClass('no');
        }

        // fetching data for edit
        if (this.props.currentLead) {
            let currentLead = this.props.currentLead;
            this.setState({
                lead_id: currentLead.id,
                lead_title: currentLead.lead_title ? currentLead.lead_title : '',

                company_id: currentLead.contact_company ? currentLead.contact_company.id + '' : '',
                contact_id: currentLead.contact_person ? currentLead.contact_person.id + '' : '',
                contact_person_country_code: currentLead.contact_person && currentLead.contact_person.countryCode ? currentLead.contact_person.countryCode.dialing_code : '',
                contact_person_phone: currentLead.contact_person && currentLead.contact_person.phone_number ? currentLead.contact_person.phone_number : '',
                lead_value: currentLead.lead_value ? currentLead.lead_value + '' : '',
                currency_id: currentLead.currency && currentLead.currency.id ? currentLead.currency.id : '',
                expected_closing_date: currentLead.expected_closing_date ? moment(currentLead.expected_closing_date).format('YYYY-MM-DD') : '',
                is_confidential: currentLead.is_confidential ? currentLead.is_confidential : 0,
                project_location: currentLead.project_location ? currentLead.project_location : '',
                notes: "", // currentLead.notes && currentLead.notes != null ? currentLead.notes : '',
                old_notes: currentLead.notes ? currentLead.notes : '',
                lead_status: currentLead.lead_status && currentLead.lead_status.name ? currentLead.lead_status.name : '',
                lead_status_id: currentLead.lead_status && currentLead.lead_status.id ? currentLead.lead_status.id : '',
                lead_source_id: currentLead.lead_source && currentLead.lead_source.id ? currentLead.lead_source.id : '',
                // category added
                category_id: currentLead.category && currentLead.category.id ? currentLead.category.id : '',


                is_hand_over: currentLead.is_hand_over !== undefined && currentLead.is_hand_over !== null ? currentLead.is_hand_over : 0,
                createdBy_id: currentLead.createdBy && currentLead.createdBy.id ? currentLead.createdBy.id : 0,
                assignedTo_id: currentLead.assignedTo && currentLead.assignedTo.id ? currentLead.assignedTo.id : 0,

            });

            //get status logs
            let params = {
                lead_id: currentLead.id
            };
            this.props.getStatusLogs(params);
            if (currentLead.is_confidential) {
                this.addClass('yes');
            } else {
                this.addClass('no');
            }

            //get persons, if company is selected
            if (currentLead.contact_company && currentLead.contact_company.id) {
                this.getPersons(currentLead.contact_company.id);
            }

            if (this.props.currentUser && this.props.currentUser.license_type
                && (this.props.currentUser.license_type.actual_name === "MANAGER" || this.props.currentUser.license_type.actual_name === "PROFESSIONAL")
                && currentLead.is_hand_over) {
                this.setState({
                    handshakeImg: handshake1,
                });
            }
        } else if (this.props.currentUser && this.props.currentUser.license_type &&
            (this.props.currentUser.license_type.actual_name === 'MANAGER' || this.props.currentUser.license_type.actual_name === 'PROFESSIONAL')) {
            //if sales person creating new lead, set status to lead(for stepper)
            this.setState({
                lead_status_id: 2,
                lead_status: 'Lead'
            });
        }

        //get assigned to users list is professional licence
        if (this.props.currentUser && this.props.currentUser.license_type &&
            (this.props.currentUser.license_type.actual_name === 'MANAGER' || this.props.currentUser.license_type.actual_name === 'PROFESSIONAL')) {
            this.props.getAssignedToList({});
            this.setState({
                asssignedTo_id: this.props.currentUser.user_id
            });
        }

        //setting the default category to product
        if (this.props.leadCategoryList && (this.props.currentLead === undefined)) {
            this.props.leadCategoryList.find(category => {
                if (category.name.toLowerCase() === 'product/service') {
                    this.setState({
                        category_id: category.id
                    })
                }
            })
        }


    }

    getCategorySources() {
        let categoryParams = {
            "arrayFilters": [
                {

                }
            ],
            "selectFilters": ["id", "name"],
            "sort": {
                "field": "name",
                "sortOrder": "ASC"
            },
            // "paginate": {
            //     "page": 0,
            //     "limit": 100
            // }
        };

        this.props.getCategorySources(categoryParams);
    }



    addCategorySource = async (event) => {
        let value = event.value.trim();
        if (value !== '') {
            let params = {
                "name": value
            }
    
            await axios.post('/master/addCategory', params)
                .then(async (response) => {
                    // console.log(response);
                    if (response && response.data && response.data.statusCode === 200) {
                        //this.forceUpdate();
                        this.formErrors['category_id'] = '';
                        await this.setState({
                            category_id: response.data.data.data.id
                        })
    
    
                    }
                    else {
                        this.formErrors['category_id'] = 'Error adding category, please try again';
                        this.setState({ category_id: "" })
                    }
                })
                .catch(error => {
                    this.formErrors['category_id'] = 'Error adding category, please try again';
                    this.setState({ category_id: "" })
                });
        }
    }

    async getPersons(company_id) {
        let params = {
            "arrayFilters": [
                {
                    "is_deleted": 0,
                    "company_id": company_id ? company_id : 0
                }
            ],
            "selectFilters": ["id", "initials", "first_name", "last_name", "phone_number", "country_code"],
            "sort": {
                "field": "first_name",
                "sortOrder": "ASC"
            },
            "paginate": {
                'page': '',
                'limit': ''
            }
        }

        await axios.post("/contact/person/get", params)
            .then(async (response) => {
                if (response && response.data && response.data.statusCode == 200) {
                    await this.setState({
                        personList: response.data.data.rows,
                        personCount: response.data.data.count
                    });
                } else {
                    await this.setState({
                        personList: [],
                        personCount: 0
                    });
                }

                this.checkPersonValidation();
            })
            .catch(error => {
                console.log(error.errorMessage);
            });
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {

        if (nextProps && nextProps.errorLeadSourceList && nextProps.errorLeadSourceList !== prevState.errorLeadSourceList) {
            nextProps.resetLead();
            nextProps.hideModal();
            openSnackbar("Oops, something went wrong!", 'snack-error');
        }

        nextProps.resetLead();
        if (nextProps && nextProps.lead && !nextProps.leadUpdated && nextProps.leadAdded) {
            openSnackbar("Lead added successfully.", 'snack-success');
            if (nextProps.leadAdded && (nextProps.leadAdded[0].is_won === true || nextProps.leadAdded[0].is_won === 1 || nextProps.leadAdded[0].is_won === 0)) {
                nextProps.leadAdded[0]['lead_status'] = { id: 6, name: 'Closed' };
                nextProps.handleWinCallBack && nextProps.handleWinCallBack(nextProps.leadAdded[0]);
            }
            nextProps.hideModal();
            getData(nextProps);
            return nextProps.lead;
        } else if (nextProps && nextProps.lead && nextProps.leadUpdated) {
            if (nextProps.leadUpdated && (nextProps.leadUpdated[0].is_won === true || nextProps.leadUpdated[0].is_won === 1 || nextProps.leadUpdated[0].is_won === 0)) {
                nextProps.leadUpdated[0]['lead_status'] = { id: 6, name: 'Closed' };
                nextProps.handleWinCallBack && nextProps.handleWinCallBack(nextProps.leadUpdated[0]);
                openSnackbar("Lead updated successfully.", 'snack-success');
            } else {
                // nextProps.hideModal();
                if (nextProps.openLeadDetailCallBack) {
                    nextProps.openLeadDetailCallBack({
                        ...nextProps.leadUpdated[0],
                        // lead_status: { id: 6, name: 'Closed' },
                    });
                }
                openSnackbar("Lead updated successfully.", 'snack-success');
            }
            getData(nextProps);
            return nextProps;
        }
        if (nextProps && nextProps.errorMessage) {
            openSnackbar(nextProps.errorMessage, 'snack-error');
            nextProps.resetLead();
            nextProps.hideModal();
        }
        else if (nextProps && nextProps.errorAddNewLead && nextProps.errorAddNewLead !== prevState.errorAddNewLead) {
            openSnackbar('Oops, something went wrong!', 'snack-error');
            nextProps.resetLead();
            nextProps.hideModal();
        } else if (nextProps && nextProps.errorUpdateLead && nextProps.errorUpdateLead !== prevState.errorUpdateLead) {
            openSnackbar('Oops, something went wrong!', 'snack-error');
            nextProps.resetLead();
            nextProps.hideModal();
        }
        return nextProps;
    }

    componentDidUpdate = async (prevProps, prevState) => {

        if (this.state.category_id !== prevState.category_id) {
            this.getCategorySources();
        }





        if (this.props.newlyAddedCompany !== undefined && this.props.newlyAddedCompany != prevProps.newlyAddedCompany) {
            if (this.props.newlyAddedCompany.id) {
                this.getPersonList({ value: this.props.newlyAddedCompany.id });
                // this.setState({
                //     company_id: this.props.newlyAddedCompany.id
                // });
            }
            this.formErrors['company_id'] = "";
        } else if (this.props.newlyAddedPerson && this.props.newlyAddedPerson != prevProps.newlyAddedPerson) {
            await this.setState({ waitPersonFlag: true });
            await this.getPersonList({ value: this.state.company_id });
            await this.setState({ waitPersonFlag: false });
            // let phone = '';
            // if (this.props.newlyAddedPerson && this.props.newlyAddedPerson.countryCode) {
            //     phone = "+" + this.props.newlyAddedPerson.countryCode.dialing_code + " " + this.props.newlyAddedPerson.phone_number;
            // } else {
            //     phone = this.props.newlyAddedPerson.phone_number;
            // }
            // if (this.props.newlyAddedPerson.id) {

            //     await this.setState({
            //         contact_id: this.props.newlyAddedPerson.id,
            //         contact_person_phone: this.props.newlyAddedPerson.id ? phone : '',
            //         contact_country_code: this.props.newlyAddedPerson.countryCode ? this.props.newlyAddedPerson.countryCode.dialing_code : ''
            //     }, () => {
            //         console.log(this.state.contact_id);
            //     });
            // }
        }
    }

    getPersonList = async event => {
        //reset person selected
        // await this.setState({
        //     resetPerson: true,
        //     contact_id: '',
        //     contact_person_phone: ''
        // })
        this.handleUserInput(event);
        let company_id = 0;
        if (event && event.target) {
            company_id = event.target.value;
        } else if (event && event.value) {
            company_id = event.value;
        }

        await this.setState({
            company_id,
            resetPerson: true,
            contact_id: '',
            contact_person_phone: ''
        });
        await this.getPersons(company_id);
    }

    getPersonPhone = event => {
        this.setState({
            resetPerson: false
        })
        this.handleUserInput(event);
        let contact_id = '';
        if (event && event.target) {
            contact_id = event.target.value;
        } else {
            contact_id = event.value
        }
        var person = this.state.personList.filter((item) => {
            return parseInt(item.id) === parseInt(contact_id);
        });

        let phone = '';
        if (event && event.contact_person_phone) {
            phone = event.contact_person_phone;
            if (person && person[0] && person[0].countryCode && person[0].countryCode.hasOwnProperty('dialing_code')) {
                phone = "+" + person[0].countryCode.dialing_code + " " + event.contact_person_phone;
            }
        }
        this.setState({
            contact_id: contact_id,
            contact_name: event && event.contact_name ? event.contact_name : '',
            contact_country_code: (person && person[0] && person[0].countryCode ? person[0].countryCode.dialing_code : ''),
            contact_person_phone: phone
        });
    }

    setLeadSource = event => {
        this.handleUserInput(event);
    }

    // setCategorySource
    setCategorySource = event => {
        this.handleUserInput(event);
    }


    addClass(key) {
        switch (key) {
            case 'yes':
                this.setState({
                    selectedYESRadio: styles.selectedBox,
                    selectedNORadio: '',
                    is_confidential: 1
                })
                break;
            case 'no':
                this.setState({
                    selectedYESRadio: '',
                    selectedNORadio: styles.selectedBox,
                    is_confidential: 0
                })
                break;
            case 'win':
                this.setState({
                    selectedWinBtn: this.state.selectedWinBtn != '' ? '' : styles.selectedBox,
                    selectedLossBtn: '',
                    selectedParkBtn: '',
                    is_won: this.state.selectedWinBtn != '' ? null : 1,
                    // lead_status_id: this.state.new_lead_status_id,// close lead
                    asssignedTo_id: this.state.currentUser.user_id
                });
                this.formErrors['is_won'] = '';
                break;
            case 'loss':
                this.setState({
                    selectedWinBtn: '',
                    selectedLossBtn: this.state.selectedLossBtn != '' ? '' : styles.selectedBox,
                    selectedParkBtn: '',
                    is_won: this.state.selectedLossBtn != '' ? null : 0,
                    // lead_status_id: this.state.new_lead_status_id,// close lead
                    asssignedTo_id: this.state.currentUser.user_id
                });
                this.formErrors['is_won'] = '';
                break;
            case 'park':
                this.setState({
                    selectedWinBtn: '',
                    selectedLossBtn: '',
                    selectedParkBtn: this.state.selectedParkBtn != '' ? '' : styles.selectedBox,
                    is_won: this.state.selectedParkBtn != '' ? null : 2,
                    // lead_status_id: this.state.new_lead_status_id
                });
                this.formErrors['is_won'] = '';
                break;
            default:
                break;
        }
    }

    handoverToSales() {
        if (this.props.currentUser && this.props.currentUser.license_type
            && (this.props.currentUser.license_type.actual_name === 'MANAGER' || this.props.currentUser.license_type.actual_name === "PROFESSIONAL")) {
            //if handover button is clicked, assignedTo dropdown should have current user selected.
            if (this.state.is_hand_over === 1) {
                this.state.asssignedTo_id = this.props.currentUser.user_id;
            }

            this.setState({
                handshakeImg: this.state.is_hand_over == 2 ? handshake1 : handshake2,
                is_hand_over: this.state.is_hand_over == 2 ? 1 : 2
            });

            this.formErrors['is_hand_over'] = '';
        } else if (this.props.currentUser && this.props.currentUser.license_type &&
            this.props.currentUser.license_type.actual_name === 'BASIC') {
            this.setState({
                handshakeImg: this.state.is_hand_over == 1 ? handshake : handshake1,
                is_hand_over: this.state.is_hand_over && this.state.is_hand_over == 1 ? 0 : (this.state.is_hand_over == 0 ? 1 : 0)
            });
        }
    }

    async addLead() {
        //validate form
        let formValid = this.validateAllFieldsForm();
        var errorArr = Object.values(this.formErrors).filter((error) => {
            return error !== ""
        });
        if (formValid && errorArr.length === 0) {
            //if professional user updating handovered lead, check if he has clicked on handover image, 
            //or he has assigned that lead to some one else
            if (this.props.currentUser && this.props.currentUser.license_type &&
                (this.props.currentUser.license_type.actual_name === 'MANAGER' || this.props.currentUser.license_type.actual_name === 'PROFESSIONAL') &&
                this.state.asssignedTo_id == this.props.currentUser.user_id &&
                this.state.is_hand_over == 1) {
                this.formErrors['is_hand_over'] = 'Please accept handover.';
            } else {
                let params = {
                    // "user_id": this.props.currentUser.user_id,
                    "category_id": this.state.category_id,

                    "lead_source_id": this.state.lead_source_id,
                    "lead_title": this.state.lead_title.trim(),
                    "company_id": this.state.company_id,
                    "expected_closing_date": this.state.expected_closing_date,
                    "lead_value": this.state.lead_value,
                    "currency_id": this.state.currency_id,
                    "is_confidential": this.state.is_confidential,
                    "contact_id": this.state.contact_id,
                    "contact_country_code": this.state.contact_country_code,
                    "contact_person_phone": this.state.contact_person_phone,
                    "project_location": this.state.project_location,
                    "is_hand_over": this.state.is_hand_over,
                    "assigned_to": null,
                    "is_won": this.state.is_won == 1 || this.state.is_won == 0 ? this.state.is_won : (this.state.is_won == 2 ? 2 : null),
                    "is_bell_ringed": 0,
                }

                if (this.state.is_won === 1 || this.state.is_won === true || this.state.is_won === 0)
                    this.setState({
                        lead_status: 'Closed',
                        new_lead_status_id: 6,
                        selectedParkBtn: ''
                    });

                if (this.state.contact_person_phone) {
                    let phoneArr = this.state.contact_person_phone.split(" ");
                    params.contact_person_phone = phoneArr && phoneArr[1] ? phoneArr[1] : ""
                }
                if (this.state.notes && this.state.notes !== '') {
                    params.notes = this.state.notes;
                }
                if (this.state.lead_status_id && this.state.lead_status_id != "") {
                    params.lead_current_status_id = this.state.lead_status_id;
                } else if (this.props.currentUser && this.props.currentUser.license_type &&
                    (this.props.currentUser.license_type.actual_name === 'MANAGER' || this.props.currentUser.license_type.actual_name === 'PROFESSIONAL')) {
                    params.lead_current_status_id = 2;
                } else if (this.props.currentUser && this.props.currentUser.license_type &&
                    this.props.currentUser.license_type.actual_name === 'BASIC') {
                    params.lead_current_status_id = 1;
                }
                //if status updated
                if (this.state.new_lead_status_id && this.state.new_lead_status_id > this.state.lead_status_id) {
                    params.lead_current_status_id = this.state.new_lead_status_id;
                }
                if (this.state.new_lead_status_id && this.state.new_lead_status_id == 6 && this.state.is_won != 1 && this.state.is_won != 0) {
                    this.formErrors['is_won'] = "Please select won or lost status";
                    return;
                }
                //if "New" lead is handovered, change its status to "Lead"
                if (this.props.currentUser && this.props.currentUser.license_type &&
                    (this.props.currentUser.license_type.actual_name === 'MANAGER' || this.props.currentUser.license_type.actual_name === 'PROFESSIONAL') &&
                    this.state.asssignedTo_id == this.props.currentUser.user_id &&
                    this.state.is_hand_over == 2 && this.state.lead_status_id == 1) {
                    params.lead_current_status_id = 2;
                }
                if (this.state.is_won == 1 || this.state.is_won == 0) { //if won/ loss close the lead
                    params.lead_current_status_id = 6;
                }

                if (this.state.lead_id) {
                    params.id = this.state.lead_id;
                    params.is_bell_ringed = 0;
                    params.assigned_to = this.state.asssignedTo_id == '' ? null : this.state.asssignedTo_id;

                    this.setState({
                        submitButton: true
                    });
                    await this.props.updateLead(params);
                    this.props.getPoints();
                } else {
                    //if sales professional adds a lead, assign it to himself or someone else
                    if (this.props.currentUser && this.props.currentUser.license_type
                        && (this.props.currentUser.license_type.actual_name === 'MANAGER' || this.props.currentUser.license_type.actual_name === 'PROFESSIONAL') &&
                        this.state.asssignedTo_id && this.state.asssignedTo_id != '') {
                        params.assigned_to = this.state.asssignedTo_id == '' ? null : this.state.asssignedTo_id;
                    }
                    this.setState({
                        submitButton: true
                    })
                    await this.props.addLead(params);
                    this.props.getPoints();
                    let fromValue = window.location.pathname === '/sales-community' ? 'sales-community' : window.location.pathname === '/my-pipeline' ? 'my_pipeline' : '';
                    const filterVals = {};
                    if (fromValue === 'my_pipeline' && this.props.parkedSelected) {
                        filterVals.is_won = 2;
                    }
                    if (this.props.filterList.length !== 0) {
                        filterVals.lead_current_status_id = this.props.filterList
                    }

                    if (fromValue !== '') {
                        let params1 = {
                            from: fromValue,
                            arrayFilters: [filterVals],
                            selectFilters: [
                                "id",
                                "lead_title",
                                "expected_closing_date",
                                "lead_value",
                                "is_confidential",
                                "notes",
                                "is_hand_over",
                                "is_won",
                                "is_bell_ringed",
                                "project_location",
                                "created_at"
                            ],
                            sort: {
                                field: "id",
                                sortOrder: "DESC"
                            },
                            paginate: {
                                page: fromValue === "sales-community" ? "0" : "",
                                limit: fromValue === "sales-community" ? "10" : "",
                            }
                        };
                        this.props.getPipelineLeadList(params1);
                    }
                }
            }
        }
    }

    handleUserInput(e) {
        if (e && e.target) {
            const name = e.target.name;
            const value = e.target.value;
            this.setState({ [name]: value },
                () => { this.validateField(name, value) });
        } else if (e && e.name) {
            const name = e.name;
            const value = e.value;
            this.setState({ [name]: value },
                () => { this.validateField(name, value) });
        }
    }



    handleDateChange = date => {
        this.setState({
            expected_closing_date: moment(date).format("YYYY-MM-DD")
        }, () => { this.validateField('expected_closing_date', date) });
    }

    checkHandoverValidation(e) {
        if (this.formErrors['is_hand_over'] !== '') {
            this.formErrors['is_hand_over'] = '';
        }
    }

    validateAllFieldsForm() {        
        if (this.state.lead_title.trim() === '') {
            this.formErrors['lead_title'] = 'This field is required';
        } else if (!this.state.lead_title.match(companyLeadName_regex)) {            ///^(?![0-9]*$)[a-zA-Z0-9]+$/)) {
            // \w matches letters, digits, and underscores
            // \s matches spaces, tabs, and line breaks
            // - matches the hyphen
            this.formErrors['lead_title'] = "Please enter valid lead title."
        }
        if (this.state.company_id === '' || this.state.company_id === 0) {
            this.formErrors['company_id'] = 'This field is required';
        }
        // if (this.state.contact_id === '' || this.state.contact_id === 0) {
        //     this.formErrors['contact_id'] = 'This field is required';
        // }
        // if(this.state.contact_person_phone.trim() === ''){
        //     this.formErrors['contact_person_phone'] = 'This field is required';
        // }
        // if (this.state.lead_source_id === '' || this.state.lead_source_id === 0) {
        //     this.formErrors['lead_source_id'] = 'This field is required';
        // }
        // if (this.state.expected_closing_date == '') {
        //     this.formErrors['expected_closing_date'] = 'This field is required';
        // }
        // if (!this.state.lead_value.match(leadValue_regex)) {
        //     this.formErrors['lead_value'] = 'This field is invalid';
        // }
        if (this.state.lead_value.trim() !== '' && (this.state.currency_id === '' || this.state.currency_id === 0)) {
            this.formErrors['currency_id'] = 'This field is required';
        }
        // if (this.state.project_location === '') {
        //     this.formErrors['project_location'] = "This field is required";
        // }
        // if (this.state.notes.trim() === '') {
        //     this.formErrors['notes'] = 'This field is required';
        // }

        // if(this.state.category_id === "" || this.state.category_id === 0) {
        //     this.formErrors['category_id'] = 'This field is required';
        // }

        var errorArr = Object.values(this.formErrors).filter((error) => {
            return error !== ""
        })
        if (errorArr.length === 0) {
            this.setState({
                formValid: true
            });
            return true;
        } else {
            this.setState({
                formValid: false
            })
        }
        return false;
    }

    validateField(fieldName, value) {
        let leadFormValid = true;

        switch (fieldName) {
            case 'lead_title':
                //let regexName =  /[a-zA-Z_][a-zA-Z0-9_]*/i; // /^(?![0-9 ]*$)[a-zA-Z0-9 ]+$/; // /^[a-zA-Z][a-zA-Z\s]*$/;
                if (value.trim() === '') {
                    this.formErrors['lead_title'] = 'This field is required';
                    leadFormValid = false;
                }
                //else if (!value.match(regexName) || !value.match(/^[^\s]+(\s+[^\s]+)*$/i)) {
                else if (!value.match(companyLeadName_regex)) {
                    this.formErrors['lead_title'] = 'This field is invalid';
                    leadFormValid = false;
                } else if (value.length > 50) {
                    this.formErrors['lead_title'] = 'Max length is 50 characters.';
                    leadFormValid = false;
                } else {
                    this.formErrors['lead_title'] = '';
                }
                break;
            case 'company_id':
                if (value === '') {
                    this.formErrors['company_id'] = 'This field is required';
                    leadFormValid = false;
                }
                else {
                    this.formErrors['company_id'] = '';
                }
                break;
            case 'contact_id':
                if (typeof value === "number") {
                    this.formErrors['contact_id'] = '';
                }
                break;
            case 'lead_source_id':
                if (typeof value === "number") {
                    this.formErrors['lead_source_id'] = '';
                }
                
                break;

            // case 'expected_closing_date':
            //     if (value === '') {
            //         this.formErrors['expected_closing_date'] = 'This field is required';
            //         leadFormValid = false;
            //     }
            //     else {
            //         this.formErrors['expected_closing_date'] = '';
            //     }
            //     break;

            case 'category_id':
                    // if (value === '') {
                    //     this.formErrors['company_id'] = 'This field is required';
                    //     leadFormValid = false;
                    // }
                    // else {
                    //     this.formErrors['company_id'] = '';
                    // }
                    // break;
               
                if (value) {
                    this.formErrors['category_id'] = '';
                    this.setState({ formValid: true })
                }
                break;

            case 'lead_value':
                if (value.trim() === '') {
                    this.formErrors['lead_value'] = "";
                } else if (!value.match(leadValue_regex)) {
                    this.formErrors['lead_value'] = 'This field is invalid';
                    leadFormValid = false;
                }
                else {
                    this.formErrors['lead_value'] = '';
                }
                break;
            case 'currency_id':
                if (this.state.lead_value !== '' && value.trim() === '') {
                    this.formErrors['currency_id'] = 'This field is required';
                    leadFormValid = false;
                }
                else {
                    this.formErrors['currency_id'] = '';
                }
                break;
            case 'project_location':
                if (value.trim() === '') {
                    this.formErrors['project_location'] = "";
                } else if (!value.match(projectLocation_regex)) {
                    this.formErrors['project_location'] = 'This field is invalid';
                    leadFormValid = false;
                } else {
                    this.formErrors['project_location'] = '';
                }
                break;
            // case 'notes':
            //     if (value.trim() === '') {
            //         this.formErrors['notes'] = 'This field is required';
            //         leadFormValid = false;
            //     }
            //     else {
            //         this.formErrors['notes'] = '';
            //     }
            //     break;
            default:
                break;
        }
        this.setState({
            formValid: leadFormValid
        });
    }

    errorClass(error) {
        return (error.length === 0 ? '' : styles.has_error);
    }

    goToNextStatus() {
        switch (this.state.lead_status) {
            case "":
                if (this.props.currentUser && this.props.currentUser.license_type &&
                    (this.props.currentUser.license_type.actual_name === 'MANAGER' || this.props.currentUser.license_type.actual_name === 'PROFESSIONAL')) {
                    this.setState({
                        lead_status: 'Lead',
                        new_lead_status_id: 2,
                    })
                }
                break;
            case 'Lead':
                this.setState({
                    lead_status: 'Opportunity',
                    new_lead_status_id: 3,
                });
                break;
            case 'Opportunity':
                this.setState({
                    lead_status: 'Proposal',
                    new_lead_status_id: 4,
                });
                break;
            case 'Proposal':
                this.setState({
                    lead_status: 'Negotiation',
                    new_lead_status_id: 5,
                });
                break;
            case 'Negotiation':
                this.setState({
                    lead_status: 'Closed',
                    new_lead_status_id: 6,
                    selectedParkBtn: ''
                });
                break;
            default:
                break;
        }
        this.formErrors['is_won'] = "";
    }

    goToPrevStatus() {
        if (this.state.new_lead_status_id && this.state.new_lead_status_id > this.state.lead_status_id) {
            switch (this.state.lead_status) {
                case 'Opportunity':
                    this.setState({
                        lead_status: 'Lead',
                        new_lead_status_id: 2,
                    });
                    break;
                case 'Proposal':
                    this.setState({
                        lead_status: 'Opportunity',
                        new_lead_status_id: 3,
                    });
                    break;
                case 'Negotiation':
                    this.setState({
                        lead_status: 'Proposal',
                        new_lead_status_id: 4,
                    });
                    break;
                case 'Closed':
                    this.setState({
                        lead_status: 'Negotiation',
                        new_lead_status_id: 5,
                    });
                    break;
                default:
                    break;
            }
        }
        this.formErrors['is_won'] = "";
    }

    openCompanyPopup() {
        this.props.openCompanyPopup();
    }

    openPersonPopup() {
        if (!this.state.company_id || this.state.company_id === '' || this.state.company_id === null) {
            this.formErrors['company_id'] = "Please select company";
            this.setState({
                formValid: false
            });
        } else {
            let selectedCompany = this.props.companyList.filter((company, index) => {
                return company.id == this.state.company_id;
            });
            this.props.openPersonPopup(this.state.company_id, selectedCompany[0]);
        }
    }

    checkValidCompany(event, newValue, autocompleteName) {
        if (newValue && newValue != "") {
            let selectedCompany = this.props.companyList.filter((company, index) => {
                return company.company_name == newValue;
            });
            if (!selectedCompany || selectedCompany.length == 0) {
                this.formErrors['company_id'] = "Please select company from suggestions";
                this.formErrors['contact_id'] = "";
                this.setState({
                    contact_id: "",
                    formValid: false,
                    personList: [],
                    contact_person_country_code: '',
                    contact_person_phone: '',
                    resetPerson: true
                });
            }
        } else {
            this.formErrors['company_id'] = "This field is required";
            this.formErrors['contact_id'] = "";
            this.setState({
                contact_id: "",
                formValid: false,
                personList: [],
                contact_person_country_code: '',
                contact_person_phone: '',
                resetPerson: true
            });
        }
        if (!this.state.company_id || this.state.company_id === 0
            || this.state.company_id === "") {
            this.formErrors['company_id'] = "Please select company from suggestions";
            this.formErrors['contact_id'] = "";
            this.setState({
                contact_id: null,
                formValid: false,
                personList: [],
                contact_person_country_code: '',
                contact_person_phone: '',
                resetPerson: true
            });
        }
    }

    checkValidPerson(event, newValue, autoCompleteName) {
        if (newValue && newValue != "") {
            let selectedPerson = this.state.personList.filter((person) => {
                return person.name == newValue;
            });
            if (!selectedPerson || selectedPerson.length == 0) {
                this.formErrors['contact_id'] = "Please select person from suggestions.";
                this.setState({
                    formValid: true,
                    contact_person_country_code: '',
                    contact_person_phone: ''
                });
            }
        } else {
            if (newValue === "") this.setState({
                contact_id: '',
                contact_person_country_code: '',
                contact_person_phone: '',
            }, () => {
                if (this.state.personList.length == 0) {
                    this.formErrors['contact_id'] = "No persons added for this company.";
                    this.setState({
                        formValid: true,
                        contact_person_country_code: '',
                        contact_person_phone: ''
                    });
                } else if (!this.state.contact_id || this.state.contact_id === 0 || this.state.contact_id === "") {
                    this.formErrors['contact_id'] = ""; //"Please select person from suggestions.";
                    this.setState({
                        formValid: true,
                        contact_person_country_code: '',
                        contact_person_phone: ''
                    });
                } else {
                    this.formErrors['contact_id'] = '';
                    this.setState({
                        // NoPersonFlag: false,
                        formValid: true
                    });
                }
            });
        }
    }

    checkPersonValidation() {
        if (this.state.personList.length == 0) {
            this.setState({
                NoPersonFlag: true
            })
        } else {
            this.formErrors['contact_id'] = '';
            this.setState({
                NoPersonFlag: false,
                formValid: true
            });
        }
    }

    checkValidLeadSource = (event, newValue, autoCompleteName) => {
        if (newValue && newValue != "") {
            let selectedLeadSource = this.props.leadSourceList.filter((leadSource) => {
                return leadSource.name == newValue;
            });

            if (!selectedLeadSource || selectedLeadSource.length == 0) {
                this.formErrors['lead_source_id'] = "Please select lead source from suggestions";
                this.setState({
                    lead_source_id: ''
                });
            }
        } else {
            // if (!this.state.lead_source_id || this.state.lead_source_id === 0 || this.state.lead_source_id === "") {
            //     this.formErrors['lead_source_id'] = "Please select lead source from suggestions.";
            //     this.setState({
            //         formValid: false
            //     });
            // }else{
            this.formErrors['lead_source_id'] = '';
            this.setState({
                lead_source_id: ''
            });
            // }
        }
    }

    // check valid lead category
    checkValidCategorySource = (event, newValue, autoCompleteName) => {
        if (newValue && newValue != "") {
            this.formErrors['category_id'] = "Please select category or add new";
            this.setState({
                formValid: false
            });
        } else {
            this.formErrors['category_id'] = "";

            this.setState({
                formValid: true,
                category_id: ''
            });
        }
    }

    dateChangeHandler = e => { };

    toggleCalendar = () => {
        if (this.state.isOpen) {
            this.setState({
                flag: "calendar",
                isOpen: false
            });
        } else {
            this.setState({
                flag: "calendar",
                isOpen: true
            });
        }
    };

    render() {
        const stepper = this.state.lead_id ||
            (this.props.currentUser && this.props.currentUser.license_type
                && (this.props.currentUser.license_type.actual_name == 'MANAGER' || this.props.currentUser.license_type.actual_name == 'PROFESSIONAL')) ?
            <div>
                <div className={styles.lead_stepper}>
                    <div >Pipeline stage</div>
                    <Stepper
                        currentSalesItem={this.props.currentLead} lead_status={this.state.lead_status}
                        statusLogs={this.props.statusLogs} />

                    <div className={styles.stepper_btns + " row wrap_prev_next"}>
                        <div className="div_prev">
                            <button type="button" value="prev"
                                onClick={() => this.goToPrevStatus()}
                                disabled={(!this.state.new_lead_status_id || (this.state.new_lead_status_id && this.state.new_lead_status_id <= this.state.lead_status_id))}
                                className={styles.stepper_btn + ' ' + styles.prev_stepper_btn + " button_prev"}>
                                <ArrowBack className={styles.backArrow} />
                                Prev
                                </button>
                        </div>
                        <div className={styles.status_name + " div_middle"}>
                            {this.state.lead_status}
                        </div>
                        <div className="div_next">
                            <button type="button" value="next"
                                onClick={() => this.goToNextStatus()}
                                className={styles.stepper_btn + ' ' + styles.next_stepper_btn + " button_next"}
                            >
                                Next
                                    <ArrowForward className={styles.nextArrow} />
                            </button>
                        </div>
                    </div>
                </div>
                {/* win , loss, park buttons */}
                <div className="row">
                    <div className="col-md-4">
                        <div id="win" className={this.state.selectedWinBtn + ' ' + styles.leadStatusBtns}
                            onClick={() => this.addClass('win')}>Won</div>
                    </div>
                    <div className="col-md-4">
                        <div id="loss"
                            className={this.state.selectedLossBtn + ' ' + styles.leadStatusBtns}
                            onClick={() => this.addClass('loss')}>Lost</div>
                    </div>
                    <div className="col-md-4">
                        <div id="park"
                            className={
                                cx(this.state.selectedParkBtn, styles.leadStatusBtns, {
                                    [styles.disabled_parkBtn]: this.state.new_lead_status_id == 6,
                                })}
                            onClick={() => this.addClass('park')}>
                            Parked
                        </div>
                    </div>
                </div>
                <FormErrors formErrors={this.formErrors} field='is_won' />
            </div>
            : '';

        return (
            <div>
                <DialogTitle id="customized-dialog-title" onClose={this.props.hideModal}>
                    <div className={styles.lead_dialog_title}>
                        {this.state.lead_id ? 'Update / Edit lead (' + this.state.lead_id + ')' : 'New lead'}
                    </div>
                </DialogTitle>
                <DialogContent className={styles.lead_dialog_content + " lead_dialog_content_remove_overflow-y"}>
                    <div className={styles.lead_content}>
                        {/* stage stepper */}
                        {
                            this.state.lead_status === 'New' ? '' : stepper
                        }
                        <div className="row">
                            <div className="col-md-6 col-lg-3">
                                <div className={styles.form_group}>
                                    <div className={styles.lead_char}>
                                        <label className={styles.form_title}>Lead title<span className={styles.required_field}>*</span></label>
                                        <div className="notesHint">{(50 - this.state.lead_title.length) + " characters left"}</div>
                                    </div>
                                    <div>
                                        <input
                                            id="outlined-name"
                                            name="lead_title"
                                            // placeholder="Enter lead title"
                                            className={styles.inputStyle}
                                            value={this.state.lead_title}
                                            onChange={(event) => this.handleUserInput(event)}
                                            margin="normal"
                                            variant="outlined"
                                            autoComplete="off"
                                            minLength="2"
                                            maxLength="50"
                                            ref={elem => (this.textInput = elem)}
                                        />
                                    </div>
                                    <FormErrors formErrors={this.formErrors} field='lead_title' />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className={styles.form_group + " row"}>
                                    <div className="col-md-10 col-sm-10">
                                        <label className={styles.form_title}>Company<span className={styles.required_field}>*</span></label>
                                        <div>
                                            <AutoComplete
                                                suggestions={this.props.companyList}
                                                autoCompleteName="company"
                                                placeholder="Select company name"
                                                getPersonList={this.getPersonList}
                                                value={this.state.company_id}
                                                onBlur={() => this.checkValidCompany()}
                                                disabled={this.state.lead_id ? true : false}
                                                newlyAddedCompany={this.props.newlyAddedCompany}
                                                onChange={(event, newValue, autocompleteName) => { this.checkValidCompany(event, newValue, autocompleteName) }}
                                            />
                                        </div>
                                        <FormErrors formErrors={this.formErrors} field='company_id' />
                                    </div>
                                    <div className={"col-md-2 col-sm-2 " + styles.add_icon_section + " " + (this.state.lead_id ? styles.disable_add_icon : '')} onClick={() => this.openCompanyPopup()}>
                                        <img src={add_icon} className={styles.add_company_icon} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className={styles.form_group + " row"}>
                                    <div className="col-md-10 col-sm-10">
                                        <label className={styles.form_title}>Contact person name</label>
                                        <AutoComplete
                                            suggestions={this.state.personList}
                                            autoCompleteName="person"
                                            resetPerson={this.state.resetPerson}
                                            placeholder="Select contact person"
                                            name="contact_id"
                                            id="personID"
                                            disabled={(!this.state.personList.length || this.state.NoPersonFlag) || this.state.waitPersonFlag}
                                            getPersonPhone={this.getPersonPhone}
                                            // onBlur={() => this.checkValidPerson()}
                                            value={this.state.contact_id}
                                            onChange={(event, newValue, autoCompleteName) => this.checkValidPerson(event, newValue, autoCompleteName)}
                                        />

                                        {
                                            this.state.NoPersonFlag
                                                ?
                                                <FormHelperText className={styles.helperText}>
                                                    Please add person
                                            </FormHelperText>
                                                : ""
                                        }
                                        {!this.state.NoPersonFlag && <FormErrors formErrors={this.formErrors} field='contact_id' />}
                                    </div>
                                    <div className={"col-md-2 col-sm-2 " + styles.add_icon_section} onClick={() => this.openPersonPopup()}>
                                        <img src={add_icon} className={styles.add_company_icon} />
                                    </div>
                                </div>
                            </div>
                            {/* </div>
                        <div className="row"> */}
                            <div className={styles.form_group + " col-md-6 col-lg-3"}>
                                <label className={styles.form_title}>Contact person phone</label>
                                <input className={styles.inputStyle} type="text"
                                    // value={(this.state.contact_person_country_code ? "+" : "") + this.state.contact_person_country_code + " " + this.state.contact_person_phone}
                                    value={(this.state.contact_person_phone.trim().charAt(0) !== "+" && this.state.contact_person_phone ?
                                        (this.state.contact_person_country_code ? "+" : "") + this.state.contact_person_country_code : "")
                                        + " " + this.state.contact_person_phone}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-lg-3 p_position remove_scrollY_pop_date">
                                <label className={styles.form_title}>Expected closing date</label>
                                <div className={styles.datepicker + ' ' + styles.form_group}>
                                    <input
                                        placeholder="yyyy-mm-dd"
                                        value={this.state.expected_closing_date}
                                        className={styles.inputStyle + " add_cursor_pointer"}
                                        onClick={this.toggleCalendar}
                                        onChange={this.dateChangeHandler}
                                    />
                                    <DatePicker
                                        calendarClassName={styles.dateLayout}
                                        value={this.state.expected_closing_date !== "" ?
                                            new Date(
                                                moment(this.state.expected_closing_date).year(),
                                                moment(this.state.expected_closing_date).month(),
                                                moment(this.state.expected_closing_date).date()
                                            )
                                            : this.state.expected_closing_date
                                        }
                                        clearIcon={null}
                                        calendarIcon={null}
                                        isOpen={this.state.isOpen}
                                        onCalendarClose={this.toggleCalendar}
                                        onChange={(event) => this.handleDateChange(event)}
                                        minDate={new Date()}
                                        maxDate={new Date(moment().add(20, 'years'))}
                                    />
                                    {/* <DatePicker
                                        className={styles.selectCompany + ' ' + styles.datepicker_select}
                                        selected={this.state.expected_closing_date}
                                        name="expected_closing_date"
                                        onChange={(event) => this.handleDateInput(event)}
                                        minDate={new Date()}
                                    />
                                     */}
                                </div>
                                <i className={styles.date_picker_icon}></i>
                                <FormErrors formErrors={this.formErrors} field='expected_closing_date' />
                            </div>

                            {/* category added */}
                            <div className="col-md-6 col-lg-3">
                                <div className={styles.form_group}>
                                    <label className={styles.form_title}>Category</label>
                                    <div>
                                        <AutoCompleteWithAdd
                                            suggestions={this.props.leadCategoryList}
                                            autoCompleteName="leadCategory"
                                            placeholder="Select category name"
                                            name="category_id"
                                            setCategorySource={this.setCategorySource}
                                            addCategorySource={this.addCategorySource}
                                            value={this.state.category_id}
                                            onChange={(event, newValue, autoCompleteName) => this.checkValidCategorySource(event, newValue, autoCompleteName)}
                                        />
                                    </div>
                                    <FormErrors formErrors={this.formErrors} field='category_id' />
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3">
                                <div className="row">
                                    <div className={styles.form_group + " col-md-8"}>
                                        <label className={styles.form_title}>Lead value</label>
                                        <input
                                            className={styles.inputStyle}
                                            type="text"
                                            name="lead_value"
                                            //placeholder="Enter lead value"
                                            value={this.state.lead_value}
                                            onChange={(event) => this.handleUserInput(event)}
                                            minLength="2"
                                            maxLength="10"
                                            autoComplete="off"
                                        />
                                        <FormErrors formErrors={this.formErrors} field='lead_value' />
                                    </div>

                                    <div className={styles.pt_20 + " col-md-4 " + styles.form_group + ' ' + styles.pl_0} >
                                        <label></label>
                                        <select className={styles.selectCompany + " " + styles.currency_field} name="currency_id" value={this.state.currency_id}
                                            onChange={(event) => this.handleUserInput(event)}>
                                            <option hidden>Select</option>
                                            {
                                                this.props.currencyList && this.props.currencyList.length > 0 ?
                                                    this.props.currencyList.map((item, index) => {
                                                        return (
                                                            <option value={item.id} key={'currency_' + index}>{item.short_name}</option>
                                                        );
                                                    }) :
                                                    ''
                                            }
                                        </select>
                                        <FormErrors formErrors={this.formErrors} field='currency_id' />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3">
                                <label className={styles.form_title}>Is the lead value confidential?</label>
                                <div className={styles.form_group + " row"}>
                                    <div className={styles.yesno_btn} onClick={() => this.addClass('yes')}>
                                        <div id="yes-radio" className={`${styles.lead_radio} ${this.state.selectedYESRadio} ${this.state.lead_value !== '' && this.formErrors.lead_value === '' ? '' : styles.disabled_yes_no}`}>Yes</div>
                                    </div>
                                    <div className={styles.yesno_btn} onClick={() => this.addClass('no')}>
                                        <div id="no-radio" className={`${styles.lead_radio} ${this.state.selectedNORadio} ${this.state.lead_value !== '' && this.formErrors.lead_value === '' ? '' : styles.disabled_yes_no}`}>No</div>
                                    </div>
                                </div>
                                <FormErrors formErrors={this.formErrors} field='is_conficential' />
                            </div>

                        </div>
                        <div className="row">
                            {/* project location */}

                            <div className="col-md-6 col-lg-3">
                                <label className={styles.form_title}>Project location</label>
                                <div className={styles.form_group}>
                                    <input
                                        id="project_location"
                                        name="project_location"
                                        //placeholder="Enter project location"
                                        className={styles.inputStyle}
                                        value={this.state.project_location}
                                        onChange={(event) => this.handleUserInput(event)}
                                        margin="normal"
                                        variant="outlined"
                                        autoComplete="off"
                                        maxLength="50"
                                    />
                                </div>
                                <FormErrors formErrors={this.formErrors} field='project_location' />
                            </div>

                            <div className="col-md-6 col-lg-3">
                                <div className={styles.form_group}>
                                    <label className={styles.form_title}>Lead source</label>
                                    <div>
                                        <AutoComplete
                                            suggestions={this.props.leadSourceList}
                                            autoCompleteName="leadSource"
                                            placeholder="Select lead source name"
                                            name="lead_source_id"
                                            // onBlur={() => this.checkValidLeadSource()}
                                            setLeadSource={this.setLeadSource}
                                            value={this.state.lead_source_id}
                                            onChange={(event, newValue, autoCompleteName) => this.checkValidLeadSource(event, newValue, autoCompleteName)}
                                        />
                                        {/* <select
                                            className={styles.selectCompany}
                                            value={this.state.lead_source_id}
                                            name="lead_source_id"
                                            onChange={(event) => this.handleUserInput(event)}>
                                            <option hidden>Select lead source</option>
                                            {
                                                this.props.leadSourceList && this.props.leadSourceList.length > 0 ?
                                                    this.props.leadSourceList.map((item, index) => {
                                                        return (
                                                            <option value={item.id} key={'lead_source_' + index}>{item.name}</option>
                                                        )
                                                    })
                                                    : ''
                                            }
                                        </select> */}
                                    </div>
                                    <FormErrors formErrors={this.formErrors} field='lead_source_id' />
                                </div>
                            </div>
                            {
                                ((   //for basic user
                                    this.props.currentUser && this.props.currentUser.license_type
                                    && this.props.currentUser.license_type.actual_name === 'BASIC'
                                ) ||
                                    (   //for sales manager
                                        this.props.currentUser && this.props.currentUser.license_type
                                        && this.props.currentUser.license_type.actual_name === 'MANAGER' &&
                                        (this.state.is_hand_over == 1 || this.state.is_hand_over == 2) && this.state.lead_status_id == 1
                                        && (this.props.currentUser.user_id == this.state.asssignedTo_id)
                                    ) ||
                                    (   //for sales professional
                                        this.props.currentUser && this.props.currentUser.license_type
                                        && this.props.currentUser.license_type.actual_name === 'PROFESSIONAL' &&
                                        (this.state.is_hand_over == 1 || this.state.is_hand_over == 2) && this.state.lead_status_id == 1 &&
                                        this.state.assignedTo_id == this.props.currentUser.user_id
                                    ))
                                    ?
                                    <div className={styles.form_group + " col-md-2"}>
                                        <label className={styles.form_title}>
                                            {
                                                (this.props.currentUser && this.props.currentUser.license_type
                                                    &&
                                                    this.props.currentUser.license_type.actual_name === 'BASIC') ?
                                                    "Handover to sales?" :
                                                    "Accept as lead"
                                            }
                                        </label>
                                        <div className="row ml-0">
                                            <div className={' col-md-12 ' + styles.form_group + ' ' + styles.handover_btn}>
                                                <Button
                                                    className={styles.handshakeBtn}
                                                    onClick={() => this.handoverToSales()}
                                                >
                                                    <img src={this.state.handshakeImg} alt="handshake" width="150" height="50" />
                                                </Button>
                                            </div>
                                        </div>
                                        <FormErrors formErrors={this.formErrors} field='is_hand_over' />
                                    </div>
                                    : ''
                            }
                            {
                                (
                                    this.props.currentUser && this.props.currentUser.license_type
                                    && (this.props.currentUser.license_type.actual_name == 'MANAGER' || this.props.currentUser.license_type.actual_name == 'PROFESSIONAL')
                                )
                                    ?
                                    <>
                                        <div className={styles.form_group + " col-md-6 col-lg-3"}>
                                            <label className={styles.form_title + " test-assign-margin-bottom"}>Assign to<span className={styles.required_field}>*</span></label>
                                            <div>
                                                <select
                                                    className={`${styles.selectCompany} ${(this.state.handshakeImg == handshake2) || (this.state.is_won == 1 || this.state.is_won == 0) ? styles.disabled_assignedToBtn : ''} `}
                                                    value={this.state.asssignedTo_id}
                                                    name="asssignedTo_id"
                                                    onChange={(event) => { this.handleUserInput(event); this.checkHandoverValidation(event) }}
                                                    disabled={(this.state.handshakeImg == handshake2) || (this.state.is_won == 1 || this.state.is_won == 0)}>
                                                    <option hidden>Select assigned to</option>
                                                    {this.props.assignedToList && this.props.assignedToList.length > 0 ?
                                                        this.props.assignedToList.map((item, index) => {
                                                            return (
                                                                <option value={item.id} key={'assignedTo_' + index}>
                                                                    {
                                                                        item.id && item.id == this.props.currentUser.user_id ?
                                                                            'Self' : item.name
                                                                    }
                                                                </option>
                                                            )
                                                        }) : ''
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </>
                                    : ''
                            }
                        </div>
                        <div className="row">
                            <div className={styles.form_group + " col-md-12"}>
                                <label className={styles.form_title}>{this.state.lead_id ? 'Add new note' : 'Note'}</label>
                                <div className="notesHint">{(1024 - this.state.notes.length) + " characters left"}</div>
                                <textarea
                                    className={styles.taxtArea}
                                    id="noter-text-area"
                                    name="notes"
                                    value={this.state.notes}
                                    onChange={(event) => this.handleUserInput(event)}
                                    maxLength="1024"
                                />
                                <FormErrors formErrors={this.formErrors} field='notes' />
                            </div>
                        </div>
                    </div>
                    <DialogActions
                        className={styles.removeBorder}>
                        {
                            this.state.submitButton ? <CircularProgress style={{
                                color: '#19D98C',
                                marginRight: 78,
                                marginBottom: 20,
                                marginTop: -11
                            }} /> :
                                <Button className={styles.saveButton} disabled={this.state.submitButton} onClick={() => this.addLead()} color="primary">
                                    {'Save'}
                                </Button>
                        }
                    </DialogActions>
                </DialogContent>

            </div >

        )
    }
}

export default NewLead;




