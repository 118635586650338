import React, { Component } from "react";
import * as actions from "./redux/actions";
import * as salesFeedActions from '../aside-right/redux/actions';
import * as communityActions from '../SalesCommunity/redux/actions';
import * as pipelineActions from '../MyPipeline/redux/actions';
import * as leadDetailActions from '../LeadDetailPopup/redux/actions';
import NewLead from "./component/NewLead";
import { connect } from "react-redux";
import { getPoints } from '../aside-left/redux/actions';
import { getStorageItem } from "../../common/common-functions";

class LeadContainer extends Component {
  render() {
    const leadComponentProps = { ...this.props };
    // const currentUser = JSON.parse(localStorage.getItem('user'));
    const currentUser = JSON.parse(getStorageItem('user'));

    return (
      <div>
        <NewLead {...leadComponentProps} currentUser={currentUser}
        // personList={null} personCount={this.props.personCount}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    lead: state.newLead.lead,
    leadUpdated: state.newLead.leadUpdated,
    leadAdded: state.newLead.leadAdded,
    companyList: state.asideLeft.companyList,
    statusLogs: state.leadDetailReducer.statusLogs,
    // personList: state.newLead.personList,
    // personCount: state.newLead.personCount,
    leadSourceList: state.newLead.leadSourceList,

    leadSourceCount: state.newLead.leadSourceCount,
    //leadCategoryList added
    leadCategoryList: state.newLead.leadCategoryList,
    leadCategoryCount: state.newLead.leadCategoryCount,

    currencyList: state.newLead.currencyList,
    currencyCount: state.newLead.currencyCount,
    assignedToList: state.newLead.assignedToList,
    assignedToCount: state.newLead.assignedToCount,
    errorMessage: state.newLead.errorMessage,
    errorLeadSourceList: state.newLead.errorLeadSourceList,

    errorLeadCategoryList: state.newLead.errorLeadCategoryList,

    errorAddNewLead: state.newLead.errorAddNewLead,
    errorUpdateLead: state.newLead.errorUpdateLead,
    filterList: state.filterData.filterValue,
    parkedSelected: state.filterData.parkedSelected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetLead: requestBody => dispatch(actions.resetLead(requestBody)),
    addLead: requestBody => dispatch(actions.addLead(requestBody)),
    updateLead: requestBody => dispatch(actions.updateLead(requestBody)),
    // getPersons: requestBody => dispatch(actions.getPersons(requestBody)),
    // resetPersonList: requestBody => dispatch(actions.resetPersonList(requestBody)),
    getLeadSources: requestBody => dispatch(actions.getLeadSources(requestBody)),
    // category prop added
    getCategorySources: requestBody => dispatch(actions.getCategorySources(requestBody)),
    getCurrencyMaster: requestBody => dispatch(actions.getCurrencyMaster(requestBody)),
    getAssignedToList: requestBody => dispatch(actions.getAssignedToList(requestBody)),

    initializeSalesFeeds: requestBody => dispatch(salesFeedActions.initializeSalesFeeds(requestBody)),
    getCommunityLeadList: requestBody => dispatch(communityActions.getCommunityLeadList(requestBody)),
    getRevenueCommunity: requestBody => dispatch(communityActions.getRevenue(requestBody)),
    getPipelineLeadList: requestBody => dispatch(pipelineActions.getPipelineLeadList(requestBody)),
    getRevenue: requestBody => dispatch(communityActions.getRevenue(requestBody)),

    getStatusLogs: requestBody => dispatch(leadDetailActions.getStatusLogs(requestBody)),
    getPoints: () => dispatch(getPoints()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadContainer);
