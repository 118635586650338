import React, { Component } from "react";
import styles from "./OopsError.module.scss";

const oopsError = (props) => (
    <div className={styles.oops_error}>
        <img className={styles.oops_error_img} src={require('../../../../assets/oops.png')} alt={"No records"} />
        <p className={styles.oops_error_text}>Oops, something went wrong!</p>
        <p className={styles.oops_error_text2}>Well, this is embarrassing. Don't worry we are working on it.</p>
    </div>
);

export default oopsError;