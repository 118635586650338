import React from "react";
import styles from '../NewContact.module.scss';
import AutoComplete from '../../../common/AutoComplete/autocomplete';
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "../../../common/Loaders/CircularLoader/circular-loader";
import { emailPattern, firstName_regex, middleName_regex, lastName_regex, zipCode_regex } from "../../../common/common-types";
const DialogContent = withStyles(theme => ({
    root: {
        // width: '950px',
        margin: 0,
        padding: theme.spacing.unit * 2
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        // width: '950px',
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit
    }
}))(MuiDialogActions);

class Person extends React.Component {

    constructor(props) {
        super(props);
        this.textInput = null;
        this.state = {
            personFields: {
                initials: "",
                first_name: "",
                middle_name: "",
                last_name: "",
                personCompany: 0,
                country_code: "",
                personPhoneNumber: "",
                personEmail: "",
                personAddress1: "",
                personAddress2: "",
                personCountry: 0,
                personState: 0,
                personCity: 0,
                zipcode: '',
                personNote: "",
                title: "",
                is_city:""
            },
            personErrors: {
                middle_name: "",
                personAddress1: "",
                personAddress2: "",
                personCountry: "",
                personState: "",
                personCity: "",
                zipcode: '',
                personNote: "",
                title: ""

            },
            disableCompanyField: false,
            resetState: false,
            resetCity: false,
            submitButton: false,
            updatedStateList: []

        }

        this.initialsArr = [
            { key: 'Mr.', value: 'Mr.' },
            { key: 'Mrs.', value: 'Mrs.' },
            { key: 'Miss.', value: 'Miss.' },
            { key: 'Ms.', value: 'Ms.' },
        ]
    }

    componentDidMount() {
        this.textInput.focus();
        if (this.props.currentPerson) {

            let currentPerson = this.props.currentPerson;
            this.setState({
                value: this.props.value,
                personFields: {
                    initials: currentPerson.initials,
                    first_name: currentPerson.first_name,
                    middle_name: currentPerson.middle_name ? currentPerson.middle_name : "",
                    last_name: currentPerson.last_name,
                    personCompany: currentPerson.contact_company ? currentPerson.contact_company.id : 0,
                    country_code: currentPerson.countryCode ? currentPerson.countryCode.id : "",
                    personPhoneNumber: currentPerson.phone_number ? currentPerson.phone_number.toString() : "",
                    personEmail: currentPerson.email ? currentPerson.email : "",
                    personAddress1: currentPerson.address_line_1,
                    personAddress2: currentPerson.address_line_2,
                    personCountry: currentPerson.country_id ? currentPerson.country_id : "",
                    personState: currentPerson.state_id?currentPerson.state_id:currentPerson.country && currentPerson.country.is_city==1  ?"other":null,

                    //personState: currentPerson.state_id ? currentPerson.state_id : "",
                    personCity: currentPerson.city_id ? currentPerson.city_id : "",
                    zipcode: currentPerson.zipcode ? currentPerson.zipcode : "",
                    personNote: currentPerson.notes ? currentPerson.notes : '',
                    title: currentPerson.title ? currentPerson.title : "",
                    is_city: currentPerson.country ? currentPerson.country["is_city"]:""

                }
            });

            if(currentPerson.country_id){
            this.props.getAllStates(currentPerson.country_id);
            }

            let personStat = currentPerson.state_id?currentPerson.state_id:currentPerson.country && currentPerson.country.is_city==1 ?"other":null
            if (currentPerson.country_id && personStat){

            this.props.getAllCities(currentPerson.country_id, personStat);
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        //if person is getting added from Lead form, pre-populate company field.
        if (this.props.selectedCompany && this.props.selectedCompany != prevState.personFields.personCompany) {
            let personFields = { ...this.state.personFields };
            personFields["personCompany"] = this.props.selectedCompany;
            if (this.props.selectedCompanyObj && this.props.selectedCompanyObj.countryCode && this.props.selectedCompanyObj.countryCode != prevState.personFields.country_code) {
                personFields["country_code"] = this.props.selectedCompanyObj.countryCode.id;
            }
            this.setState({
                personFields,
                disableCompanyField: true
            });
        }


        if (prevProps.stateList !== this.props.stateList) {
          
            this.setState({
                updatedStateList: this.props.stateList

            }, () => {

                if (this.state.updatedStateList && (this.state.personFields["is_city"] == true ||this.state.personFields["is_city"] == 1)) {

                    this.setState({ 
                        updatedStateList: [...this.state.updatedStateList, { id: "other", name: "Other" }]
                    }, () => { 

                    if(this.state.personFields["personState"]==null||this.state.personFields["personState"]==0){
                    this.handlePersonFieldsChange({ name: "personState", value: "other"}) 
                     }
                
                   })
                 }
              })
          }
 
       }
       
       
    componentWillUnmount () {
        //clearing state and city data from redux
        this.props.resetStateCity();
    }




    

    // person fields on change
    handlePersonFieldsChange = async (e) => {
        let { name, value } = '';
        if (e && e.target) {
            name = e.target.name;
            value = e.target.value;
        } else if (e && e.name) {
            name = e.name;
            value = e.value;
        }
        let personFields = { ...this.state.personFields };
        let personErrors = { ...this.state.personErrors };

        if (
            name === "personCompany" ||
            name === "personCountry" ||
            name === "personState" ||
            name === "personCity"
        ) {
            if (value === "") {
                value = 0;
            }else if(value=="other"){
                value = "other"
            }else {
                value = parseInt(value);
            }
        }

        switch (name) {
            case "initials":
                personFields["initials"] = value;
                personErrors["initials"] = "";
                break;

            case "first_name":
                if (value.trim() === "") {
                    personFields["first_name"] = value.trim();
                    personErrors["first_name"] = "This field is required";
                } else if (!value.match(firstName_regex)) {
                    personFields["first_name"] = value;
                    personErrors["first_name"] = "Please enter valid first name";
                } else {
                    personFields["first_name"] = value.trim();
                    personErrors["first_name"] = "";
                }
                break;

            case "middle_name":
                if (!value.match(middleName_regex)) {
                    personFields["middle_name"] = value;
                    personErrors["middle_name"] = "Please enter valid middle name";
                } else {
                    personFields["middle_name"] = value.trim();
                    personErrors["middle_name"] = "";
                }
                break;
            //title -spaces allowed, maxlength 100 no validation
            case "title":
                personFields["title"] = value;
                personErrors["title"] = "";
                break;


            case "last_name":
                if (value.trim() === "") {
                    personFields["last_name"] = value.trim();
                    personErrors["last_name"] = "This field is required";
                } else if (!value.match(lastName_regex) || value.charAt(value.length - 1) === "'") {
                    personFields["last_name"] = value;
                    personErrors["last_name"] = "Please enter valid last name";
                } 
                else {
                    personFields["last_name"] = value.trim();
                    personErrors["last_name"] = "";
                }

                break;

            case "personCompany":
                if (value === 0) {
                    personFields["personCompany"] = value;
                    personErrors["personCompany"] = "This field is required";
                } else {
                    let countryCode = '';
                    if (this.props.companyList.length > 0) {
                        countryCode = this.props.companyList.filter(company => {
                            return company.id == value
                        })[0]['contact_country_code'];
                        if (countryCode) {
                            personFields["country_code"] = countryCode;
                            personErrors["country_code"] = "";
                            //  this.setState({ countryCode });
                        } else {
                            personFields["country_code"] = ""
                            personErrors["country_code"] = "";
                            // this.setState({  countryCode: '' });
                        }
                    }
                    personFields["personCompany"] = value;
                    personErrors["personCompany"] = "";
                }
                break;

            case "country_code":
                personFields["country_code"] = value;
                personErrors["country_code"] = "";
                break;

            case "personEmail":
                if (value.trim() === "") {
                    personFields["personEmail"] = value;
                    personErrors["personEmail"] = "This field is required";
                } else if (
                    !value.match(emailPattern)
                ) {
                    personFields["personEmail"] = value;
                    personErrors["personEmail"] = "Please enter a valid email";
                } else {
                    personFields["personEmail"] = value;
                    personErrors["personEmail"] = "";
                }
                break;

            case "personAddress1":
                // if (value.trim() === "") {
                //     personFields["personAddress1"] = value;
                //     personErrors["personAddress1"] = "Please enter person address";
                // } else {
                personFields["personAddress1"] = value;
                personErrors["personAddress1"] = "";
                // }
                break;

            case "personAddress2":
                // if (value.trim() === "") {
                //     personFields["personAddress2"] = value;
                //     personErrors["personAddress2"] = "Please enter person address";
                // } else {
                personFields["personAddress2"] = value;
                personErrors["personAddress2"] = "";
                // }
                break;

            case "personCountry":
                // if (value === 0) {
                //     personFields["personCountry"] = value;
                //     personErrors["personCountry"] = "Please select person country";
                // } else {
                personFields["personCountry"] = value;
                personFields["personState"] = 0;
                personFields["personCity"] = 0;
                personErrors["personCountry"] = "";
                this.props.resetStateCity();
                await this.setState({
                    resetState: true,
                    resetCity: true
                })
                this.props.getAllStates(value);

                if (this.props.countryList) {
                    let selectedCountry = this.props.countryList.filter((country, index) => {
                        return country.id == value;
                    });
                    if (selectedCountry && selectedCountry[0] && selectedCountry[0].id) {
                        personFields["is_city"] = selectedCountry[0].is_city;

                        }
                    //console.log(personFields["is_city"]);

                }
             // }
                break;

            case "personState":
                // if (value === 0) {
                //     personFields["personState"] = value;
                //     personErrors["personState"] = "Please select person state";
                // } else {
                personFields["personState"] = value;
                personFields["personCity"] = 0;
                personErrors["personState"] = "";
                this.props.resetCity();
                await this.setState({
                    resetState: false,
                    resetCity: true
                })
                this.props.getAllCities(this.state.personFields.personCountry, value);
                // }
                break;

            case "personCity":
                // if (value === 0) {
                //     personFields["personCity"] = value;
                //     personErrors["personCity"] = "Please select person city";
                // } else {
                await this.setState({
                    resetCity: false
                })
                personFields["personCity"] = value;
                personErrors["personCity"] = "";
                // }
                break;

            case "zipcode":
                if (!value.match(zipCode_regex)) {
                    personFields["zipcode"] = value;
                    personErrors["zipcode"] = "Please enter valid zip code";
                } else {
                    personFields["zipcode"] = value;
                    personErrors["zipcode"] = "";
                }
                break;

            case "personNote":
                personFields["personNote"] = value;
                personErrors["personNote"] = "";
                break;

            default:
                break;
        }

        this.setState({
            personFields: { ...personFields },
            personErrors: { ...personErrors }
        });
    };

    handlePhoneNumberChange = (e) => {
        let value = e.target.value;
        const personFields = { ...this.state.personFields };
        const personErrors = { ...this.state.personErrors };
        if (value.trim() === "") {
            personFields["personPhoneNumber"] = value;
            personErrors["personPhoneNumber"] = "This field is required";
        } else if (!value.match(/^[0-9]+$/)) {
            //personFields["personPhoneNumber"] = "";
            personErrors["personPhoneNumber"] = "";
            if (value.length < 9) {
                personErrors["personPhoneNumber"] = "Phone number should not be less than 8 digits.";
            }
        } else if (value.length < 8) {
            personFields["personPhoneNumber"] = value;
            personErrors["personPhoneNumber"] = "Phone number should not be less than 8 digits.";
        } else {
            personFields["personPhoneNumber"] = value.trim();
            personErrors["personPhoneNumber"] = "";
        }

        this.setState({
            personFields: personFields,
            personErrors: personErrors
        });
    }

    handleUpdatePerson = () => {
        const personRequestBody = {
            id: this.props.currentPerson.id,
            initials: this.state.personFields.initials,
            first_name: this.state.personFields.first_name,
            middle_name: this.state.personFields.middle_name,
            last_name: this.state.personFields.last_name,
            title: this.state.personFields.title.trim(),
            company_id: this.state.personFields.personCompany ? (this.state.personFields.personCompany == 0 ? "" : this.state.personFields.personCompany.toString()) : "",
            country_code: this.state.personFields.country_code ? (this.state.personFields.country_code == 0 ? "" : this.state.personFields.country_code.toString()) : "",
            phone_number: this.state.personFields.personPhoneNumber,
            email: this.state.personFields.personEmail,
            address_line_1: this.state.personFields.personAddress1,
            address_line_2: this.state.personFields.personAddress2,
            country_id: this.state.personFields.personCountry ? (this.state.personFields.personCountry == 0 ? "" : this.state.personFields.personCountry.toString()) : "",
            state_id: this.state.personFields.personState ? (this.state.personFields.personState == 0 || this.state.personFields.personState == "other" ? "" : this.state.personFields.personState.toString()) : "",

            //state_id: this.state.personFields.personState ? (this.state.personFields.personState == 0 ? "" : this.state.personFields.personState.toString()) : "",
            city_id: this.state.personFields.personCity ? (this.state.personFields.personCity == 0 ? "" : this.state.personFields.personCity.toString()) : "",
            zipcode: this.state.personFields.zipcode.toString(),
            notes: this.state.personFields.personNote
        };
        this.props.updatePerson(personRequestBody);
    };

    // submitting person form
    handlePersonFormSubmit = () => {
        let personErrors = { ...this.state.personErrors };
        if (personErrors["middle_name"] !== "" ||
            personErrors["personAddress1"] !== "" || personErrors["personAddress2"] ||
            personErrors["personCity"] !== "" || personErrors["personCountry"] ||
            personErrors["personState"] !== "" || personErrors["personNote"] ||
            personErrors["zipcode"] || personErrors["personPhoneNumber"]) {
            this.handlePersonOnSubmitValidation();
            return;
        } else {
            if (this.handlePersonOnSubmitValidation()) {
                this.setState({ submitButton: true });
                if (this.props.currentPerson) {
                    this.handleUpdatePerson();
                } else {
                    this.handleSavePerson();
                }
            }
        }
    };

    // person form on submit all validations
    handlePersonOnSubmitValidation = () => {
        let personFields = this.state.personFields;
        let personErrors = { ...this.state.personErrors };
        let personFormIsValid = true;

        // person name
        if (typeof personFields["first_name"]) {
            if (personFields["first_name"].trim() === "") {
                personFormIsValid = false;
                personErrors["first_name"] = "This field is required";
            } else {
                if (!personFields["first_name"].match(firstName_regex)) { ///^[a-zA-Z ]+$/
                    personFormIsValid = false;
                    personErrors["first_name"] = "Please enter valid first name";
                }
            }
        }

        if (typeof personFields["middle_name"]) {
            if (!personFields["middle_name"].match(middleName_regex)) { ///^[a-zA-Z ]+$/
                personFormIsValid = false;
                personErrors["middle_name"] = "Please enter valid middle name";
            }
        }

        if (typeof personFields["last_name"]) {
            if (personFields["last_name"].trim() === "") {
                personFormIsValid = false;
                personErrors["last_name"] = "This field is required";
            } else {
                if (!personFields["last_name"].match(lastName_regex)) { ///^[a-zA-Z ]+$/
                    personFormIsValid = false;
                    personErrors["last_name"] = "Please enter valid last name";
                }
            }
        }

        // person company
        if (personFields["personCompany"] == 0) {
            personFormIsValid = false;
            personErrors["personCompany"] = "This field is required";
        }

        // person phone country code
        if (personFields["country_code"] === "") {
            personFormIsValid = false;
            personErrors["country_code"] = "Required";
        }

        // person phone number
        if (typeof personFields["personPhoneNumber"]) {
            if (personFields["personPhoneNumber"].trim() === "") {
                personFormIsValid = false;
                personErrors["personPhoneNumber"] = "This field is required";
            } else if (personFields["personPhoneNumber"].length > 15) {
                personFormIsValid = false;
                personErrors["personPhoneNumber"] =
                    "Person phone number should not greater than 15 characters";
            }
            else {
                if (!personFields["personPhoneNumber"].match(/^[0-9]+$/)) {
                    personFormIsValid = false;
                    personErrors["personPhoneNumber"] = "Please enter valid person phone number";
                }
            }
        }

        //person email
        if (typeof personFields["personEmail"]) {
            if (personFields["personEmail"].trim() === "") {
                personFormIsValid = false;
                personErrors["personEmail"] = "This field is required";
            } else {
                if (
                    !personFields["personEmail"].match(emailPattern)
                ) {
                    personFormIsValid = false;
                    personErrors["personEmail"] = "Please enter a valid email";
                }
            }
        }

        //person zip code
        // if (typeof personFields["zipcode"]) {
        //     if(!personFields["zipcode"].match(zipCode_regex)){
        //         personFormIsValid = false;
        //         personErrors["zipcode"] = "Please enter valid zip code";
        //     }
        // }

        // person address 1
        // if (typeof personFields["personAddress1"]) {
        //     if (personFields["personAddress1"].trim() === "") {
        //         personFormIsValid = false;
        //         personErrors["personAddress1"] = "Please enter person address";
        //     }
        // }

        // // person address 2
        // if (typeof personFields["personAddress2"]) {
        //     if (personFields["personAddress2"].trim() === "") {
        //         personFormIsValid = false;
        //         personErrors["personAddress2"] = "Please enter person address";
        //     }
        // }

        // person country
        // if (personFields["personCountry"] == 0) {
        //     personFormIsValid = false;
        //     personErrors["personCountry"] = "Please select person country";
        // }

        // person state
        // if (personFields["personState"] == 0) {
        //     personFormIsValid = false;
        //     personErrors["personState"] = "Please select person state";
        // }

        // // person city
        // if (personFields["personCity"] == 0) {
        //     personFormIsValid = false;
        //     personErrors["personCity"] = "Please select person city";
        // }

        // person note 2
        // if (typeof personFields["personNote"]) {
        //     if (personFields["personNote"].trim() === "") {
        //         // personFormIsValid = false;
        //         personErrors["personNote"] = "";
        //     }
        //     else {
        //         if (personFields["personNote"].length > 500) {
        //             personFormIsValid = false;
        //             personErrors["personNote"] =
        //                 "Note should not be greater than 500 characters";
        //         }
        //     }
        // }

        this.setState({ personErrors: personErrors });
        return personFormIsValid;
    };

    // save person contact request body
    handleSavePerson = () => {
        const personRequestBody = {
            initials: this.state.personFields.initials,
            first_name: this.state.personFields.first_name,
            title: this.state.personFields.title.trim(),
            middle_name: this.state.personFields.middle_name,
            last_name: this.state.personFields.last_name,
            company_id: this.state.personFields.personCompany.toString(),
            country_code: this.state.personFields.country_code.toString(),
            phone_number: this.state.personFields.personPhoneNumber,
            email: this.state.personFields.personEmail,
            address_line_1: this.state.personFields.personAddress1,
            address_line_2: this.state.personFields.personAddress2,
            country_id: this.state.personFields.personCountry.toString(),
            //state_id: this.state.personFields.personState.toString(),
            state_id: this.state.personFields.personState ? (this.state.personFields.personState == 0|| this.state.personFields.personState=="other" ? "" : this.state.personFields.personState.toString()) : "",
            city_id: this.state.personFields.personCity.toString(),
            zipcode: this.state.personFields.zipcode.toString(),
            notes: this.state.personFields.personNote
        };
        this.props.addNewPerson(personRequestBody);
    };

    // on update if fields changed to empty, handle the state
    onChangeAutocomplete = async (event, newValue, key) => {
        let personFields = { ...this.state.personFields };
        let personErrors = { ...this.state.personErrors };
        switch (key) {
            case "personCompany":

                if (!newValue || newValue == "") {
                    personFields["personCompany"] = "";
                    personFields["country_code"] = ""
                    await this.setState({ personFields });
                } else {
                    let selectedCompany = this.props.companyList.filter((company, index) => {
                        return company.company_name === newValue;
                    });
                    if (!selectedCompany || selectedCompany.length === 0) {
                        personFields["country_code"] = "";
                        personFields["personCompany"] = 0;
                        personErrors["personCompany"] = "Please select company from suggestions";
                        await this.setState({ personFields });
                    }
                    await this.setState({ personErrors });
                }
                break;
            case "personCountry":
                if (!newValue || newValue == "") {
                    personFields["personCountry"] = "";
                    personFields["personState"] = "";
                    personFields["personCity"] = "";
                    personFields["is_city"]="";
                    personErrors["personCountry"] = "";
                    personErrors["personState"] = "";
                    personErrors["personCity"] = "";
                    this.props.resetStateCity();
                    await this.setState({ personFields, personErrors, resetState: true, resetCity: true });
                } else {
                    let selectedCountry = this.props.countryList.filter((country, index) => {
                        return country.name === newValue;
                    });
                    if (!selectedCountry || selectedCountry.length == 0) {
                        personFields["personCountry"] = "";
                        personFields["personState"] = "";
                        personFields["personCity"] = "";
                        personFields["is_city"] = "";
                        personErrors["personState"] = "";
                        personErrors["personCity"] = "";
                        this.props.resetStateCity();
                        personErrors["personCountry"] = "Please select country from suggestions";
                    }
                    await this.setState({ personFields, personErrors, resetState: true, resetCity: true });
                }
                break;
            case "personState":
                if (!newValue || newValue == "") {
                    personFields["personState"] = "";
                    personFields["personCity"] = "";
                    personErrors["personState"] = "";
                    personErrors["personCity"] = "";
                    this.props.resetCity();
                    await this.setState({ personFields, personErrors, resetCity: true });
                } else {
                    let selectedState = this.props.stateList.filter((state, index) => {
                        return state.name === newValue;
                    });
                    if (!selectedState || selectedState.length == 0) {
                        personFields["personState"] = "";
                        personFields["personCity"] = "";
                        personErrors["personState"] = "Please select state from suggestions";
                        personErrors["personCity"] = "";
                        this.props.resetCity();
                        await this.setState({ personFields, personErrors, resetCity: true });
                    }
                }
                break;
            case "personCity":
                if (!newValue || newValue == "") {
                    personFields["personCity"] = "";
                    personErrors["personCity"] = "";
                    await this.setState({ personFields, personErrors });
                } else {
                    let selectedCity = this.props.cityList.filter((city, index) => {
                        return city.name === newValue;
                    });
                    if (!selectedCity || selectedCity.length == 0) {
                        personFields["personCity"] = "";
                        personErrors["personCity"] = "Please select city from suggestions";
                        await this.setState({ personFields, personErrors });
                    }
                }
                break;
            default:
                break;
        }
    }

    render() {

        return (
            <div>
                <DialogContent className={styles.lead_dialog_content}>
                    <div className="row">
                        <div className="form-group col-md-6 col-lg-3">
                            <label className={styles.label}>Company<span className={styles.required_field}>*</span></label>
                            <AutoComplete
                                suggestions={this.props.companyList}
                                autoCompleteName="personCompany"
                                placeholder="Select company name"
                                handlePersonFieldsChange={this.handlePersonFieldsChange}
                                value={this.state.personFields["personCompany"]}
                                disabled={(this.props.currentPerson && this.props.currentPerson.contact_company && this.props.currentPerson.contact_company.id) || this.state.disableCompanyField}
                                onChange={(event, newValue, key) => this.onChangeAutocomplete(event, newValue, key)}

                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["personCompany"]}
                            </span>
                        </div>
                        <div className="form-group col-md-6 col-lg-3">
                            <div className="row">
                                <div className="col-4  padding_right padding_right_remove">
                                    <label className={styles.label}>Prefix</label>
                                    <select className={styles.selectCompany}
                                        name="initials"
                                        value={this.state.personFields["initials"]}
                                        onChange={this.handlePersonFieldsChange}
                                    >
                                        <option value="" disabled>Select</option>
                                        {
                                            this.initialsArr.map((item, index) => {
                                                return (
                                                    <option value={item.key} key={"initial_" + index}>{item.value}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-8 padding_left">
                                    <label className={styles.label}>First name<span className={styles.required_field}>*</span></label>
                                    <input
                                        className={styles.inputStyle}
                                        type="text"
                                        name="first_name"
                                        value={this.state.personFields["first_name"]}
                                        onChange={this.handlePersonFieldsChange}
                                        autoComplete="off"
                                        maxLength="20"
                                        //placeholder="Enter first name"
                                        ref={elem => (this.textInput = elem)}
                                    />
                                    <span className={styles.errorMessage}>
                                        {this.state.personErrors["first_name"]}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-6 col-lg-3">
                            <label className={styles.label}>Middle name</label>
                            <input
                                className={styles.inputStyle}
                                type="text"
                                name="middle_name"
                                value={this.state.personFields["middle_name"]}
                                onChange={this.handlePersonFieldsChange}
                                autoComplete="off"
                                maxLength="20"
                            //placeholder="Enter middle name"
                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["middle_name"]}
                            </span>
                        </div>
                        <div className="form-group col-md-6 col-lg-3">
                            <label className={styles.label}>Last name<span className={styles.required_field}>*</span></label>
                            <input
                                className={styles.inputStyle}
                                type="text"
                                name="last_name"
                                value={this.state.personFields["last_name"]}
                                onChange={this.handlePersonFieldsChange}
                                autoComplete="off"
                                maxLength="20"
                            //placeholder="Enter last name"
                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["last_name"]}
                            </span>
                        </div>
                    </div>
                    <div className="row">

                        <div className="form-group col-md-6 col-lg-3">
                            <label className={styles.label}>Title</label>
                            <input
                                className={styles.inputStyle}
                                type="text"
                                name="title"
                                value={this.state.personFields["title"]}
                                onChange={this.handlePersonFieldsChange}
                                autoComplete="off"
                                maxLength="30"
                                placeholder="Enter title"
                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["title"]}
                            </span>
                        </div>

                        <div className="form-group col-md-6 col-lg-3">
                            <label className={styles.label}>Email address<span className={styles.required_field}>*</span></label>
                            <input
                                className={styles.inputStyle}
                                type="text"
                                name="personEmail"
                                value={this.state.personFields["personEmail"]}
                                onChange={this.handlePersonFieldsChange}
                                autoComplete="off"
                                maxLength="100"
                            //placeholder="Enter email address"
                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["personEmail"]}
                            </span>
                        </div>
                        <div className="form-group col-md-6 col-lg-3">

                            <div className="row">
                                <div className="col-4 padding_right padding_right_remove">
                                    <label className={styles.label}>Code<span className={styles.required_field}>*</span></label>
                                    <select className={styles.selectCompany}
                                        name="country_code"
                                        value={this.state.personFields["country_code"]}
                                        onChange={this.handlePersonFieldsChange}>
                                        <option value="">Select</option>
                                        {
                                            this.props.countryList ?
                                                this.props.countryList.map((country, index) => {
                                                    return (
                                                        <option value={country.id} key={"code_" + index}>
                                                            {country.iso_code + " +" + country.dialing_code}
                                                        </option>
                                                    )
                                                })
                                                : ''
                                        }
                                    </select>
                                    <span className={styles.errorMessage}>
                                        {`${this.state.personErrors["country_code"] ?
                                            this.state.personErrors["country_code"] : ""}`}
                                    </span>
                                </div>

                                <div className="col-8 padding_left">
                                    <label className={styles.label}>Phone number<span className={styles.required_field}>*</span></label>
                                    <input
                                        className={styles.inputStyle}
                                        type="text"
                                        name="personPhoneNumber"
                                        value={this.state.personFields["personPhoneNumber"]}
                                        onChange={this.handlePhoneNumberChange}
                                        autoComplete="off"
                                        maxLength="15"
                                    //placeholder="Enter phone number"
                                    />
                                    <span className={styles.errorMessage}>
                                        {`${this.state.personErrors["personPhoneNumber"] ?
                                            this.state.personErrors["personPhoneNumber"] : ""}`}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="form-group col-md-6 col-lg-3">
                            <label className={styles.label}>Address line 1</label>
                            <input
                                className={styles.inputStyle}
                                type="text"
                                name="personAddress1"
                                value={this.state.personFields["personAddress1"]}
                                onChange={this.handlePersonFieldsChange}
                                autoComplete="off"
                                maxLength="100"
                            // placeholder="Enter address line 1"
                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["personAddress1"]}
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6 col-lg-3">
                            <label className={styles.label}>Address line 2</label>
                            <input
                                className={styles.inputStyle}
                                type="text"
                                name="personAddress2"
                                value={this.state.personFields["personAddress2"]}
                                onChange={this.handlePersonFieldsChange}
                                autoComplete="off"
                                maxLength="100"
                            //  placeholder="Enter address line 2"
                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["personAddress2"]}
                            </span>
                        </div>



                        <div className="form-group col-md-6 col-lg-3">
                            <label className={styles.label}>Country</label>
                            <AutoComplete
                                suggestions={this.props.countryList}
                                autoCompleteName="personCountry"
                                placeholder="Select country name"
                                handlePersonFieldsChange={this.handlePersonFieldsChange}
                                value={this.state.personFields["personCountry"]}
                                onChange={(event, newValue, key) => this.onChangeAutocomplete(event, newValue, key)}
                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["personCountry"]}
                            </span>
                        </div>
                        <div className="form-group col-md-6 col-lg-3">
                            <label className={styles.label}>State</label>
                            <AutoComplete
                                suggestions={this.state.updatedStateList}
                                autoCompleteName="personState"
                                resetState={this.state.resetState}
                                placeholder="Select state name"
                                handlePersonFieldsChange={this.handlePersonFieldsChange}
                                value={this.state.personFields["personState"]}
                                onChange={(event, newValue, key) => this.onChangeAutocomplete(event, newValue, key)}
                                disabled={this.props.stateList && this.props.stateList.length !== 0 ? false : true}
                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["personState"]}
                            </span>
                        </div>
                        <div className="form-group col-md-6 col-lg-3">
                            <label className={styles.label}>City</label>
                            <AutoComplete
                                suggestions={this.props.cityList}
                                autoCompleteName="personCity"
                                resetCity={this.state.resetCity}
                                placeholder="Select city name"
                                handlePersonFieldsChange={this.handlePersonFieldsChange}
                                value={this.state.personFields["personCity"]}
                                onChange={(event, newValue, key) => this.onChangeAutocomplete(event, newValue, key)}
                                disabled={this.props.cityList && this.props.cityList.length !== 0 ? false : true}
                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["personCity"]}
                            </span>
                        </div>
                    </div>
                    <div className="row">

                        <div className="form-group col-md-6 col-lg-3">
                            <label className={styles.label}>Zip/Postal address</label>
                            <input
                                className={styles.inputStyle}
                                type="text"
                                name="zipcode"
                                value={this.state.personFields["zipcode"]}
                                onChange={this.handlePersonFieldsChange}
                                autoComplete="off"
                                maxLength="10"
                            //placeholder="Enter zip/postal address"
                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["zipcode"]}
                            </span>
                        </div>


                        <div className="form-group col-md-12 col-lg-9">
                            <label className={styles.label}>Note</label>
                            <div className="notesHint">{(1024 - this.state.personFields["personNote"].length) + " characters left"}</div>
                            <textarea
                                className={styles.taxtArea}
                                style={{height: "46px"}}
                                name="personNote"
                                value={this.state.personFields["personNote"]}
                                onChange={this.handlePersonFieldsChange}
                                autoComplete="off"
                                maxLength="1024"
                            />
                            <span className={styles.errorMessage}>
                                {this.state.personErrors["personNote"]}
                            </span>
                        </div>
                    </div>

                </DialogContent>
                <DialogActions className={styles.removeBorder}>
                    {
                        this.state.submitButton ?
                            <div className={styles.submit_loader}>
                                <CircularProgress
                                    style={{
                                        color: "#19D98C"
                                    }}
                                />
                            </div> :
                            <Button
                                className={styles.saveButton}
                                onClick={this.handlePersonFormSubmit}
                                color="primary"
                            >
                                {"Save"}
                            </Button>
                    }
                </DialogActions>
            </div>
        )
    }
}

export default Person;