import React, { Component } from "react";
import styles from "./NoRecordsFound.module.scss";

const noRecords = (props) => (
    <div className={styles.no_records}>
        <img className={styles.no_records_img} src={require('../../../../assets/no_record.png')} alt={"No records"} />
        {/* <p className={styles.no_records_text}>No records found</p> */}
    </div>
);

export default noRecords;