import Autosuggest from "react-autosuggest";
import React from "react";
import styles from "./autocompletewithadd.module.scss";

class AutoCompleteWithAdd extends React.Component {
  constructor() {
    super();

    this.state = {
      value: "",
      suggestions: [],
      inputChanged: false
    };
  }

  componentDidMount() {
    this.setState({
      suggestions: this.props.suggestions ? this.props.suggestions : []
    });
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (
      this.props.value &&
      (this.props.value != prevProps.value ||
        this.props.suggestions != prevProps.suggestions)
    ) {
      var selectedRecord = this.props.suggestions
        ? this.props.suggestions.filter(item => {
          return item.id == this.props.value;
        })
        : [];

      if (this.props.autoCompleteName === "leadCategory") {
        this.setState({
          value:
            typeof selectedRecord !== "undefined" &&
              selectedRecord[0] &&
              selectedRecord[0].name &&
              !this.state.inputChanged
              ? selectedRecord[0].name
              : this.state.value
        });
      }
    }
  };

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    let suggestions =
      inputLength === 0
        ? this.props.suggestions
        : this.props.suggestions
          ? this.props.suggestions.filter(
            record =>
              record.name.toLowerCase().slice(0, inputLength) === inputValue
          )
          : [];

    if (suggestions.length === 0) {
      return [{ isAddNew: true }];
    }
    return suggestions;
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.

  getSuggestionValue = suggestion => {
    if (suggestion.isAddNew) {
      return this.state.value;
    }

    return suggestion.name;
  };

  // Use your imagination to render suggestions.
  renderSuggestion = suggestion => {
    if (suggestion.isAddNew && this.state.value !== '') {
      return (
        <span>
          Add new: <strong>{this.state.value}</strong>
        </span>
      );
    }
    return <div>{suggestion.name}</div>;
  };

  shouldRenderSuggestions = value => {
    return true;
  };

  onChange = async (event, { newValue }) => {
    if (this.props.autoCompleteName === "leadCategory") {
      this.props.onChange(event, newValue, "leadCategory");
    }

    this.setState({
      value: newValue,
      inputChanged: true
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {


    switch (this.props.autoCompleteName) {
      case "leadCategory":
        if (suggestion.isAddNew) {
          this.props.addCategorySource({
            name: "name",
            value: this.state.value
          });

          break;
        }

        this.props.setCategorySource({
          name: "category_id",
          value: suggestion.id
        });
        break;
    }
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = async ({ value }) => {
    await this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.props.suggestions
    });
  };

  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    let inputProps = {
      placeholder: this.props.placeholder,
      value,
      onChange: this.onChange,
      onBlur: this.onBlur,
      disabled: false,
      maxLength:30
    };

    if (this.props.disabled) {
      inputProps.disabled = true;
    }

    return (
      <Autosuggest
        
        theme={styles}
        suggestions={suggestions ? suggestions : []}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={this.onSuggestionSelected}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
      // highlightFirstSuggestion={true}
      />
    );
  }
}

export default AutoCompleteWithAdd;
