import React from "react";
import { Route, Redirect } from "react-router-dom";
import AsideLeftContainer from "../pages/aside-left/asideLeftContainer";
import {getStorageItem} from "../common/common-functions";

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      {/* const currentUser = JSON.parse(localStorage.getItem("user")); */}
      const currentUser = JSON.parse(getStorageItem("user"));
      const path = window.location.pathname;
      const pathArray = path.split("/");
      const url = pathArray[1];
      if (currentUser && currentUser.role && currentUser.role.id) {
        if (
          currentUser.license_type &&
          (currentUser.license_type.actual_name === "BASIC" ||
            currentUser.license_type.id === 1 ||
            currentUser.license_type.display_name === "Basic Role") &&
          url === "activities"
        ) {
          return <Redirect to="/sales-news" />;
        }
        return (
          <div>
            <AsideLeftContainer {...props} />
            <div className="responsive-aside-left">
              <Component {...props} />
            </div>
          </div>
        );
      } else {
        return <Redirect to="/login" />;
      }
    }}
  />
);
