import * as types from "./types";
import axios from "../../../common/api-config";

export const addNewPerson = requestBody => {
  return dispatch => {
    axios
      .post("/contact/person/add", requestBody)
      .then(response => {
        if (response.data && response.data.statusCode === 200) {
          if (response.data.data && response.data.data.data) {
            dispatch(addNewPersonSuccess(response.data.data.data));
          }
        } else {
          dispatch(addNewPersonFailed());
        }
      })
      .catch(error => {
        dispatch(addNewPersonFailed());
      });
  };
};

export const addNewCompany = requestBody => {
  return dispatch => {
    axios
      .post("/contact/company/add", requestBody)
      .then(response => {
        if (response.data && response.data.statusCode === 200) {
          if (response.data.data && response.data.data.data) {
            dispatch(addNewCompanySuccess(response.data.data.data));
          }
        } else {
          dispatch(addNewCompanyFailed());
        }
      })
      .catch(error => {
        dispatch(addNewCompanyFailed());
      });
  };
};

const addNewPersonFailed = message => {
  return {
    type: types.ADD_NEW_PERSON_FAILED
  };
};

const addNewPersonSuccess = data => {
  return {
    type: types.ADD_NEW_PERSON_SUCCESS,
    data: data
  };
};

const addNewCompanyFailed = message => {
  return {
    type: types.ADD_NEW_COMPANY_FAILED
  };
};

const addNewCompanySuccess = data => {
  return {
    type: types.ADD_NEW_COMPANY_SUCCESS,
    data: data
  };
};

export const getAllStates = countryId => {

  return dispatch => {
    const stateRequestBody = {
      arrayFilters: [
        {
          country_id: countryId ? countryId.toString() : ""
        }
      ],
      selectFilters: ["id", "name"],
      sort: {
        field: "name",
        sortOrder: "ASC"
      },
      paginate: {
        page: 0
      }
    };
    axios
      .post("/master/territory/country/state", stateRequestBody)
      .then(response => {
        dispatch({ type: types.GET_ALL_STATES, data: response.data.data });
      })
      .catch(error => {
        dispatch({
          type: types.GET_ALL_STATES_ERROR,
          data: error.errorMessage
        });
      });
  };
};




export const getAllCities = (countryId, stateId, isCity) => {


  return dispatch => {


    let cityRequestBody = {
      arrayFilters: [
        {
          country_id: countryId ? countryId.toString() : 0,
          state_id: stateId ? stateId.toString() : 0
        }
      ],
      selectFilters: ["id", "name"],
      sort: {
        field: "name",
        sortOrder: "ASC"
      },
      paginate: {
        page: 0
      }
    };

// NOTE: || isCity==1
    if (stateId === "other") {


      cityRequestBody = {
        arrayFilters: [
          {
            country_id: countryId ? countryId.toString() : 0,
            //state_id: stateId ? stateId.toString() : 0
          }
        ],
        selectFilters: ["id", "name"],
        sort: {
          field: "name",
          sortOrder: "ASC"
        },
        paginate: {
          page: 0
        }
      };

    }
    axios
      .post("/master/territory/country/state/city", cityRequestBody)
      .then(response => {
        dispatch({ type: types.GET_ALL_CITIES, data: response.data.data });
      })
      .catch(error => {
        dispatch({
          type: types.GET_ALL_CITIES_ERROR,
          data: error.errorMessage
        });
      });
  };
};

export const updateCompany = requestBody => {
  return dispatch => {
    axios
      .put("/contact/company/update", requestBody)
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch(updateCompanySuccess(response.data.data));
        } else {
          dispatch(updateCompanyFailed(response.data.errorMessage));
        }
      })
      .catch(error => {
        dispatch(updateCompanyFailed(error));
      });
  };
};

const updateCompanyFailed = message => {
  return {
    type: types.UPDATE_COMPANY_FAILED,
    data: message
  };
};

const updateCompanySuccess = data => {
  return {
    type: types.UPDATE_COMPANY_SUCCESS,
    data: data
  };
};

export const updatePerson = requestBody => {
  return dispatch => {
    axios
      .put("/contact/person/update", requestBody)
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch(updatePersonSuccess(response.data.data));
        } else {
          dispatch(updatePersonFailed(response.data.errorMessage));
        }
      })
      .catch(error => {
        dispatch(updatePersonFailed(error));
      });
  };
};

const updatePersonFailed = message => {
  return {
    type: types.UPDATE_PERSON_FAILED,
    data: message
  };
};

const updatePersonSuccess = data => {
  return {
    type: types.UPDATE_PERSON_SUCCESS,
    data: data
  };
};

export const resetAll = () => {
  return {
    type: 'RESET_ALL'
  }
}

export const resetStateCity = () => {
  return {
    type: 'RESET_STATE_CITY'
  }
}

export const resetCity = () => {
  return {
    type: 'RESET_CITY'
  }
}

export default {
  addNewPerson,
  addNewCompany,
  getAllStates,
  getAllCities,
  updateCompany,
  updatePerson,
  resetAll,
  resetStateCity,
  resetCity,
};
