import React, { useState, useEffect } from 'react';
import { Button, TextField, Grid, Dialog, DialogTitle, Divider, DialogContent } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SalesNewsDialogStyle from "./SalesNewsDialog.module.css"
import FormHelperText from '@material-ui/core/FormHelperText';

import PreviewNewsDialog from './PreviewNewsDialog'
import './PreviewNewsDialog.css'

const remoteCallback = async(e, callBack) => {
    //if(e.target.files.length){
        let header = '';
        var fr = new FileReader();
        fr.onload = (e) => {
            var arr = new Uint8Array(e.target.result).subarray(0, 4);
            for(let i = 0; i < arr.length; i++) {
                header += arr[i].toString(16);
            }
            mimeType(header, callBack);
        };
        fr.readAsArrayBuffer(e.target.files[0]);
    //}
}
const mimeType = (headerString, callBackFn) => {
    if(headerString.startsWith("ffd8ff")){
        headerString = headerString.slice(0,6);
    }
    switch(headerString){
        case "89504e47":
        case "ffd8ff":
            callBackFn(true);
            break;
        default:
            callBackFn(false);
            break;
    }
}

const SalesNewsDialog = (props) => {
    const [previewNewsModal, setPreviewNewsModal] = useState(false)
    const [errorTitle, seterrorTitle] = useState(false)
    const [errorFile, seterrorFile] = useState(false)
    const [errorDiscription, seterrorDiscription] = useState(false)
    const [errorimageType, seterrorimageType] = useState(false)
    const [errorimageSize, seterrorimageSize] = useState(false)
    return (
        <div>
            <Dialog
                className={SalesNewsDialogStyle.SalesNewsPopup}
                open={props.openModal ? props.openModal : false}
                onClose={() => {
                    seterrorTitle(false);
                    seterrorFile(false);
                    seterrorDiscription(false);
                    seterrorimageType(false);
                    seterrorimageSize(false);
                    props.onCloseNews();
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
            >
                <Grid container>
                    
                    <Grid item md={12} lg={12} xs={12} sm={12}>
                        <DialogTitle className={SalesNewsDialogStyle.newsTitle + " sales-pop-header-space"} id="sales-news-title">
                            <span className={SalesNewsDialogStyle.newsTitle}>Post news</span>
                            <IconButton className={SalesNewsDialogStyle.closeBtn} aria-label="Close" onClick={() => {
                                seterrorTitle(false);
                                seterrorDiscription(false);
                                seterrorFile(false);
                                seterrorimageType(false);
                                seterrorimageSize(false);
                                props.onCloseNews()
                            }}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                    </Grid>
                    
                    <Grid item md={12} lg={12} xs={12} sm={12}>
                        <Divider absolute={false} />
                    </Grid>

                    <Grid className={SalesNewsDialogStyle.dialogContents} container>
                        <Grid item md={6} lg={6} xs={12} sm={12}>
                            <div className={SalesNewsDialogStyle.newTitleLabelWrap}>
                                <label htmlFor="title" >News title <span className={SalesNewsDialogStyle.requiredSpan}>{" *"}</span></label>
                                <label htmlFor="title" className={SalesNewsDialogStyle.charTitle}>{100-props.title.length}{" chars left"}</label>
                            </div>
                            <input
                                autoFocus
                                maxLength="100"
                                onChange={(event) => {
                                    seterrorTitle(false)
                                    props.setNewsTitle(event.target.value)
                                }}
                                id="title" type="text"
                                value={props.title}
                                className={errorTitle ? SalesNewsDialogStyle.inputField+" "+SalesNewsDialogStyle.errorBorder : SalesNewsDialogStyle.inputField}
                            />
                            <FormHelperText className={SalesNewsDialogStyle.errorMessage}>{(props.title === "" && errorTitle) ? 'This field is required' : ' '}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6} sm={12}>
                            <Grid container>
                            
                                <label htmlFor="inputImageLabel" className={SalesNewsDialogStyle.uploadLabel}>Upload image<span className={SalesNewsDialogStyle.requiredSpan}>{" *"}</span></label>
                                <div  className={SalesNewsDialogStyle.inputFileWrapper} >
                                <label id="inputImageLabel" className={errorFile ? SalesNewsDialogStyle.labelFileWrapper+" "+SalesNewsDialogStyle.errorBorder : SalesNewsDialogStyle.labelFileWrapper} htmlFor="file" >
                                    <p className={SalesNewsDialogStyle.imgSpanSelect}>{props.file && !errorFile ? props.file.fileName : "Select image"}</p>
                                    <span className={SalesNewsDialogStyle.browseFileButton}>Browse</span>
                                </label>
                                {
                                    (errorFile) ? <FormHelperText className={SalesNewsDialogStyle.errorMessage}>
                                       {errorimageType ? "The image file is invalid or image type is not allowed. Allowed types: jpg, jpeg, png" : errorimageSize ?
                                        "Image size exceeds the maximum limit. Maximum image size allowed is 10MB" : "This field is required"} </FormHelperText> :
                                       <FormHelperText className={SalesNewsDialogStyle.imageHelperText} >
                                        Upload only .JPG, .PNG file, Total file size should not exceed 10MB
                                       </FormHelperText>                                     
                                }
                                <input type="file" name="file" id="file"
                                    onChange={(event) => {
                                        event.persist();
                                        remoteCallback(event, (imgValue) => {
                                            if(imgValue === true && event.target.files.length && 
                                                (props.maxSize > event.target.files[0].size && props.accept.indexOf(event.target.files[0].type.split("/")[1]) !== -1)){
                                                seterrorFile(false);
                                                seterrorimageType(false);
                                                seterrorimageSize(false);
                                                props.onChange(event, true);
                                                event.target.value = "";
                                            }else if(event.target.files.length && (imgValue === false || props.accept.indexOf(event.target.files[0].type.split("/")[1]) == -1)){
                                                seterrorFile(true);
                                                seterrorimageType(true);
                                                seterrorimageSize(false);
                                                props.onChange(event, false);
                                                event.target.value = "";
                                            }else if(event.target.files.length === 0){
                                                seterrorFile(false);
                                                seterrorimageType(false);
                                                seterrorimageSize(false);
                                                props.onChange(event, false);
                                                event.target.value = "";
                                            }else{
                                                seterrorFile(true);
                                                seterrorimageType(false);
                                                seterrorimageSize(true);
                                                props.onChange(event, false);
                                                event.target.value = "";
                                            }
                                        });
                                    }}
                                    multiple={props.multiple}
                                    name="_photos"
                                    accept={props.accept}
                                /> 
                                </div>
                                
                            </Grid>
                        </Grid>
                        <Grid className={SalesNewsDialogStyle.newsDescriptionContainer} item xs={12} md={12} lg={12} sm={12}>
                            <label htmlFor="discription">
                            News description<span className={SalesNewsDialogStyle.requiredSpan}>{" *"}</span>
                                    </label>
                                    <textarea rows="5" cols="70" style={{resize: 'none'}}
                                    className={errorDiscription ? SalesNewsDialogStyle.discriptionTextArea+" "+SalesNewsDialogStyle.errorBorder : SalesNewsDialogStyle.discriptionTextArea}
                                    id="discription"
                                    onChange={(event) => {
                                        seterrorDiscription(false)
                                        props.setNewDiscription(event.target.value)
                                    }}
                                    value={props.discription && props.discription}
                                type="text" />                        
                                    
                            <FormHelperText className={SalesNewsDialogStyle.errorMessageText}>{(props.discription === "" && errorDiscription) ? 'This field is required' : ' '}</FormHelperText>

                        </Grid>
                        <Grid justify="flex-end" container>
                            
                            <Grid item xs={12} md={12} lg={12} sm={12} className={SalesNewsDialogStyle.psticky}>
                                {<Button className={`${SalesNewsDialogStyle.btnAll + " pstatic"} 
                                ${SalesNewsDialogStyle.resetBtn}`} variant="contained"
                                    onClick={() =>{ 
                                        seterrorTitle(false);
                                        seterrorFile(false);
                                        seterrorDiscription(false);
                                        seterrorimageType(false);
                                        seterrorimageSize(false);
                                        props.resetFormData();
                                    }}>{props.resetButtonText}</Button>}
                                <Button className={`${SalesNewsDialogStyle.btnAll + " pstatic"} 
                                    ${SalesNewsDialogStyle.publishBtn}`}
                                    variant="contained"
                                    onClick={() => {
                                        if(props.title && props.discription && props.file) 
                                        {
                                            seterrorTitle(false);
                                            seterrorDiscription(false);
                                            seterrorFile(false);
                                            props.publishNews(props.currentUser.user_id) 
                                        }
                                        if(!props.title){
                                            seterrorTitle(true);
                                        }
                                        if(!props.discription){
                                            seterrorDiscription(true);
                                        }
                                        if(!props.file){
                                            seterrorFile(true)
                                        }
                                    }}>{props.publishButtonText}</Button>
                                {props.saveToDrafts && <Button className={`${SalesNewsDialogStyle.btnAll + " pstatic"}
                                 ${SalesNewsDialogStyle.saveBtn}`}
                                    variant="outlined"
                                    onClick={() => {
                                        if(props.title && props.discription && props.file) 
                                        {
                                            seterrorTitle(false);
                                            seterrorDiscription(false);
                                            seterrorFile(false);
                                            props.saveToDrafts(props.currentUser.user_id)
                                        }
                                        if(!props.title){
                                            seterrorTitle(true);
                                        }
                                        if(!props.discription){
                                            seterrorDiscription(true);
                                        }
                                        if(!props.file){
                                            seterrorFile(true)
                                        }
                                    }}>{props.saveToDraftsButtonText}
                                 </Button>}
                                <Button className={`${SalesNewsDialogStyle.btnAll + " pstatic"} 
                                ${SalesNewsDialogStyle.previewBtn}`}
                                    variant="text"
                                    onClick={() => { 
                                        if(props.title && props.discription && props.file) 
                                        {
                                            seterrorTitle(false);
                                            seterrorDiscription(false);
                                            seterrorFile(false);
                                            setPreviewNewsModal(!previewNewsModal)
                                        }
                                        if(!props.title){
                                            seterrorTitle(true);
                                        }
                                        if(!props.discription){
                                            seterrorDiscription(true);
                                        }
                                        if(!props.file){
                                            seterrorFile(true)
                                        } 
                                    }}>{props.previewButtonText}</Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Dialog>
            <PreviewNewsDialog
                onClosePreview={() => setPreviewNewsModal(!previewNewsModal)}
                previewNewsDialog={previewNewsModal}
                imagePreview={props.file && props.file.fileBody}
                title={props.title}
                discription={props.discription}
                userAvatar={props.currentUser && props.currentUser.user_avatar}
                userName={props.currentUser && props.currentUser.name}
            />
        </div>
    )
}
export default SalesNewsDialog

SalesNewsDialog.defaultProps = {
    // saveToDrafts: () => console.log("saveToDrafts"),
    // resetFormData: () => console.log("resetFormData"),
    // previewNews: () => console.log("previewNews"),
    multiple: false,
    accept: "image/jpeg, image/png",
    maxSize:10485760,
    resetButtonText:"Reset",
    saveToDraftsButtonText:"Save as draft",
    publishButtonText:"Publish",
    previewButtonText:"Preview"
}