import React, { Component } from "react";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import styles from "./ActivityTaskNote.module.scss";
import moment from "moment";
import axiosInstance from "../../../common/api-config";
import DatePicker from "react-date-picker";
import IconButton from "@material-ui/core/IconButton";
import { FormErrors } from "../../../common/formErrors/formErrors";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import CircularProgress from "../../../common/Loaders/CircularLoader/circular-loader";

const DialogTitle = withStyles(theme => ({
  root: {
    width: "100%",
    margin: 0,
    padding: theme.spacing.unit
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    width: "100%",
    margin: 0,
    padding: "0px 30px 30px 30px"
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    width: "100%",
    borderTop: `0`,
    margin: 0
  }
}))(MuiDialogActions);

class TaskForm extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
  }
  state = {
    description: "",
    lead_id: null,
    deadline: moment().format("YYYY-MM-DD"),
    errors: { descriptionError: "" },
    submitButton : false
  };

  componentDidMount() {
    this.textInput.focus();
    if (this.props.item) {
      const { item } = this.props;
      this.setState({
        description: item.description ? item.description : "",
        deadline: item.deadline
          ? moment(item.deadline).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD")
      });
    }
  }

  toggleCalendar = () => {
    if (this.state.isOpen) {
      this.setState({
        flag: "calendar",
        isOpen: false
      });
    } else {
      this.setState({
        flag: "calendar",
        isOpen: true
      });
    }
  };

  handleDateChange = date =>
    this.setState({ deadline: moment(date).format("YYYY-MM-DD") });

  dateChangeHandler = e => {};

  handleSaveTask = () => {
    if (this.state.description.trim() !== "") {
      if (this.props.item) {
        const reqParam = {
          id: this.props.item.id,
          lead_id: this.props.lead_id,
          description: this.state.description,
          deadline: this.state.deadline,
          is_completed: 0
          //user_id: JSON.parse(localStorage.getItem("user")).user_id
        };
        this.setState({submitButton : true})
        axiosInstance
          .put("/task/update", reqParam)
          .then(response => {
            if (response && response.data && response.data.statusCode === 200) {
              openSnackbar("Task updated successfully.", "snack-success");
              this.props.close("save_activity");
            } else {
              openSnackbar(response.data.errorMessage, "snack-error");
              this.props.close("");
            }
          })
          .catch(error => {
            openSnackbar(error.errorMessage, "snack-error");
            this.props.close("");
          });
      } else {
        this.setState({submitButton : true})
        const requestBody = {
          lead_id: this.props.lead_id,
          description: this.state.description,
          deadline: this.state.deadline,
          is_completed: 0
          //user_id: JSON.parse(localStorage.getItem("user")).user_id
        };
        axiosInstance
          .post("/task/add", requestBody)
          .then(response => {
            if (response && response.data && response.data.statusCode === 200) {
              openSnackbar("Task added successfully.", "snack-success");
              this.props.close("save_activity");
            } else {
              openSnackbar(response.data.errorMessage, "snack-error");
              this.props.close("");
            }
          })
          .catch(error => {
            openSnackbar(error.errorMessage, "snack-error");
            this.props.close("");
          });
      }
    } else {
      this.validateAllFields();
    }
  };

  validateAllFields = () => {
    let errors = { ...this.state.errors };
    if (this.state.description.trim() === "") {
      errors.descriptionError = "This field is required";
    }
    this.setState({ errors: errors });
  };

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  validateField = (fieldName, value) => {
    let errors = { ...this.state.errors };
    switch (fieldName) {
      case "description":
        if (value.trim() === "") {
          errors["description"] = "This field is required";
        } else {
          errors["description"] = "";
        }
        break;
      default:
        break;
    }
    this.setState({
      errors: errors
    });
  };

  render() {
    return (
      <div className={styles.add_tasks}>
        <DialogTitle id="customized-dialog-title" onClose={this.props.close} />
        <DialogContent>
          <div className="row">
            <div className="col-12 col-md-8">
              <div className={styles.form_group}>
                <label className={styles.form_title} htmlFor="description">
                  Task<span className={styles.required_field}>*</span>
                </label>
                <div className="notesHint">{(500 - this.state.description.length) +" characters left"}</div>
                <textarea
                  className={styles.textArea}
                  id="description"
                  name="description"
                  value={this.state.description}
                  onChange={this.handleChange}
                  ref={elem => (this.textInput = elem)}
                  maxLength={500}
                />
                <span className={styles.errorMessage}>
                  {this.state.errors.descriptionError}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <label className={styles.form_title} htmlFor="deadline">
                Deadline<span className={styles.required_field}>*</span>
              </label>
              <div className={styles.datepicker + " " + styles.form_group}>
                <input
                  value={this.state.deadline}
                  className={styles.inputStyle + " add_cursor_pointer"}
                  onClick={this.toggleCalendar}
                  onChange={this.dateChangeHandler}
                />
                <DatePicker
                  value={
                    new Date(
                      moment(this.state.deadline).year(),
                      moment(this.state.deadline).month(),
                      moment(this.state.deadline).date()
                    )
                  }
                  clearIcon={null}
                  calendarIcon={null}
                  isOpen={this.state.isOpen}
                  onCalendarClose={this.toggleCalendar}
                  onChange={this.handleDateChange}
                  minDate={new Date()}
                  maxDate={new Date(moment().add(20, 'years'))}
                />
              </div>
              <i className="date-picker-icon" />
            </div>
          </div>
          <DialogActions>
            {this.state.submitButton ? (
              <div className={styles.submit_loader}>
                <CircularProgress
                  style={{
                    color: "#19D98C"
                  }}
                />
              </div>
            ) : (
              <Button
                className={styles.saveButton}
                onClick={this.handleSaveTask}
                color="primary"
              >
                Save
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </div>
    );
  }
}

export default TaskForm;
