import React from "react";
import Dialog from "@material-ui/core/Dialog";
import NewContact from "../../newContact/NewContact.container";
import LeadContainer from "../../newLead/LeadContainer";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faNewspaper } from "@fortawesome/free-solid-svg-icons";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles, createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AccountBox from "@material-ui/icons/AccountBox";
import { NavLink, Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import leadXlogo from "../../../../assets/images/leadx-logo.png";
import { UncontrolledPopover } from "reactstrap";
import ProfilePopup from "../../../components/ProfilePopup/profile-popup";
import SalesNewsDialog from "../../../components/SalesNewsDialog/SalesNewsDialog";
import styles from "./aside-left.module.scss";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import { imageType } from '../../../common/common-types';
import DirectWin from "../../../components/DirectWin/DirectWin";
import asideLeftStyles from './aside-left.module.scss';
import LeadDetailPopup from '../../LeadDetailPopup/component/lead-detail-popup';
import { connect } from "react-redux";
import * as pipelineActions from '../../MyPipeline/redux/actions';
import { getStorageItem } from "../../../common/common-functions";
import imageCompression from 'browser-image-compression';

const drawerWidth = 202;
const theme = createMuiTheme({

  breakpoints: {
    values: {
      md: 992
    }
  }
});

const cssStyles = theme => ({

  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    border: "none",
    zIndex: "1000000",
    overflowX: "hidden",
    overflowY: "hidden"

  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  },
  margin: {
    margin: theme.spacing.unit
  }
});

class AsideLeft extends React.Component {
  state = {
    activeMenu: "Sales news",
    title: "",
    discription: "",
    previewNewsModal: false,
    mobileOpen: false,
    open: false,
    show: false,
    showDialog: false,
    showNewsDialog: false,
    user: {
      name: ""
    },
    popoverOpen: false,
    addCompanyFromLead: false,
    addPersonFromLead: false,
    directWin: false,
    currentSalesItem: null,
    filterValue: [1, 2, 3, 4, 5, 6], //all lead status,
    licenseType: ''
  };

  componentDidMount() {
    this.getCompanies();
    this.getCategorySources();
    this.props.getPoints();

    const countryListRequestBody = {
      arrayFilters: [{}],
      selectFilters: ["id", "name", "iso_code", "dialing_code", "is_city"],
      sort: {
        field: "name",
        sortOrder: "ASC"
      },
      paginate: {
        page: 0
      }
    };
    this.props.getCountries(countryListRequestBody);
    // const currentUser = JSON.parse(localStorage.getItem("user"));
    const currentUser = JSON.parse(getStorageItem("user"));
    if (currentUser) {
      this.setState({
        licenseType: currentUser.license_type.actual_name,
      });
    }
  }

  getCompanies() {
    let params = {
      arrayFilters: [
        {
          is_deleted: 0
        }
      ],
      // selectFilters: ["id", "company_name"],
      selectFilters: [],
      sort: {
        field: "company_name",
        sortOrder: "ASC"
      },
      paginate: {
        page: "",
        limit: ""
      }
    };
    this.props.getCompanies(params);
  }

  getCategorySources() {
    let categoryParams = {
        "arrayFilters": [
            {

            }
        ],
        "selectFilters": ["id", "name"],
        "sort": {
            "field": "name",
            "sortOrder": "ASC"
        },
        // "paginate": {
        //     "page": 0,
        //     "limit": 100
        // }
    };

    this.props.getCategorySources(categoryParams);
  }

  //MOBILE add new dialog for create new Lead/ Contact
  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  //DESKTOP add new contact dialog
  showDialog = async (company_id, companyObj) => {
    await this.setState({
      showDialog: true,
      open: false,
      selectedCompany: company_id,
      selectedCompanyObj: companyObj
    });

    if (!this.state.show) {
      var headerElement = document.getElementsByClassName(styles.appBar);
      headerElement[0].classList.add(styles.modal_zindex);

      var leftNavElement = document.getElementsByClassName(styles.brand);
      leftNavElement[0].parentNode.classList.add(styles.modal_zindex);
    }
  };

  logout() {
    this.props.userLogout();
  }

  hideDialog = newlyAddedItem => {
    this.setState({ showDialog: false });

    //If company gets added from lead form, return added company name to lead form
    if (this.state.addCompanyFromLead && newlyAddedItem) {
      this.setState({
        addCompanyFromLead: false,
        newlyAddedCompany: newlyAddedItem
      });
      this.getCompanies();
    } else if (this.state.addPersonFromLead && newlyAddedItem) {
      this.setState({
        addPersonFromLead: false,
        newlyAddedPerson: newlyAddedItem
      });
    } else {
      var headerElement = document.getElementsByClassName(styles.appBar);
      headerElement[0].classList.remove(styles.modal_zindex);

      var leftNavElement = document.getElementsByClassName(styles.brand);
      leftNavElement[0].parentNode.classList.remove(styles.modal_zindex);
    }
  };

  //DESKTOP add new lead dialog
  showModal() {
    this.setState({
      show: true,
      open: false
    });

    var headerElement = document.getElementsByClassName(styles.appBar);
    headerElement[0].classList.add(styles.modal_zindex);

    var leftNavElement = document.getElementsByClassName(styles.brand);
    leftNavElement[0].parentNode.classList.add(styles.modal_zindex);
  }

  hideModal = () => {
    this.setState({ show: false });
    var headerElement = document.getElementsByClassName(styles.appBar);
    headerElement[0].classList.remove(styles.modal_zindex);

    var leftNavElement = document.getElementsByClassName(styles.brand);
    leftNavElement[0].parentNode.classList.remove(styles.modal_zindex);
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  componentWillReceiveProps(nextProps) {
    // var user = JSON.parse(localStorage.getItem("user"));
    var user = JSON.parse(getStorageItem("user"));
    if (user == null) {
      this.props.history.push("/login");
    }
  }

  //****************************************************//
  //                 SALES NEWS FUNCTIONS               //
  //****************************************************//

  showNewsDialog = () => {
    // console.log("news modal opened");
    this.setState({
      showNewsDialog: true,
      open: false
    });
    var headerElement = document.getElementsByClassName(styles.appBar);
    headerElement[0].classList.add(styles.modal_zindex);

    var leftNavElement = document.getElementsByClassName(styles.brand);
    leftNavElement[0].parentNode.classList.add(styles.modal_zindex);
  };

  onCloseNews = () => {
    // console.log("news modal closed");
    this.setState({
      showNewsDialog: false,
      file: null,
      title: "",
      discription: ""
    });
    var headerElement = document.getElementsByClassName(styles.appBar);
    headerElement[0].classList.remove(styles.modal_zindex);

    var leftNavElement = document.getElementsByClassName(styles.brand);
    leftNavElement[0].parentNode.classList.remove(styles.modal_zindex);
  };

  setNewsTitle = title => {
    this.setState({
      title
    });
  };

  setNewDiscription = discription => {
    this.setState({
      discription
    });
  };

  saveToDrafts = user_id => {
    const params = {
      title: this.state.title,
      news_body: this.state.discription,
      attachment: this.state.file,
      is_active: 0
      // user_id: user_id
    };

    this.props.saveToDrafts(params);

    this.setState({
      showNewsDialog: false,
      file: null,
      title: "",
      discription: ""
    });
  };

  resetFormData = () => {
    this.setState({
      file: null,
      title: "",
      discription: ""
    });
  };

  //*************** Image uploading logic
  publishNews = user_id => {
    const params = {
      title: this.state.title,
      news_body: this.state.discription,
      attachment: this.state.file,
      is_active: 1
      // user_id: user_id
    };
    // const sales_params = {
    //   arrayFilters: [
    //     {
    //       is_active: 1,
    //       is_deleted: 0
    //     }
    //   ],

    //   selectFilters: [],
    //   sort: {
    //     field: "id",
    //     sortOrder: "DESC"
    //   },
    //   paginate: {
    //     page: 0,
    //     limit: 100
    //   }
    // };

    this.props.publishSalesNews(params);

    // setTimeout(() => {
    //   this.props.initializeSalesNews(sales_params);
    // }, 1800);

    this.setState({
      showNewsDialog: false,
      file: null,
      title: "",
      discription: ""
    });
  };

  previewNews = () => {
    this.setState({
      previewNewsModal: true
    });
  };

  onChange = (e, valid) => {
    if (valid == true) {
      let file = e.target.files[0];
      e.target.value = '';

      // checking file size if more than 2 mb than compress
      if ((file.size / 1024 / 1024) > 2) {
        const options = {
          maxSizeMB: 2,
          maxWidthOrHeight: 1920
        }
        imageCompression(file, options)
          .then((compressedFile) => {
            this.settingImage(compressedFile);
          })
          .catch((error) => {
            this.setState({
              file: null
            });
          });
      } else {
        this.settingImage(file);
      }
    } else {
      this.setState({
        file: null
      });
    }
  };

  settingImage = (file) => {
    this.getBase64(file, result => {
      this.setState({
        file: {
          fileBody: result,
          fileName: file.name,
          fileExtension: file.type,
          parentFolder: "sales_news"
        }
      });
    });
  }

  //***************  converting image into base 64
  getBase64(file, cb, sto) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb(reader.result);
    };
    reader.onerror = error => {
      console.log("Error: ", error);
    };
  }

  toggle(e) {
    if (e) {
      e.stopPropagation()
    }
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  openCompanyPopupFromLead() {
    this.setState({
      addCompanyFromLead: true,
      addPersonFromLead: false
    });

    this.showDialog();
  }

  openPersonPopup(company_id, company) {
    this.setState({
      addPersonFromLead: true,
      addCompanyFromLead: false
    });

    this.showDialog(company_id, company);
  }

  handleWinCallBack = (currentSalesItem) => {
    var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
    headerElement[0].classList.add("modal-zindex");

    var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
    leftNavElement[0].parentNode.classList.add("modal-zindex");

    this.setState({
      openLeadDetail: true,
      show: false,
      currentSalesItem
    })
  }

  handleClose = () => {
    this.setState({ open: false, openLeadDetail: false });
    var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
    if (headerElement && headerElement[0]) {
      headerElement[0].classList.remove("modal-zindex");
    }

    var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
    if (leftNavElement && leftNavElement[0]) {
      leftNavElement[0].parentNode.classList.remove("modal-zindex");
    }
    if (window.location.pathname === '/sales-news') {
      const requestBody = {
        arrayFilters: [
          {
            is_deleted: 0
          }
        ],
        selectFilters: ["id", "created_at", "action_type", "updated_at"],
        sort: {
          field: "created_at",
          sortOrder: "DESC"
        },
        paginate: {
          page: "0",
          limit: "10"
        }
      };
      this.props.initializeSalesFeeds(requestBody);
    }
  };

  activeMenuClickHandler = url => {
    this.setState({
      activeMenu: url,
      mobileOpen: false
    })
  }

  render() {
    let activeMenu = ""
    if (window.location.pathname.includes("sales-community")) {
      activeMenu = "Sales community";
    } else if (window.location.pathname.includes("sales-news")) {
      activeMenu = "Sales news";
    } else if (window.location.pathname.includes("my-pipeline")) {
      activeMenu = "My pipeline";
    } else if (window.location.pathname.includes("activities")) {
      activeMenu = "Activities";
    }

    const { open } = this.state;
    const { classes, theme, user } = this.props;
    // var user = JSON.parse(localStorage.getItem("user"));
    const drawer = (

      <div className={styles.brand}>
        <div className={styles.header_bar + " " + classes.toolbar} onClick={() => this.activeMenuClickHandler("Sales news")}>
          <Link to="/sales-news">
            <img
              className={styles.brand_logo_aside_left}
              src={leadXlogo}
              alt="brand-logo"
            />
          </Link>
        </div>
        <List className={styles.left_navigation}>
          {[
            {
              img: require("../../../../assets/images/menu-icon/news.png"),
              menu: "Sales news",
              url: "/sales-news",
              disabled: false
            },
            {
              img: require("../../../../assets/images/menu-icon/community.png"),
              menu: "Sales community",
              url: "/sales-community",
              disabled: false
            },
            {
              img: require("../../../../assets/images/menu-icon/pipeline.png"),
              menu: "My pipeline",
              url: "/my-pipeline",
              disabled: false
            },
            {
              img: (this.state.licenseType !== '' && this.state.licenseType === "BASIC")
                ? require("../../../../assets/images/menu-icon/d_activity.png")
                : require("../../../../assets/images/menu-icon/activity.png"),
              menu: "Activities",
              url: "/activities",
              disabled: this.state.licenseType !== '' && this.state.licenseType === 'BASIC' ? true : false
            }
            // {
            //   img: require("../../../../assets/images/menu-icon/activity.png"),
            //   menu: "Manage news",
            //   url: "/managenews",
            //   disabled: user ? user.license_type.actual_name === "BASIC" : false
            // }

            // {
            //   img: require("../../../../assets/images/menu-icon/more.png"),
            //   menu: "More",
            //   url: "/more"
            // }
          ].map((item, index) => (
            <div
              className={
                item.menu === activeMenu ? styles.activeNavBar : ""
              }
              onClick={() => this.activeMenuClickHandler(item.menu)}
              key={item.menu}
            >
              {item.disabled ? (
                <NavLink
                  to="#"
                  disabled
                  //onClick={e => e.preventDefault()}
                  title="Access denied! Please contact your sales manager to upgrade your license."
                >
                  <ListItem>
                    <div className={styles.icon_image_container}>
                      <img src={item.img} alt="menu-icon" />
                    </div>
                    <ListItemText
                      className={`${styles.menu_list} ${styles.disabled_activity}`}
                      primary={item.menu}
                    />
                  </ListItem>
                </NavLink>
              ) : (
                  <NavLink to={item.url}>
                    <ListItem>
                      <div className={styles.icon_image_container}>
                        <img src={item.img} alt="menu-icon" />
                      </div>
                      <ListItemText
                        className={styles.menu_list}
                        primary={item.menu}
                      />
                    </ListItem>
                  </NavLink>
                )}
              <Divider />
            </div>
          ))}
        </List>
      </div>
    );

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.root + " " + styles.header_edge_fix}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={styles.appBar + " " + classes.appBar}
          >
            <Toolbar className={styles.header_bar}>
              <div
                className={
                  "row header mar_left " +
                  styles.mobile_header_content +
                  " " +
                  styles.header_row
                }
              >
                <div
                  className={
                    "col-xl-1 col-lg-1 col-md-4 col-8 tab-logo " +
                    styles.mobile_logo_content
                  }
                >
                  <Link to="/sales-news">
                    <div
                      className={
                        styles.brand_logo +
                        " " +
                        styles.header_bar +
                        " " +
                        styles.brand_logo_mobile +
                        " " +
                        classes.toolbar
                      }
                    /></Link>
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={this.handleDrawerToggle}
                    className={classes.menuButton}
                  >
                    <MenuIcon />
                  </IconButton>
                </div>

                <div
                  className={
                    "col-xl-11 col-lg-11 col-md-8 col-4 padding_right " +
                    styles.header_row + " " + styles.create_box
                  }
                >

                  <span className={styles.add_icon} onClick={this.onOpenModal} />
                  <Link to="/profile" onClick={this.props.toggle}><div className="btn_mobile_view_profie" /></Link>
                  <button className="btn_mobile_desktop_hide" onClick={() => this.logout()}> <i className={styles.logout_icon} /></button>
                  <Modal open={open} onClose={this.onCloseModal} center>
                    <h2 className={styles.popup_title}>Create new:</h2>
                    <div className={styles.btn_wrapper}>
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={
                          styles.header_btn +
                          " " +
                          styles.creat_btn +
                          " " +
                          styles.creat_new_btn
                        }
                        onClick={() => this.showModal()}
                      >
                        Create new lead
                    </Button>
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={
                          styles.header_btn +
                          " " +
                          styles.creat_btn +
                          " " +
                          styles.margin_0 +
                          " " +
                          styles.remove_right_margin
                        }
                        onClick={() => this.showDialog()}
                      >
                        Create new contact
                    </Button>
                      {user &&
                        user.license_type &&
                        user.license_type.actual_name === "MANAGER" && (
                          <React.Fragment>
                            <Button
                              variant="contained"
                              size="medium"
                              color="primary"
                              className={
                                styles.header_btn +
                                " " +
                                styles.creat_btn +
                                " " +
                                styles.margin_0 +
                                " " +
                                styles.remove_right_margin
                              }
                              onClick={() => this.showNewsDialog()}
                            >
                              Create sales news
                              </Button>

                            <SalesNewsDialog
                              onCloseNews={this.onCloseNews}
                              title={this.state.title}
                              discription={this.state.discription}
                              onChange={this.onChange}
                              previewNews={this.previewNews}
                              publishNews={this.publishNews}
                              resetFormData={this.resetFormData}
                              saveToDrafts={this.saveToDrafts}
                              openModal={this.state.showNewsDialog}
                              setNewsTitle={this.setNewsTitle}
                              setNewDiscription={this.setNewDiscription}
                              file={this.state.file && this.state.file}
                              currentUser={user && user}
                              userna={user && user.name}
                            />
                          </React.Fragment>
                        )}
                    </div>
                  </Modal>
                  <div
                    className={
                      "row mar_left " + styles.header_row + " " + styles.create_content
                    }
                  >
                    <div
                      className={
                        "col-xl-8 col-lg-8 col-md-8 " +
                        styles.right_header_border +
                        " text-right " +
                        styles.btn_content
                      }
                    >
                      <div className={styles.btn_wrapper}>
                        <div className={styles.border_left_header}></div>
                        <Dialog
                          className="width"
                          aria-labelledby="customized-dialog-title"
                          open={this.state.show ? this.state.show : false}
                          onClose={this.hideModal}
                          maxWidth={"lg"}
                          className={styles.leadFromPopup}
                        >
                          <LeadContainer hideModal={this.hideModal}
                            handleWinCallBack={(lead) => this.handleWinCallBack(lead)}
                            openCompanyPopup={() => {
                              this.openCompanyPopupFromLead();
                            }}
                            openPersonPopup={(company_id, companyObj) =>
                              this.openPersonPopup(company_id, companyObj)
                            }
                            newlyAddedCompany={this.state.newlyAddedCompany}
                            newlyAddedPerson={this.state.newlyAddedPerson} />
                        </Dialog>

                        <Dialog
                          onClose={this.handleClose}
                          aria-labelledby="customized-dialog-title"
                          open={this.state.openLeadDetail ? this.state.openLeadDetail : false} maxWidth={'lg'}
                        >
                          <LeadDetailPopup
                            currentUser={user}
                            currentSalesItem={this.state.currentSalesItem}
                            onClose={() => this.handleClose()}
                            filterList={this.state.filterValue}
                            getPipelineLeadList={this.props.getPipelineLeadList} />
                        </Dialog>

                        <Dialog
                          className="width"
                          aria-labelledby="customized-dialog-title"
                          open={this.state.directWin ? this.state.directWin : false}
                          onClose={this.hideModal}
                          maxWidth={"lg"}
                          className={styles.leadFromPopup}
                        >
                          <DirectWin />
                        </Dialog>
                        <Button
                          variant="contained"
                          size="medium"
                          onClick={() => this.showModal()}
                          color="primary"
                          className={
                            classes.margin +
                            " " +
                            styles.header_btn +
                            " " +
                            styles.creat_btn +
                            " " +
                            styles.creat_new_btn
                          }
                        >
                          <i
                            className={
                              styles.btn_icon + " " + styles.creat_new_icon
                            }
                          />
                          Create new lead
                        </Button>
                        <Dialog
                          className="width"
                          aria-labelledby="customized-dialog-title"
                          open={this.state.showDialog ? this.state.showDialog : false}
                          onClose={this.hideDialog}
                          maxWidth={"lg"}
                          className="contactForm"
                        >
                          {this.state.addPersonFromLead ? (
                            <NewContact
                              {...this.props}
                              addCompanyFromLead={this.state.addCompanyFromLead}
                              addPersonFromLead={this.state.addPersonFromLead}
                              selectedCompany={this.state.selectedCompany}
                              selectedCompanyObj={this.state.selectedCompanyObj}
                              value={1}
                              hideDialog={newlyAddedItem =>
                                this.hideDialog(newlyAddedItem)
                              }
                            />
                          ) : this.state.addCompanyFromLead ? (
                            <NewContact
                              {...this.props}
                              addCompanyFromLead={this.state.addCompanyFromLead}
                              addPersonFromLead={this.state.addPersonFromLead}
                              selectedCompany={this.state.selectedCompany}
                              value={0}
                              hideDialog={newlyAddedItem =>
                                this.hideDialog(newlyAddedItem)
                              }
                            />
                          ) : (
                                <NewContact
                                  {...this.props}
                                  addCompanyFromLead={this.state.addCompanyFromLead}
                                  addPersonFromLead={this.state.addPersonFromLead}
                                  selectedCompany={this.state.selectedCompany}
                                  hideDialog={newlyAddedItem =>
                                    this.hideDialog(newlyAddedItem)
                                  }
                                  selectedCompanyObj={this.state.selectedCompanyObj}
                                />
                              )}
                        </Dialog>
                        <Button
                          variant="contained"
                          size="medium"
                          onClick={() => this.showDialog()}
                          color="primary"
                          className={`${classes.margin} ${styles.header_btn} ${styles.creat_contact_btn} ${user.license_type && user.license_type.actual_name !== "MANAGER" ? styles.remove_right_margin : ''}`}
                        >
                          <i
                            className={
                              styles.btn_icon + " " + styles.creat_contact_icon
                            }
                          />
                          Create new contact
                        </Button>
                        {this.state.licenseType !== '' && this.state.licenseType === "MANAGER" && (
                          <React.Fragment>
                            <Button
                              variant="contained"
                              size="medium"
                              onClick={() => this.showNewsDialog()}
                              color="primary"
                              className={
                                classes.margin +
                                " " +
                                styles.header_btn +
                                " " +
                                styles.creat_btn +
                                " " +
                                styles.remove_right_margin
                              }
                            >
                              <i
                                icon={faNewspaper}
                                className={
                                  styles.btn_icon +
                                  " " +
                                  styles.create_news_icon
                                }
                              />
                              Create sales news
                              </Button>

                            <SalesNewsDialog
                              onCloseNews={this.onCloseNews}
                              title={this.state.title}
                              discription={this.state.discription}
                              onChange={this.onChange}
                              previewNews={this.previewNews}
                              publishNews={this.publishNews}
                              resetFormData={this.resetFormData}
                              saveToDrafts={this.saveToDrafts}
                              openModal={this.state.showNewsDialog}
                              setNewsTitle={this.setNewsTitle}
                              setNewDiscription={this.setNewDiscription}
                              file={this.state.file && this.state.file}
                              currentUser={user && user}
                              userna={user && user.name}
                            />
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        "col-md-4 " +
                        styles.right_header_border +
                        " " +
                        styles.user_content
                      }
                    >
                      <div id="Popover1" className={"col-xl-10 " + styles.user_inner_content}>
                        <div className={styles.user_profile_image}>
                          <i className={styles.star_icon} />
                          {user && user.user_avatar ? (
                            (imageType.test(user.user_avatar) ? <img
                              src={
                                user.user_avatar.slice(0, user.user_avatar.lastIndexOf('/')) + "/small_" +
                                user.user_avatar.substring(user.user_avatar.lastIndexOf("/") + 1)
                              }
                              className={styles.header_icon}
                              style={{ display: "inline-block" }}
                              width="47"
                              height="47"
                            /> : <img
                                src={require("../../../../assets/userProfile.jpeg")}
                                className={styles.header_icon}
                                style={{ display: "inline-block" }}
                                width="47"
                                height="47"
                              />)
                          ) : (
                              <img
                                src={require("../../../../assets/userProfile.jpeg")}
                                className={styles.header_icon}
                                style={{ display: "inline-block" }}
                                width="47"
                                height="47"
                              />
                            )}
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "top"
                          }}
                        >
                          <div className={styles.user_name}>
                            {user && user.name ? user.name : ""}
                          </div>
                          <div className={styles.points}>{this.props.points} points</div>
                        </div>
                        <FontAwesomeIcon
                          icon={faChevronDown}

                          className={styles.profile_down_icon}
                        />
                        <UncontrolledPopover
                          trigger="legacy"
                          placement="bottom"
                          isOpen={this.state.popoverOpen}
                          target="Popover1"
                          toggle={this.toggle.bind(this)}
                          className={styles.profile_popover}

                        >
                          <ProfilePopup toggle={this.toggle.bind(this)} user={user} points={this.props.points} />
                        </UncontrolledPopover>
                      </div>
                      <div
                        className={styles.logout_content}
                        onClick={() => this.logout()}
                        title="Logout"
                      >
                        {/* logout icon */}
                        <i className={styles.logout_icon} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <nav className={classes.drawer}>
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                container={this.props.container}
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPipelineLeadList: requestBody => dispatch(pipelineActions.getPipelineLeadList(requestBody)),
  };
};

export default withStyles(cssStyles, { withTheme: true })(connect(null,
  mapDispatchToProps)(AsideLeft));