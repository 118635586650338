import React from 'react';
import { Button, DialogContentText, Grid, Dialog, DialogTitle, Divider, DialogContent } from '@material-ui/core';
import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './PreviewNewsDialog.css'
const PreviewNewsDialog = (props) => {
    return (

        <Dialog
            className={"dialogContainer sdddddddddddddddddddddddddddddddd"}
            open={props.previewNewsDialog ? props.previewNewsDialog : false }
            onClose={props.onClosePreview}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="lg"

        >
            <Grid container>

                <Grid item md={12} lg={12} xs={12} sm={12}>
                    <DialogTitle className={"titleText"} id="alert-dialog-title">
                        <strong>Preview</strong>
                        <IconButton className={"closeBtn"} aria-label="Close" onClick={() => props.onClosePreview()}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>

                </Grid>
                <Grid item md={12} lg={12} xs={12} sm={12}>
                        <Divider absolute={false} />
                    </Grid>
                <Grid style={{display:"block"}} item xs={12} md={12} lg={12} sm={12} >
                    <img className={"userAvatarIcon"} src={props.userAvatar ? props.userAvatar : require('../../../assets/userProfile.jpeg')} alt="user profile" />
                    
                        {
                            props.userName && <Typography className={"userName"} variant="subtitle2">{props.userName}</Typography>
                        }
                </Grid>
                <br/>
                <Grid item md={12} lg={12} xs={12} sm={12}>
                        <Grid className="imgPreviewWrap" item xs={12} md={12} lg={12} sm={12}>
                            <img className={"imgPreview"} alt="News image" src={props.imagePreview && props.imagePreview} />
                        </Grid>
                        <Grid className={"DialogContent"} container>
                            <Grid className={"newTitle"} item xs={12} md={12} lg={12} sm={12}>
                                <strong>
                                    {props.title}
                                </strong>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} sm={12}>
                                <DialogContentText className={"newsDiscriptionText"}>
                                    {props.discription}
                                </DialogContentText>
                            </Grid>
                        </Grid>
                </Grid>
            </Grid>
        </Dialog>


    )
}
export default PreviewNewsDialog

PreviewNewsDialog.defaultProps = {
    onClosePreview: () => console.log("saveToDrafts"),

    openModal: false,


}