import * as commonTypes from "../../../common/common-types";

const initialState = {
  personListForCompanyDetails: null,
  personCount: 0,
  errorMessage: '',
  isFetching: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case commonTypes.HANDLE_LOADING:
      return{
        ...state,
        isFetching: action.data
      }
    case 'PERSON_LIST_SUCCESS':
      return { 
        ...state, 
        personListForCompanyDetails: action.data.rows,
        personCount: action.data.count,
      };
    case 'LOAD_MORE_PERSON_LIST_SUCCESS':
      let finalArr = [...state.personListForCompanyDetails, ...action.data.rows];
      return{
        ...state, 
        personListForCompanyDetails: finalArr,
        personCount: action.data.count,
      }
    case 'PERSON_LIST_FAILED':
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    default:
      return state;
  }
};

export default reducer;
