import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import companyIcon from '../../../../assets/images/content-images/company_pink.png';
import contactIcon from '../../../../assets/images/content-images/contact_icon_blue.png';
import { connect } from "react-redux";
import * as actions from "../redux/action";
import Divider from "@material-ui/core/Divider";
import './companyDetailPopup.scss';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: '20px'
    },
    title: {
        fontSize: 20
    },
    margin: {
        margin: theme.spacing.unit,
    },
    textField: {
        flexBasis: 200,
    },
    dialog_content: {
        padding: 0
    }
});

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
        width: '100%'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2,
        overflowX: 'hidden'
    },
}))(MuiDialogContent);

class CompanyDetailPopup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            personListForCompany: []
        };
    }

    componentDidMount() {
        let params = {
            "arrayFilters": [
                {
                    "is_deleted": 0,
                    "company_id": this.props.companyItem ? this.props.companyItem.id : 0 
                }
            ],
            "selectFilters": [],
            "sort": {
                "field": "first_name",
                "sortOrder": "ASC"
            },
            "paginate": {
                "page": 0,
                "limit": 10
            }
        };

        this.props.getPersonList(params);
    }

    render() {
        const { companyItem } = this.props;
        let keys = this.props.personListForCompanyDetails ? this.props.personListForCompanyDetails.map(item => { return Object.keys(item)[0] }) : null;
        return (
            <div>
                <DialogTitle id="customized-dialog-title" onClose={this.props.handleClose}>
                    <div className="person-dialog">
                        <img src={companyIcon} height="28px" width="28px" className="header-image contact-popup-top-img" />
                        <div className="person-dialog-title contact-popup-top">
                            <div className="dialog-title">{companyItem && companyItem.company_name ? companyItem.company_name : ' - '}</div>
                            <div className="dialog-company-name">
                                <span className="dialog-content-title dialog-content-title-left">Company registration number: </span>
                                <span className="dialog-content-value">{companyItem && companyItem.company_code ? companyItem.company_code : ' - '}</span>
                            </div>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="pipeline-popup-content">
                        <div className="row pipeline-popup-row">
                            <div className="col-md-12 pipeline-popup-grid">
                                <span className="dialog-content-title">Office address line 1:</span>
                                <span className="dialog-content-value">
                                    {companyItem && companyItem.office_address_line_1 ?
                                        companyItem.office_address_line_1 : ' - '}
                                </span>
                            </div>
                        </div>
                        <div className="row pipeline-popup-row">
                            <div className="col-md-12 pipeline-popup-grid">
                                <span className="dialog-content-title">Office address line 2:</span>
                                <span className="dialog-content-value">
                                    {companyItem && companyItem.office_address_line_2 ?
                                        companyItem.office_address_line_2 : ' - '}
                                </span>
                            </div>
                        </div>
                        <div className="row pipeline-popup-row">
                            <div className="col-md-4 pipeline-popup-grid">
                                <span className="dialog-content-title">Zip/Postal address:</span>
                                <span className="dialog-content-value">
                                    {companyItem && companyItem.zipcode ? companyItem.zipcode : ' - '}
                                </span>
                            </div>
                            <div className="col-md-4 pipeline-popup-grid">
                                <span className="dialog-content-title">Country registered: </span>
                                <span className="dialog-content-value">{companyItem && companyItem.country && companyItem.country.name ? companyItem.country.name : ' - ' }</span>
                            </div>
                            <div className="col-md-4 pipeline-popup-grid">
                                <span className="dialog-content-title">State: </span>
                                <span className="dialog-content-value">{companyItem && companyItem.state && companyItem.state.name ? companyItem.state.name : ' - '}</span>
                            </div>
                        </div>
                        <div className="row pipeline-popup-row">
                            <div className="col-md-4 pipeline-popup-grid">
                                <span className="dialog-content-title">City: </span>
                                <span className="dialog-content-value">{companyItem && companyItem.city && companyItem.city.name ? companyItem.city.name : ' - '}</span>
                            </div>
                            <div className="col-md-4 pipeline-popup-grid">
                                <span className="dialog-content-title">Website: </span>
                                <span className="dialog-content-value">{companyItem && companyItem.website ? companyItem.website : ' - '}</span>
                            </div>
                            <div className="col-md-4 pipeline-popup-grid">
                                <span className="dialog-content-title">Phone number: </span>
                                <span className="dialog-content-value">{companyItem && companyItem.countryCode && companyItem.contact_person_phone ? "+" + companyItem.countryCode.dialing_code + " " + companyItem.contact_person_phone : companyItem.contact_person_phone !== "" ? companyItem.contact_person_phone : " - "}</span>
                            </div>
                        </div>
                        <div className="row pipeline-popup-row">
                            <div className="col-md-4 pipeline-popup-grid">
                                <span className="dialog-content-title">Email address: </span>
                                <span className="dialog-content-value">{companyItem && companyItem.contact_person_email ? companyItem.contact_person_email : ' - '}</span>
                            </div>
                        </div>
                        <div className="row pipeline-popup-row">
                            <div className="col-md-12 pipeline-popup-grid">
                                <span className="dialog-content-title">Note: </span>
                                <span className="dialog-content-value">{companyItem && companyItem.notes ? companyItem.notes : ' - '}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row dialog-btn">
                        <div className="col-md-12 text-right">
                            <button type="button" className="update-btn" onClick={() => this.props.updateCompany()}>Update</button>
                        </div>
                    </div>
                    { this.props.personListForCompanyDetails && this.props.personListForCompanyDetails.length > 0 ? <Divider /> : ''}
                    { this.props.personListForCompanyDetails && this.props.personListForCompanyDetails.length > 0 ?
                        <div className="personListSection">
                        {
                            this.props.personListForCompanyDetails ? this.props.personListForCompanyDetails.map((item, index) => {
                                return (
                                    <div className="row contact-list" key={index}>
                                        <div className="contact-col-1">
                                            <span className="person-initial">{keys[index].toUpperCase()}</span>
                                        </div>
                                        <div className="contact-col-2">
                                            <div className="row person-list-grid">
                                                {
                                                    item[keys[index]].map((person, index) => {
                                                        return (
                                                            <div className="col-md-6 person-item" key={'person_' + index} >
                                                                <div className="display-inline contact-item-inner" style={{ width: '100%' }}>
                                                                    <div className="contact-first">
                                                                        <img className="display-inline contact-list-display-image" src={contactIcon} alt="contact" />
                                                                        <div className="display-inline left-section">
                                                                            <div className="contact-name">{person.name}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="display-inline right-section" >
                                                                        <div className="right-phone-text">{person.phone_number}</div>
                                                                        <div className="right-email-text">{person.email}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            }) : ''
                        }
                        </div>
                     : ''
                    }
                </DialogContent>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
      personListForCompanyDetails: state.companyDetails.personListForCompanyDetails,
      personCount: state.companyDetails.leadsCount,
      errorMessage: state.companyDetails.errorMessage,
      isFetching: state.companyDetails.isFetching
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      getPersonList: requestBody => dispatch(actions.getPersonList(requestBody)),
    };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(CompanyDetailPopup));