import React from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import contactIcon from "../../../../assets/images/content-images/contact_icon_blue.png";

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: "20px"
  },
  title: {
    fontSize: 20
  },
  margin: {
    margin: theme.spacing.unit
  },
  textField: {
    flexBasis: 200
  },
  dialog_content: {
    padding: 0
  }
});

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
    width: "100%"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
    overflowX: "hidden"
  }
}))(MuiDialogContent);

class PersonDetailPopup extends React.Component {
  render() {
    const { personItem } = this.props;
    return (
      <div>
        <DialogTitle
          id="customized-dialog-title"
          onClose={this.props.handleClose}
        >
          <div className="person-dialog">
            <img
              src={contactIcon}
              height="28px"
              width="28px"
              className="header-image contact-popup-top-img"
            />
            <div className="person-dialog-title contact-popup-top">
              <div className="dialog-title">
                {personItem && personItem.name ? personItem.name : "-"}
              </div>
              <span className="dialog-title-name">
                {personItem && personItem.title && personItem.title.trim() !== ""
                  ? `(${personItem.title}) `
                  : null}
              </span>
              <span className="dialog-company-name">
                {personItem &&
                  personItem.contact_company &&
                  personItem.contact_company.company_name
                  ? personItem.contact_company.company_name
                  : " - "}
              </span>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="pipeline-popup-content">
            <div className="row pipeline-popup-row">
              <div className="col-md-6 pipeline-popup-grid">
                <span className="dialog-content-title">Email address: </span>
                <span className="dialog-content-value">
                  {personItem && personItem.email ? personItem.email : " - "}
                </span>
              </div>
              <div className="col-md-6 pipeline-popup-grid">
                <span className="dialog-content-title">Phone number: </span>
                <span className="dialog-content-value">
                  {personItem &&
                    personItem.phone_number &&
                    personItem.countryCode
                    ? "+" +
                    personItem.countryCode.dialing_code +
                    " " +
                    personItem.phone_number
                    : " - "}
                </span>
              </div>
              <div className="col-md-3"></div>
            </div>

            <div className="row pipeline-popup-row">
              <div className="col-md-12 pipeline-popup-grid">
                <span className="dialog-content-title">Address line 1: </span>
                <span className="dialog-content-value">
                  {personItem && personItem.address_line_1
                    ? personItem.address_line_1
                    : " - "}
                </span>
              </div>
            </div>
            <div className="row pipeline-popup-row">
              <div className="col-md-12 pipeline-popup-grid">
                <span className="dialog-content-title">Address line 2: </span>
                <span className="dialog-content-value">
                  {personItem && personItem.address_line_2
                    ? personItem.address_line_2
                    : " - "}
                </span>
              </div>
            </div>
            <div className="row pipeline-popup-row">
              <div className="col-md-4 pipeline-popup-grid">
                <span className="dialog-content-title">
                  Zip/Postal address:{" "}
                </span>
                <span className="dialog-content-value">
                  {personItem && personItem.zipcode
                    ? personItem.zipcode
                    : " - "}
                </span>
              </div>
              <div className="col-md-4 pipeline-popup-grid">
                <span className="dialog-content-title">Country: </span>
                <span className="dialog-content-value">
                  {personItem && personItem.country && personItem.country.name
                    ? personItem.country.name
                    : " - "}
                </span>
              </div>
              <div className="col-md-4 pipeline-popup-grid">
                <span className="dialog-content-title">State: </span>
                <span className="dialog-content-value">
                  {personItem && personItem.state && personItem.state.name
                    ? personItem.state.name
                    : " - "}
                </span>
              </div>
            </div>
            <div className="row pipeline-popup-row">
              <div className="col-md-4 pipeline-popup-grid">
                <span className="dialog-content-title">City: </span>
                <span className="dialog-content-value">
                  {personItem && personItem.city && personItem.city.name
                    ? personItem.city.name
                    : " - "}
                </span>
              </div>
              <div className="col-md-8 pipeline-popup-grid">
                <span className="dialog-content-title">Note: </span>
                <span className="dialog-content-value">
                  {personItem && personItem.notes ? personItem.notes : " - "}
                </span>
              </div>
            </div>
            <div className="row dialog-btn dialog-btn-bottom dialog-btn-bottom-adding">
              <div className="col-md-12 text-right">
                <button
                  type="button"
                  className="update-btn"
                  onClick={() => this.props.updatePerson()}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </DialogContent>
      </div>
    );
  }
}
export default withStyles(styles)(PersonDetailPopup);
