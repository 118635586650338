const initialState = {
  userDetails: null,
  updatedUserDetailsFlag: false,
  errorMessage: '',
  loadingData: true,
  profilePhoto: undefined,
  removePhoto: undefined,
  errorLoadingUserProfile: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_DETAILS_SUCCESS':
      return { 
        ...state, 
        userDetails: action.data,
        loadingData: false,
        profilePhoto: undefined,
        removePhoto: undefined,
        errorLoadingUserProfile: false
      };
    case 'USER_DETAILS_ERROR':
      return {
        ...state,
        loadingData: false,
        errorMessage: action.errorMessage,
        errorLoadingUserProfile: true
      }
    case 'USER_UPDATE_SUCCESS':
      return {
        ...state,
        loadingData: false,
        updatedUserDetailsFlag: true,
        profilePhoto: action.profilePhoto,
        removePhoto: action.removePhoto
      }
    case 'USER_UPDATE_ERROR': 
      return {
        ...state,
        updatedUserDetailsFlag: false,
        loadingData: false,
        errorMessage: action.data
      }
    case 'RESET_USER_UPDATE_FLAG':
      return {
        ...state,
        loadingData: false,
        updatedUserDetailsFlag: false
      }
    case 'LOADING': 
      return{
        ...state,
        loadingData: true
      }
    case 'RESET_ERROR_MESSAGE' :
      return {
        ...state,
        errorMessage: '',
      }
    default:
      return state;
  }
};

export default reducer;