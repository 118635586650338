import * as types from "./types";

export const filterSelected = num => {
  switch (num) {
    case 1:
      return { type: types.NEW_SELECTED };

    case 2:
      return { type: types.LEAD_SELECTED };

    case 3:
      return { type: types.OPPORTUNITY_SELECTED };

    case 4:
      return { type: types.PROPOSAL_SELECTED };

    case 5:
      return { type: types.NEGOTIATION_SELECTED };

    case 6:
      return { type: types.CLOSED_SELECTED };

    case 0:
    case 7:
      return { type: types.RESET_FILTER };
    default:
      return { type: types.RESET_FILTER };
  }
};

export const filterDeselected = num => {
  switch (num) {
    case 1:
      return { type: types.NEW_REMOVED };

    case 2:
      return { type: types.LEAD_REMOVED };

    case 3:
      return { type: types.OPPORTUNITY_REMOVED };

    case 4:
      return { type: types.PROPOSAL_REMOVED };

    case 5:
      return { type: types.NEGOTIATION_REMOVED };

    case 6:
      return { type: types.CLOSED_REMOVED };

    case 0:
    case 7:
      return { type: types.RESET_FILTER };
    default:
      return { type: types.RESET_FILTER };
  }
};

export const parkFilterSelected = () => {
  return { type: types.PARKED_FILTER_SELECTED }
}

export const parkFilterDeselected = () => {
  return { type: types.PARKED_FILTER_DESELECTED }
}
