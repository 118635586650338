import React, { Component } from "react";
import ResetPasswordComponent from "./component/ResetPasswordComponent";
import { withStyles } from "@material-ui/core/styles";
// import crypto from "crypto";
import CryptoJS from "crypto-js";
import axiosInstance from "../../common/api-config";
import { openSnackbar } from "../../common/Snackbar/snackbar";
import { Dialog, Modal, Button } from "@material-ui/core";
import styles from "./ResetPasswordContainer.module.scss";
import moment from "moment";

const stylesMaterialUI = theme => ({
  input: {
    borderBottom: "none",
    color: "black",
    "&:focus": {
      borderBottom: "none"
    }
  }
});

class ResetPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
  }
  state = {
    resetPasswordForm: {
      password: {
        value: "",
        valid: false,
        errorMessage: ""
      },
      confirmPassword: {
        value: "",
        valid: false,
        errorMessage: ""
      }
    },
    isLoading: true,
    // email: "",
    token: "",
    successPopup: false
  };

  componentDidMount() {
    const path = window.location.pathname;
    const pathArray = path.split("/");
    const token = decodeURIComponent(pathArray[2]);
    
    const reqParams = {
      token: encodeURIComponent(token)
    };
    axiosInstance
      .post("/login/checkTokenExist", reqParams)
      .then(response => {
        
        if (response.data.statusCode === 200) {
                    
          // const decipher = crypto.createDecipher("aes192", "7u8i9o0p");
          // let tokenString = decipher.update(token, "hex", "utf8");
          // tokenString += decipher.final("utf8");

          const bytes = CryptoJS.AES.decrypt(token, process.env.REACT_APP_CRYPTO_KEY);          
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

          const expiryTime = decryptedData.split("|")[1];
          const currentTime = new Date().getTime();
          if (expiryTime < currentTime) {
            this.timeExpiry();
          } else {
            this.setState({ token: token, isLoading: false });
          }
        } else {
          // this.setState({ isLoading: false });
          // openSnackbar(
          //   "It looks like you clicked on an invalid password reset link. Please try again.",
          //   "snack-error",
          //   10000
          // );
          // this.props.history.push("/forgot-password");
          this.timeExpiry();          
        }
      })
      .catch(error => {
        this.timeExpiry();
      });
  }

  timeExpiry = () => {
    this.setState({ isLoading: false });
    openSnackbar(
      "Your password reset link expired after 30 minutes or has already been used. Please try again.",
      "snack-error",
      10000
    );
    this.props.history.push("/forgot-password");
  };

  handleInputChanged = e => {
    let name = e.target.name;
    let password = e.target.value.trim();
    const updatedresetPasswordForm = {
      ...this.state.resetPasswordForm
    };
    const updatedFormElement = {
      ...updatedresetPasswordForm[name]
    };
    updatedFormElement.value = password;
    updatedFormElement.touched = true;
    const passwordPattern = /^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*()_\~\-\`\\/\"\'+|\[\]}{:;'?/>.<,])(?!.*\s)(?!.*\s).{8,20}$/;
    if (password.trim() === "") {
      updatedFormElement.errorMessage = "Please enter a 'new password'";
      updatedFormElement.valid = false;
    } else if (!passwordPattern.test(password) || password.length < 8) {
      updatedFormElement.errorMessage =
        "Password should be at least 8 characters long and should contain at least one upper case letter, one number, one special character and no spaces.";
      updatedFormElement.valid = false;
    }
    //  else if (password.length < 12) {
    //   updatedFormElement.errorMessage = "Password too short";
    //   updatedFormElement.valid = false;
    // } else if (password.length > 12) {
    //   updatedFormElement.errorMessage = "Password too long";
    //   updatedFormElement.valid = false;
    // }
    else {
      if (name === "confirmPassword") {
        if (password !== this.state.resetPasswordForm.password.value) {
          updatedFormElement.errorMessage = "Passwords do not match";
          updatedFormElement.valid = false;
        } else {
          updatedFormElement.errorMessage = "";
          updatedFormElement.valid = true;
        }
      } else if (
        name === "password" &&
        this.state.resetPasswordForm.confirmPassword.value !== ""
      ) {
        if (password !== this.state.resetPasswordForm.confirmPassword.value) {
          //updatedFormElement.errorMessage = "Passwords do not match";
          //updatedFormElement.valid = false;
          updatedresetPasswordForm["confirmPassword"].valid = false;
          updatedresetPasswordForm["confirmPassword"].errorMessage =
            "'Confirm new password' should be the same as 'new password'.";
          updatedFormElement.errorMessage = "";
          updatedFormElement.valid = true;
        } else {
          updatedresetPasswordForm["confirmPassword"].errorMessage = "";
          updatedresetPasswordForm["confirmPassword"].valid = true;
          updatedFormElement.errorMessage = "";
          updatedFormElement.valid = true;
        }
      } else {
        updatedFormElement.errorMessage = "";
        updatedFormElement.valid = true;
      }
    }
    updatedresetPasswordForm[name] = updatedFormElement;
    this.setState({
      resetPasswordForm: updatedresetPasswordForm
    });
  };

  // handleOnBlur = e => {
  //   let name = e.target.name;
  //   let password = e.target.value;
  //   const updatedresetPasswordForm = {
  //     ...this.state.resetPasswordForm
  //   };
  //   const updatedFormElement = {
  //     ...updatedresetPasswordForm[name]
  //   };
  //   updatedFormElement.value = password.trim();
  //   updatedFormElement.touched = true;
  //   const passwordPattern = /^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*()_\~\-\`\\/\"\'+|\[\]}{:;'?/>.<,])(?!.*\s)(?!.*\s).{8,20}$/;
  //   if (password.trim() === "") {
  //     updatedFormElement.errorMessage = "Please enter a 'new password'";
  //     updatedFormElement.valid = false;
  //   } else if (!passwordPattern.test(password) || password.length < 8) {
  //     updatedFormElement.errorMessage =
  //       "Password should be at least 8 characters long and should contain at least one upper case letter, one number, one special character and no spaces.";
  //     updatedFormElement.valid = false;
  //   }
  //   //  else if (password.length < 12) {
  //   //   updatedFormElement.errorMessage = "Password too short";
  //   //   updatedFormElement.valid = false;
  //   // } else if (password.length > 12) {
  //   //   updatedFormElement.errorMessage = "Password too long";
  //   //   updatedFormElement.valid = false;
  //   // }
  //   else {
  //     if (name === "confirmPassword") {
  //       if (password !== this.state.resetPasswordForm.password.value) {
  //         updatedFormElement.errorMessage = "Passwords do not match";
  //         updatedFormElement.valid = false;
  //       } else {
  //         updatedFormElement.errorMessage = "";
  //         updatedFormElement.valid = true;
  //       }
  //     } else {
  //       updatedFormElement.errorMessage = "";
  //       updatedFormElement.valid = true;
  //     }
  //   }
  //   updatedresetPasswordForm[name] = updatedFormElement;
  //   this.setState({
  //     resetPasswordForm: updatedresetPasswordForm
  //   });
  // };

  // handleInputChanged = e => {
  //   if (e.target.name === "confirmPassword") {
  //     if (this.state.resetPasswordForm.password.value === e.target.value) {
  //       this.setState({
  //         resetPasswordForm: {
  //           ...this.state.resetPasswordForm,
  //           confirmPassword: {
  //             ...this.state.resetPasswordForm["confirmPassword"],
  //             value: e.target.value.trim(),
  //             valid: true
  //           }
  //         }
  //       });
  //     } else {
  //       this.setState({
  //         resetPasswordForm: {
  //           ...this.state.resetPasswordForm,
  //           confirmPassword: {
  //             ...this.state.resetPasswordForm["confirmPassword"],
  //             value: e.target.value.trim(),
  //             valid: false
  //           }
  //         }
  //       });
  //     }
  //   } else {
  //     this.setState({
  //       resetPasswordForm: {
  //         ...this.state.resetPasswordForm,
  //         password: {
  //           ...this.state.resetPasswordForm["password"],
  //           value: e.target.value.trim()
  //         }
  //       }
  //     });
  //   }
  // };

  handleSubmit = e => {
    e.preventDefault();
    if (this.checkValidity()) {
      this.setState({
        isLoading: true
      });
      const requestParams = {
        password: this.state.resetPasswordForm.password.value
      };
      axiosInstance
        .post("/login/reset/" + encodeURIComponent(this.state.token) , requestParams)
        .then(response => {
          this.setState({
            isLoading: false
          });
          if (response.data.statusCode === 200) {
            this.setState({ successPopup: true });
          } else {
            openSnackbar(response.data.errorMessage, "snack-error");
            this.props.history.push("/");
          }
        })
        .catch(error => {
          this.setState({
            isLoading: false
          });
          openSnackbar(error, "snack-error");
          this.props.history.push("/");
        });
    }
  };
  okButtonClicked = e => {
    e.preventDefault();
    this.props.history.push("/login"); // this will redirect to intended page
  };

  checkValidity = () => {
    let password = { ...this.state.resetPasswordForm.password };
    let confirmPassword = { ...this.state.resetPasswordForm.confirmPassword };
    if (password.value.trim() === "") {
      password.valid = false;
      password.errorMessage = "This field is required";
    }

    if (confirmPassword.value === "") {
      confirmPassword.valid = false;
      confirmPassword.errorMessage = "This field is required";
    }

    this.setState({ resetPasswordForm: { password, confirmPassword } });

    return (
      this.state.resetPasswordForm.password.valid &&
      this.state.resetPasswordForm.confirmPassword.valid
    );
  };

  render() {
    const resetPasswordComponentProps = { ...this.props };
    return (
      <div>
        <ResetPasswordComponent
          {...resetPasswordComponentProps}
          password={this.state.resetPasswordForm.password}
          confirmPassword={this.state.resetPasswordForm.confirmPassword}
          handleSubmit={this.handleSubmit}
          handleInputChanged={this.handleInputChanged}
          handleConfirmPassword={this.handleConfirmPassword}
          isLoading={this.state.isLoading}
          email={this.state.email}
          validity={
            this.state.resetPasswordForm.password.valid &&
            this.state.resetPasswordForm.confirmPassword.valid
          }
          //handleOnBlur={this.handleOnBlur}
          reference={elem => (this.textInput = elem)}
        />
        <Modal open={this.state.successPopup}>
          <div className={styles.reset_password_popup}>
            <div>
              <div className={styles.tick}>
                <div className={styles.logo}>
                  <div className={styles.checkmark} />
                </div>
              </div>
              <div className={styles.message}>
                <h2>Password reset successfully.</h2>
              </div>
              <div className={styles.okButton}>
                <Button className={styles.btn}
                  autoFocus
                  onClick={this.okButtonClicked}>
                  Ok
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withStyles(stylesMaterialUI)(ResetPasswordContainer);
