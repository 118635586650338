import * as types from "./types";

const initialState = {
  addNewPersonError: null,
  addNewCompanyError: null,
  newlyAddedCompany: null,
  newlyAddedPerson: null,
  stateList: null,
  cityList: null,
  updateCompanyError: null,
  updatePersonError: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_NEW_PERSON_SUCCESS:
      return {
        ...state,
        newlyAddedPerson: action.data,
        addNewPersonError: false
      };

    case types.ADD_NEW_PERSON_FAILED:
      return {
        ...state,
        addNewPersonError: true
      };

    case types.ADD_NEW_COMPANY_SUCCESS:
      return {
        ...state,
        newlyAddedCompany: action.data,
        addNewCompanyError: false
      };

    case types.ADD_NEW_COMPANY_FAILED:
      return {
        ...state,
        newlyAddedCompany: null,
        addNewCompanyError: true
      };

    case types.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        updateCompanyError: false
      };

    case types.UPDATE_COMPANY_FAILED:
      return {
        ...state,
        updateCompanyError: true
      };

    case types.UPDATE_PERSON_SUCCESS:
      return {
        ...state,
        updatePersonError: false
      };

    case types.UPDATE_PERSON_FAILED:
      return {
        ...state,
        updatePersonError: true
      };

    case types.GET_ALL_STATES:
      return {
        ...state,
        stateList: action.data.rows
      };

    case types.GET_ALL_CITIES:
      return {
        ...state,
        cityList: action.data.rows
      };

    case 'RESET_ALL':
      return {
        ...state,
        addNewPersonError: null,
        addNewCompanyError: null,
        updateCompanyError: null,
        updatePersonError: null
      }

    case 'RESET_STATE_CITY':
      return {
        ...state,
        stateList: null,
        cityList: null,
      }

    case 'RESET_CITY':
      return {
        ...state,
        cityList: null
      }
    default:
      return state;
  }
};

export default reducer;
