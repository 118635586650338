import React, { Component } from "react";
import { Grid, Dialog, DialogActions, DialogTitle,Button } from '@material-ui/core'
import NewsCard from "../../../components/NewsCard/NewsCard";
import SalesNewsDialog from '../../../components/SalesNewsDialog/SalesNewsDialog'
import './InDraft.css'
import styles from "../../aside-left/component/aside-left.module.scss";
import NoRecordFound from "../../../components/NoRecordFound/NoRecordFound"
import {openSnackbar} from '../../../common/Snackbar/snackbar';
import LargeScreenLoader from "../../../common/Loaders/PulseLoader/pulse-loader";
import imageCompression from 'browser-image-compression';

class InDraft extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      discription: "",
      openNewEditor: false,
      file: null,
      updateImageFlag: false,
      updateFlag: false,
      deleteConfirmationFlag:false,
      count: 1
    }
  }

  onCloseNews = () => {
    this.setState({
      openNewEditor: false
    })
    
    var headerElement = document.getElementsByClassName(styles.appBar);
    headerElement[0].classList.remove(styles.modal_zindex);

    var leftNavElement = document.getElementsByClassName(styles.brand);
    leftNavElement[0].parentNode.classList.remove(styles.modal_zindex);
  }

  setNewsTitle = (title) => {
    this.setState({
      title,
      updateFlag: true
    })
  }

  setNewDiscription = (discription) => {
    this.setState({
      discription,
      updateFlag: true
    })
  }



  componentDidMount() {
    window.addEventListener("scroll", this.handleScrollIndraft);

    const params = {
      arrayFilters: [
        {
          "is_active": 0,
          "is_deleted": 0,
          "created_by": this.props.currentUser.user_id
        }
      ],
      selectFilters: [],
      sort: {
        field: "updated_at",
        sortOrder: "DESC"
      },
      paginate: {
        page: "0",
        limit: "10"
      },
    }
    this.props.initializeInDraftSalesNews(params)

  }


  resetFormData = () => {
    this.setState({
      file: null,
      title: "",
      discription: ""
    })

  }

  previewNews = () => {
    this.setState({
      previewNewsModal: true
    })

  }

  // onChange = e => {
  //   let file = e.target.files[0]

  //   this.getBase64(file, (result) => {
  //     this.setState({
  //       updateFlag: true,
  //       updateImageFlag: true,
  //       file: {
  //         fileBody: result,
  //         fileName: file.name,
  //         fileExtension: file.type,
  //         parentFolder: "sales_news"
  //       }
  //     })
  //   });
  // }

  onChange = (e, valid) => {
    if (valid == true) {
      let file = e.target.files[0];
      e.target.value = '';
      
      // checking file size if more than 2 mb than compress
      if ((file.size / 1024 / 1024) > 2) {
        const options = {
          maxSizeMB: 2,
          maxWidthOrHeight: 1920
        }
        imageCompression(file, options)
        .then((compressedFile) => {
          this.settingImage(compressedFile);
        })
        .catch((error) => {
          this.setState({
            file: null
          });
        });
      } else {
        this.settingImage(file);
      }
    } else {
      this.setState({
        file: null
      });
    }        
  };

  settingImage = (file) => {
    this.getBase64(file, result => {
      this.setState({
          updateFlag: true,
          updateImageFlag: true,
          file: {
            fileBody: result,
            fileName: file.name,
            fileExtension: file.type,
            parentFolder: "sales_news"
          }
        });
    });
  }

  //***************  converting image into base 64

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb(reader.result)
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }





  //  News card  //



  editNews = (data) => {

    var headerElement = document.getElementsByClassName(styles.appBar);
    headerElement[0].classList.add(styles.modal_zindex);

    var leftNavElement = document.getElementsByClassName(styles.brand);
    leftNavElement[0].parentNode.classList.add(styles.modal_zindex);
    const { id, url, user, cover_image, title, news_body } = data
    let fileName = cover_image.split("/")


    this.setState({

      id,
      url,
      user,
      cover_image,
      title,
      discription: news_body,
      file: {
        fileName: fileName.length && fileName[fileName.length - 1],
        fileBody: cover_image
      }
    }, () => {
      this.setState({
        openNewEditor: true
      })
    })

  }
  deleteNewsClick = (id) => {
    
    var headerElement = document.getElementsByClassName(styles.appBar);
    headerElement[0].classList.add(styles.modal_zindex);

    var leftNavElement = document.getElementsByClassName(styles.brand);
    leftNavElement[0].parentNode.classList.add(styles.modal_zindex);
    
    this.setState({
      deleteConfirmationFlag:true,
      id
    })

  }


  ///////////////////////////////////////////////////////
  saveToDrafts = (data) => {
    const editImageparams = {
      "title": this.state.title,
      "news_body": this.state.discription,
      "cover_image_old": this.state.cover_image,
      "attachment": this.state.file,
      "is_active": 0,
      "id": this.state.id
    }

    const editparams = {
      "title": this.state.title,
      "news_body": this.state.discription,
      "is_active": 0,
      "id": this.state.id
    }

    const parameters = {
      "title": this.state.title,
      "news_body": this.state.discription,
      "attachment": this.state.file,
      "is_active": 0
    }

    this.state.updateImageFlag ? this.props.updatePublishNews(editImageparams) : this.props.updatePublishNews(editparams)
    // openSnackbar("News published successfully...!","snack-success")

  }


  updatePublishNews = async (data) => {
    data.id && await this.setState({
      id: data.id,
      title: data.title,
      discription: data.news_body
    })
    const editImageparams = {
      "title": this.state.title,
      "news_body": this.state.discription,
      "cover_image_old": this.state.cover_image,
      "attachment": this.state.file,
      "is_active": 1,
      "id": this.state.id,
      "user_id": this.props.currentUser.user_id
    }
    const editparams = {
      "title": this.state.title,
      "news_body": this.state.discription,
      "is_active": 1,
      "id": this.state.id,
      "user_id": this.props.currentUser.user_id
    }
    const params = {
      "is_active": 1,
      "id": this.state.id,
      "user_id": this.props.currentUser.user_id
    }
    const parameters = {
      "title": this.state.title,
      "news_body": this.state.discription,
      "attachment": this.state.file,
      "is_active": 1,
      "user_id": this.props.currentUser.user_id
    }
    !this.state.updateFlag ? this.props.updatePublishNews(params) : this.state.updateImageFlag ? this.props.updatePublishNews(editImageparams) : this.props.updatePublishNews(editparams);
  
  }

  componentDidUpdate = (prevProps, prevState) => {
    window.addEventListener("scroll", this.handleScrollIndraft);

    if (prevProps.callStatus !== this.props.callStatus) {
      this.setState({
        openNewEditor: false
      })
      this.resetFormData()
      const params = {
        arrayFilters: [
          {
            "is_active": 0,
            "is_deleted": 0,
            "created_by": this.props.currentUser.user_id
          }
        ],
        selectFilters: [],
        sort: {
          field: "updated_at",
          sortOrder: "DESC"
        },
        paginate: {
          page: "0",
          limit: "10"
        },
      }
  
      this.props.initializeInDraftSalesNews(params)
    }
    if(prevProps.inDraftNewsTotalRecords>this.props.inDraftNewsTotalRecords){
      
    }
  }

  handleDeleteClose = () => this.setState({  deleteConfirmationFlag:false  })
  
  
  
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScrollIndraft);
  }

  handleScrollIndraft= () => {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight) {
      this.loadMoreDataSalesNews();
    }
  };


  loadMoreDataSalesNews = () => {
    
    if (
      this.props.inDraftSalesNews &&
      this.props.inDraftNewsTotalRecords > this.props.inDraftSalesNews.length
    ) {
      const requestBody = {
        arrayFilters: [
          {
            is_active: 0,
            is_deleted: 0,
            created_by:this.props.currentUser.user_id
          }
        ],
        selectFilters: [
          "id",
          "title",
          "cover_image",
          "news_body",
          "created_at",
          "updated_at"
        ],
        sort: {
          field: "updated_at",
          sortOrder: "DESC"
        },
        paginate: {
          page: this.state.count.toString(),
          limit: "10"
        }
      };
  
      this.props.loadMoreInDraftSalesNews(requestBody);
      this.setState(preState => {
        return { count: preState.count + 1 };
      });
    }
  };


  handleClick = (id, salesNewsItem) => {
    this.props.history.push({
      pathname: `/sales-news/${id}`,
      state: {
        salesNews: salesNewsItem
      }
    });
  };

  render() {
    const loader = (
      <LargeScreenLoader />
    );

    return (
      <Grid container className="indraft-card-container">
        <SalesNewsDialog
          onCloseNews={this.onCloseNews}
          title={this.state.title}
          discription={this.state.discription}
          onChange={this.onChange}
          style={{ marginTop: "200px" }}
          previewNews={this.previewNews}
          publishNews={this.updatePublishNews}
          resetFormData={this.resetFormData}
          saveToDrafts={this.saveToDrafts}
          openModal={this.state.openNewEditor}
          setNewsTitle={this.setNewsTitle}
          setNewDiscription={this.setNewDiscription}
          file={this.state.file && this.state.file}
          user={this.state.user && this.state.user}
          currentUser={this.props.currentUser}
        /> 
        <Dialog
          open={this.state.deleteConfirmationFlag}
          onClose={this.handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          fullWidth
          aria-describedby="alert-dialog-description"
        >
           <DialogTitle id="simple-dialog-title">Do you really want to delete news</DialogTitle>
          <DialogActions className="action-btn-wrap">
          <Button
          variant="contained"
          onClick={()=>{

            this.handleDeleteClose();
            const params = {
              "id": this.state.id
            }
            this.props.deleteSalesNews(params)
            // openSnackbar("News Deleted successfully...!","snack-success")

          }}
          className="action-btn yes-btn" >
            Yes
          </Button>
          <Button variant="contained" className="action-btn" onClick={this.handleDeleteClose}  autoFocus>
            No
          </Button>
        </DialogActions>
        </Dialog>
        {
          this.props.loadingSalesNews ? loader :
          this.props.inDraftSalesNews ?
          (( this.props.inDraftSalesNews && this.props.inDraftNewsTotalRecords) ? this.props.inDraftSalesNews.map((item, key) => {
            return (
              <Grid  onClick={() => this.handleClick(item.id, item)}  key={key} className="indraft-card-item" item md={6} lg={6} xs={12} sm={12}>

                <NewsCard
                  currentUser={this.props.currentUser}
                  newsobj={item}
                  {...item}
                  id={item.id}
                  userName={item.user.name}
                  userAvatar={item.user.user_avatar}
                  title={item.title}
                  newsTime={item.updated_at}
                  imagePath={item.cover_image}
                  editNews={this.editNews}
                  deleteNews={this.deleteNewsClick}
                  PublishNews={this.updatePublishNews}
                />
              </Grid>
            )
          }) :
          <div className="col-md-12 no-records">
          No sales news found.
      </div>) : loader
        }
      </Grid>
    )
  }
}


export default InDraft
