import React, { Component } from "react";
import "./sales-news.css";
import AsideRight from "../../aside-right/component/aside-right";
import Card from "@material-ui/core/Card";
import Breadcrumbs from "@material-ui/lab/Breadcrumbs";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import LargeScreenLoader from "../../../common/Loaders/PulseLoader/pulse-loader";
import { Grid } from '@material-ui/core'
import NewsCard from "../../../components/NewsCard/NewsCard";
import NoRecordFound from '../../../components/NoRecordFound/NoRecordFound'
const styles = () => ({
  root: {
    flexGrow: 1,
    padding: "20px"
  },
  no_records_card: {
    textAlign: 'center',
    fontSize: '16px',
    padding: '20px'
  }
});

class SalesNews extends Component {
  constructor(props) {
    super(props)

    this.state = {
      count: 1,
      countFeed: 1
    };




  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    const requestBody = {
      arrayFilters: [
        {
          is_active: 1,
          is_deleted: 0
        }
      ],
      selectFilters: ["id", "title", "cover_image", "news_body", "created_at", "updated_at"],
      sort: {
        field: "updated_at",
        sortOrder: "DESC"
      },
      paginate: {
        page: "0",
        limit: "10"
      },
    };
    this.props.initializeSalesNews(requestBody);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleClick = (id, salesNewsItem) => {
    this.props.history.push({
      pathname: `/sales-news/${id}`,
      state: {
        salesNews: salesNewsItem
      }
    });
  };

  handleScroll = () => {

    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    const windowBottom = windowHeight + window.pageYOffset
    if (windowBottom >= docHeight) {
      this.loadMoreDataSalesNews();
    }

  };

  loadMoreDataSalesNews = () => {
    if (
      this.props.salesNews &&
      this.props.totalRecords > this.props.salesNews.length
    ) {
      const requestBody = {
        arrayFilters: [
          {
            is_active: 1,
            is_deleted: 0
          }
        ],
        selectFilters: [
          "id",
          "title",
          "cover_image",
          "news_body",
          "created_at",
          "updated_at"
        ],
        sort: {
          field: "updated_at",
          sortOrder: "DESC"
        },
        paginate: {
          page: this.state.count.toString(),
          limit: "10"
        }
      };

      this.props.loadMoreSalesNews(requestBody);
      this.setState(preState => {
        return { count: preState.count + 1 };
      });
    }
  };

  // handleScrollFeeds = e => {
  //   debugger
  //   const bottom =
  //     e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
  //   if (bottom) {
  //     debugger
  //     this.loadMoreDataSalesFeed();
  //   }
  // };

  loadMoreDataSalesFeed = () => {
    if (
      this.props.salesFeeds &&
      this.props.totalSalesFeed > this.props.salesFeeds.length
    ) {
      const requestBody = {
        arrayFilters: [
          {
            is_deleted: 0
          }
        ],
        selectFilters: ["id", "action_type", "created_at"],
        sort: {
          field: "updated_at",
          sortOrder: "DESC"
        },
        paginate: {
          page: this.state.countFeed.toString(),
          limit: "10"
        },
        // "user_id": this.props.currentUser.user_id
      };
      this.props.loadMoreSalesFeeds(requestBody);
      this.setState(preState => {
        return { countFeed: preState.countFeed + 1 };
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {

    if (prevProps.callStatus !== this.props.callStatus) {
      this.setState({
        openNewEditor: false
      })
      const params = {
        arrayFilters: [
          {
            "is_active": 1,
            "is_deleted": 0,
          }
        ],
        selectFilters: [],
        sort: {
          field: "updated_at",
          sortOrder: "DESC"
        },
        paginate: {
          page: "0",
          limit: "10"
        },
      }
      this.props.initializeSalesNews(params)
    }
  }

  render() {
    const { totalRecords, salesNews, errorLoadingSalesNews, classes } = this.props;
    const loader = (
      <LargeScreenLoader />
    );
    //     const fullContent = salesNews ? (


    // ) : ;
    const errorRenderer = (
      // <NoRecordFound variant="h4" message="No Sales news record to display" />
      <div className="col-md-12 no-records">
        No sales news found.
  </div>
    )
    return (
      this.props.loadingSalesNews ? loader : 
      (this.props.salesNews)
        ? <div>
          <Grid container className="indraft-card-container">

            {(salesNews && totalRecords && salesNews.length) ? salesNews.map((gridItem, index) => (

              <Grid className="indraft-card-item" key={index} item md={6} lg={6} xs={12} sm={12}>
                <div onClick={() => this.handleClick(gridItem.id, gridItem)} className="all-news">
                  <NewsCard
                    user={gridItem.user}
                    newsobj={gridItem}
                    id={gridItem.id}
                    userName={gridItem.user && gridItem.user.name}
                    userAvatar={gridItem.user && gridItem.user.user_avatar}
                    title={gridItem.title}
                    newsTime={gridItem.updated_at}
                    imagePath={gridItem.cover_image}
                    cardAction={false}
                  />
                </div>
              </Grid>

            )) :
              errorRenderer
            }

          </Grid>
          {/* {errorLoadingSalesNews
        ? errorRenderer
        : salesNews
          ? fullContent
          : loader}
      {fullContent} */}
        </div>
        : loader
    );
  }
}

{
  /* export default SalesNews; */
}
export default withStyles(styles)(SalesNews);
