const initialState = {
  addSalesTask: null,
  updateSalesTask: null,
  errorMessage: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_TASK":
      return {
        ...state,
        addSalesTask: null,
        errorMessage: ""
      };
    case "ADD_TASK_SUCCESS":
      return {
        ...state,
        addSalesTask: action.data,
        errorMessage: ""
      };

    case "ADD_TASK_FAILED":
      return {
        ...state,
        addSalesTask: null,
        errorMessage: action.errorMessage
      };

    case "RESET_UPDATE_TASK":
      return {
        ...state,
        updateSalesTask: null,
        errorMessage: ""
      };

    case "UPDATE_TASK_SUCCESS":
      return {
        ...state,
        updateSalesTask: action.data,
        errorMessage: ""
      };

    case "UPDATE_TASK_FAILED":
      return {
        ...state,
        updateSalesTask: null,
        errorMessage: action.errorMessage
      };

    default:
      return state;
  }
};

export default reducer;
