import * as types from "./types";

import axios from "../../../common/api-config";

export const getSalesTasks = requestBody => {
  return dispatch => {
    dispatch(loadSalesTasks());
    axios
      .post("/salesActivity/getActivityTaskList", requestBody)
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch(getSalesTasksSuccess(response.data.data.rows));
        } else {
          dispatch(getSalesTasksFail());
        }
      })
      .catch(error => {
        dispatch(getSalesTasksFail());
      });
  };
};

const loadSalesTasks = () => {
  return {
    type: types.LOAD_SALES_TASKS
  };
}

const getSalesTasksSuccess = data => {
  return {
    type: types.GET_SALES_TASKS_SUCCESS,
    data: data
  };
};

const getSalesTasksFail = () => {
  return {
    type: types.GET_SALES_TASKS_FAIL
  };
};

export default {
    getSalesTasks
};
