import * as types from "./types";
import axios from "../../../common/api-config";

export const getActivityTypes = requestBody => {
  return dispatch => {
    axios
      .post("/master/getMstActivityType", requestBody)
      .then(response => {
        if (response && response.data && response.data.statusCode === 200) {
          dispatch({
            type: types.GET_ALL_SALES_ACTIVITIES_TYPE_SUCCESS,
            data: response.data.data
          });
        } else {
          dispatch({
            type: types.GET_ALL_SALES_ACTIVITIES_TYPE_ERROR,
            data: response.data.errorMessage
          });
        }
      })
      .catch(error => {
        dispatch({
          type: types.GET_ALL_SALES_ACTIVITIES_TYPE_ERROR,
          data: error.errorMessage
        });
      });
  };
};

export const addActivity = requestBody => {
  return dispatch => {
    axios
      .post("/salesActivity/add", requestBody)
      .then(response => {
        if (response && response.data && response.data.statusCode === 200) {
          dispatch({
            type: types.ADD_ACTIVITY_SUCCESS,
            data: response.data.data
          });
        } else {
          dispatch({
            type: types.ADD_ACTIVITY_FAILED,
            errorMessage: response.data.errorMessage
          });
        }
      })
      .catch(error => {
        dispatch({
          type: types.ADD_ACTIVITY_FAILED,
          errorMessage: error.errorMessage
        });
      });
  };
};

export const resetAddActivity = () => {
  return dispatch => {
    dispatch({ type: types.RESET_ADD_ACTIVITY });
  };
};

export const updatingActivity = requestBody => {
  return dispatch => {
    axios
      .put("/salesActivity/update", requestBody)
      .then(response => {
        if (response && response.data && response.data.statusCode === 200) {
          dispatch({
            type: types.UPDATE_ACTIVITY_SUCCESS,
            data: response.data.data
          });
        } else {
          dispatch({
            type: types.UPDATE_ACTIVITY_FAILED,
            errorMessage: response.data.errorMessage
          });
        }
      })
      .catch(error => {
        dispatch({
          type: types.UPDATE_ACTIVITY_FAILED,
          errorMessage: error.errorMessage
        });
      });
  };
};

export const resetUpdateActivity = () => {
  return dispatch => {
    dispatch({ type: types.RESET_UPDATE_ACTIVITY });
  };
};
