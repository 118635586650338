import React, { Component } from "react";

import { connect } from "react-redux";
import * as actions from "./redux/actions";
import { loadMoreSalesFeeds } from "../aside-right/redux/actions";
import SalesNews from "./component/sales-news";
import SalesNewsContainerStyles from './SalesNewsContainer.module.css'
import AsideRight from "../aside-right/component/aside-right";
import Breadcrumbs from "@material-ui/lab/Breadcrumbs";
import { Grid, Button } from "@material-ui/core";
import InDraft from './InDraft/InDraft'
import PublishNews from './PublishNews/PublishNews';
import OopsError from "../../components/ErrorMessages/OopsError/OopsError";
import { getStorageItem } from "../../common/common-functions";




class SalesNewsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
        // currentUser: JSON.parse(localStorage.getItem('user')),
        currentUser: JSON.parse(getStorageItem('user')),
        activeIndex: 0,
        countFeed: 1

    }

}
componentWillUnmount () {
  this.props.resetActiveIndex();
}

  changeTab = (activeIndex) => {
    this.props.initializeActiveTab(activeIndex)
 }

  handleScrollFeeds = e => {

    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
    if (bottom) {
      this.loadMoreDataSalesFeed();
    }
  };

  loadMoreDataSalesFeed = () => {
    // const currentUser = JSON.parse(localStorage.getItem('user'));
    const currentUser = JSON.parse(getStorageItem('user'));

    if (
      this.props.salesFeeds &&
      this.props.totalSalesFeed > this.props.salesFeeds.length
    ) {
      const requestBody = {
        arrayFilters: [
          {
            is_deleted: 0
          }
        ],
        selectFilters: ["id", "action_type", "created_at"],
        sort: {
          field: "created_at",
          sortOrder: "DESC"
        },
        paginate: {
          page: this.state.countFeed.toString(),
          limit: "10"
        },
        "user_id": currentUser.user_id
      };
      this.props.loadMoreSalesFeeds(requestBody);
      this.setState(preState => {
        return { countFeed: preState.countFeed + 1 };
      });
    }
  }

  //manage news



  componentDidUpdate = (prevProps, prevState) => {

    // if (prevProps.totalPublishedNews !== this.props.totalPublishedNews ||
    //   prevProps.inDraftNewsTotalRecords !== this.props.inDraftNewsTotalRecords
    //    || prevProps.totalRecords !== this.props.totalRecords ) {
    //     const params = {
    //       arrayFilters: [
    //         {
    //           "is_active": 0,
    //           "is_deleted": 0,
    //           "created_by": this.state.currentUser.user_id
    //         }
    //       ],
    //       selectFilters: [],
    //       sort: {
    //         field: "updated_at",
    //         sortOrder: "DESC"
    //       },
    //       paginate: {
    //         page: "0",
    //         limit: "10"
    //       },
    //     }


    //     const requestsales = {
    //       arrayFilters: [
    //         {
    //           is_active: 1,
    //           is_deleted: 0
    //         }
    //       ],
    //       selectFilters: ["id", "title", "cover_image", "news_body", "created_at", "updated_at"],
    //       sort: {
    //         field: "updated_at",
    //         sortOrder: "DESC"
    //       },
    //       paginate: {
    //         page: "0",
    //         limit: "10"
    //       },
    //     };
    //     this.props.initializeSalesNews(requestsales);


    //     this.props.initializeInDraftSalesNews(params)

    //     const requestBody = {
    //       arrayFilters: [
    //         {
    //           is_active: 1,
    //           is_deleted: 0,
    //           "created_by": this.state.currentUser.user_id
    //         }
    //       ],
    //       selectFilters: ["id", "title", "cover_image", "news_body", "created_at", "updated_at"],
    //       sort: {
    //         field: "updated_at",
    //         sortOrder: "DESC"
    //       },
    //       paginate: {
    //         page: "0",
    //         limit: "10"
    //       },
    //     };
    //     this.props.initializePublishNews(requestBody);

    //    }
  }

  componentDidMount(){

    // const params = {
    //   arrayFilters: [
    //     {
    //       "is_active": 0,
    //       "is_deleted": 0,
    //       "created_by": this.state.currentUser.user_id
    //     }
    //   ],
    //   selectFilters: [],
    //   sort: {
    //     field: "updated_at",
    //     sortOrder: "DESC"
    //   },
    //   paginate: {
    //     page: "0",
    //     limit: "10"
    //   },
    // }
    // this.props.initializeInDraftSalesNews(params)

    // const requestBody = {
    //   arrayFilters: [
    //     {
    //       is_active: 1,
    //       is_deleted: 0,
    //       "created_by": this.state.currentUser.user_id
    //     }
    //   ],
    //   selectFilters: ["id", "title", "cover_image", "news_body", "created_at", "updated_at"],
    //   sort: {
    //     field: "updated_at",
    //     sortOrder: "DESC"
    //   },
    //   paginate: {
    //     page: "0",
    //     limit: "10"
    //   },
    // };
    // this.props.initializePublishNews(requestBody);
  }

  

  render() {
    const salesNewsProps = {
      ...this.props
    };
    // const currentUser = JSON.parse(localStorage.getItem('user'));
    const currentUser = JSON.parse(getStorageItem('user'));
    return (
      <>
      {
        this.props.errorLoadingSalesNews ?
        <div>
          <OopsError />
        </div>
        :
        <div>
        <Grid container>
          <Grid container className={SalesNewsContainerStyles.salesContanerGrid} item md={8} lg={8} xs={12} sm={12}>
            
            <Grid className={SalesNewsContainerStyles.salesNewsHeading} item md={12} lg={12} xs={12} sm={12}>
              <h2 className={SalesNewsContainerStyles.salesNewsTitile}>SALES NEWS</h2>
            </Grid>
            <Grid container direction="row" className={SalesNewsContainerStyles.breadcrumbsAlign} item md={12} lg={12} xs={12} sm={12}>
              <Grid className={SalesNewsContainerStyles.breadcrumbsWrap+" "} item md={5} lg={5} xs={12} sm={4}>
                <Breadcrumbs className={SalesNewsContainerStyles.breadcrumbsAlignItem} separator="›" arial-label="Breadcrumb">
                  <span>Home</span>
                  <span>Sales news</span>
                </Breadcrumbs>
              </Grid> 


              {currentUser && currentUser.license_type.actual_name === "MANAGER" && <Grid container justify="flex-end" alignItems="flex-end" spacing={8} className={SalesNewsContainerStyles.containerBtn} item md={7} lg={7} xs={12} sm={8} >
              <b className={this.props.activeIndex === 0 ? `${SalesNewsContainerStyles.btnText} ${SalesNewsContainerStyles.activeBtn}` : `${SalesNewsContainerStyles.btnText}`} onClick={() => this.changeTab(0)}>All{" ( "+this.props.totalRecords+" )"}</b>

                    <span className={SalesNewsContainerStyles.separator}>|</span>
                    
                    <b className={this.props.activeIndex === 1 ? `${SalesNewsContainerStyles.btnText} ${SalesNewsContainerStyles.activeBtn}` : `${SalesNewsContainerStyles.btnText}`} onClick={() => this.changeTab(1)}>Published{" ( "+this.props.totalPublishedNews+" )"}</b>

                    <span className={SalesNewsContainerStyles.separator}>|</span>
                    <b className={this.props.activeIndex === 2 ?  `${SalesNewsContainerStyles.btnText} ${SalesNewsContainerStyles.activeBtn}` : `${SalesNewsContainerStyles.btnText}`} onClick={() => this.changeTab(2)}>Draft{" ( "+this.props.inDraftNewsTotalRecords+" )"}</b>
                </Grid>}


            </Grid>
                  
                  <Grid className={SalesNewsContainerStyles.salesNewsMobile}   item md={12} lg={12} xs={12} sm={12}>
                    
                    {
                        this.props.activeIndex === 0 && <SalesNews {...salesNewsProps} currentUser={currentUser} />
                    }
                    {
                        this.props.activeIndex === 1 && <PublishNews  {...salesNewsProps} currentUser={this.state.currentUser} />
                    }
                    {
                        this.props.activeIndex === 2 && <InDraft {...salesNewsProps} currentUser={this.state.currentUser} />
                    }
                  </Grid>
              
            </Grid>
               


          <Grid className={SalesNewsContainerStyles.rightContentWrapper} onScroll={this.handleScrollFeeds} item md={4} lg={4} xs={12} sm={12}>
            
              <div className={SalesNewsContainerStyles.rightContentInner}>
                <AsideRight {...this.props} currentUser={currentUser}/>
              </div>

          </Grid>
        </Grid>


      </div>
      }
      </>
      );
  }
}

const mapStateToProps = state => {
  return {
    salesNews: state.salesNews.salesNews,
    loadingSalesNews: state.salesNews.loadingSalesNews,
    errorLoadingSalesNews: state.salesNews.errorLoadingSalesNews,
    totalRecords: state.salesNews.totalRecords,
    totalSalesFeed: state.salesFeeds.totalSalesFeed,
    salesFeeds: state.salesFeeds.salesFeeds,
    callStatus: state.salesNews.callStatus,
    snackbarStatus: state.salesNews.snackbarStatus,
    inDraftSalesNews: state.salesNews.inDraftSalesNews,
    inDraftNewsTotalRecords: state.salesNews.inDraftNewsTotalRecords,
    callFlag: state.salesNews.callFlag,
    totalPublishedNews:state.salesNews.totalPublishedNews,
    publishSalesNewsdata:state.salesNews.publishSalesNewsdata,
    activeIndex:state.salesNews.activeIndex
  };
};


const mapDispatchToProps = dispatch => {
  return {
    initializeInDraftSalesNews : requestBody => dispatch(actions.initializeInDraftSalesNews(requestBody)),
    deleteSalesNews: requestBody => dispatch(actions.deleteSalesNews(requestBody)),
    initializeSalesNews: requestBody =>
      dispatch(actions.initializeSalesNews(requestBody)),
    loadMoreSalesNews: requestBody =>
      dispatch(actions.loadMoreSalesNews(requestBody)),
    loadMoreSalesFeeds: requestBody =>
      dispatch(loadMoreSalesFeeds(requestBody)),
      updatePublishNews: requestBody => dispatch(actions.updatePublishNews(requestBody)),
      publishSalesNews: requestBody => dispatch(actions.publishSalesNews(requestBody)),
      loadMoreInDraftSalesNews: requestBody => dispatch(actions.loadMoreInDraftSalesNews(requestBody)),
      initializePublishNews: requestBody => dispatch(actions.initializePublishNews(requestBody)),
      loadMorePublishedNews:requestBody => dispatch(actions.loadMorePublishedNews(requestBody)),
      initializeActiveTab:requestBody=>dispatch(actions.initializeActiveTab(requestBody)),
    resetActiveIndex: () => dispatch(actions.resetActiveIndex())
  };
};



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesNewsContainer);
