import React, { Component } from "react";
import styles from "./ActivitiesCheckbox.module.scss";
import { connect } from "react-redux";
import {
  updateTasks,
  getSalesActivititesTasks
} from "../../../pages/Activities/redux/actions";
import { getSalesTasks } from "../../../pages/Tasks/redux/actions";
import { myPipelineGetPlannedActivities } from "../../../pages/LeadDetailPopup/redux/actions";
import axios from "../../api-config";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";

class ActivitiesCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_complete: props.task.is_completed
    };
  }

  handleCheckBoxClick = async e => {
    this.setState({ is_complete: e.target.checked });
    const updateReq = {
      id: this.props.task.id,
      is_completed: e.target.checked
      //user_id: JSON.parse(localStorage.getItem("user")).user_id
    };

    axios.put("/task/update", updateReq).then(response => {
      if (response.data.statusCode === 200) {
        if (this.props.from === "activities") {
          const requestBodySalesActivitiesTasks = {
            arrayFilters: [
              {
                dateFrom: this.props.startDate,
                dateTo: this.props.endDate
              }
            ],
            selectFilters: [],
            sort: {
              field: "activity_time",
              sortOrder: "ASC"
            },
            paginate: {
              page: 0,
              limit: 1000
            },
            type: "BOTH"
            //user_id: JSON.parse(localStorage.getItem("user")).user_id
          };

          this.props.getSalesActivititesTasks(requestBodySalesActivitiesTasks);

          const requestBody = {
            arrayFilters: [
              {
                dateFrom: this.props.startDate,
                dateTo: this.props.endDate
              }
            ],
            selectFilters: [],
            sort: {
              field: "activity_time",
              sortOrder: "ASC"
            },
            paginate: {
              page: 0,
              limit: 1000
            },
            type: "TASK"
            //user_id: JSON.parse(localStorage.getItem("user")).user_id
          };

          this.props.getSalesTasks(requestBody);
        } else if (this.props.from === "my-pipeline") {
          const requestBodySalesActivitiesTasks = {
            arrayFilters: [
              {
                dateFrom: moment().format("YYYY-MM-DD"),
                dateTo: null,
                lead_id: this.props.lead_id
              }
            ],
            selectFilters: [],
            sort: {
              field: "activity_time",
              sortOrder: "ASC"
            },
            paginate: {
              page: 0,
              limit: 1000
            },
            type: "BOTH"
            //user_id: JSON.parse(localStorage.getItem("user")).user_id
          };
          this.props.myPipelineGetPlannedActivities(
            requestBodySalesActivitiesTasks
          );
        }
      }
    });
  };

  render() {
    return (
      <div className={styles.checkboxContainer}>
        <label className={styles.container}>
          <span className={(this.state.is_complete ? styles.labelText : null) +" "+ styles.wordBreakClass}>
            {this.props.task.description}
          </span>
          <input
            type="checkbox"
            checked={this.state.is_complete}
            onChange={this.handleCheckBoxClick}
          />
          <span className={styles.checkmark} />
        </label>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateTasks: requestBody => dispatch(updateTasks(requestBody)),
    getSalesActivititesTasks: request =>
      dispatch(getSalesActivititesTasks(request)),
    getSalesTasks: params => dispatch(getSalesTasks(params)),
    myPipelineGetPlannedActivities: requestBody =>
      dispatch(myPipelineGetPlannedActivities(requestBody))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ActivitiesCheckbox);
