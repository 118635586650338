import React from "react";
import leadXLogo from "../../../../assets/logo-with-slogan.png";
import styles from "./ResetPasswordComponent.module.scss";
import { Button, Input, FormGroup } from "@material-ui/core";
import LargeScreenLoader from "../../../common/Loaders/PulseLoader/pulse-loader";
import { Link } from "react-router-dom";

const resetPasswordComponent = props => {
  return (
    <div className={styles.login_page}>
      {props.isLoading ? <LargeScreenLoader /> : ""}
      <div className={styles.login}>
        <div className={styles.logo_container}>
          <img className={styles.logo} src={leadXLogo} alt="Lead X logo" />
        </div>
        <div className={styles.welcome}>
          <h2 className={styles.heading}>Reset your password</h2>
          {/* <h5>You have requested to reset new password for: {props.email}</h5> */}
        </div>
        <form className={styles.login_form} onSubmit={props.handleSubmit}>
          <FormGroup>
            <div className={styles.input_container}>
              <Input
                inputProps={{
                  autoFocus: true,
                  maxLength: 20
                }}
                className={props.classes.input + " " + styles.input}
                type="password"
                value={props.password.value}
                name="password"
                placeholder="Enter new password"
                onChange={props.handleInputChanged}
                onBlur={props.handleOnBlur}
                autoComplete="off"
              />
              {!props.password.valid ? (
                <p>
                  <span className={styles.errors}>
                    {props.password.errorMessage}
                  </span>
                </p>
              ) : null}
            </div>
            <div className={styles.input_container}>
              <Input
                inputProps={{
                  maxLength: 20
                }}
                className={props.classes.input + " " + styles.input}
                type="password"
                value={props.confirmPassword.value}
                name="confirmPassword"
                placeholder="Confirm new password"
                onChange={props.handleInputChanged}
                onBlur={props.handleOnBlur}
                autoComplete="off"
              />
              {!props.confirmPassword.valid ? (
                <p>
                  <span className={styles.errors}>
                    {props.confirmPassword.errorMessage &&
                      "'Confirm new password' should be the same as 'new password'."}
                  </span>
                </p>
              ) : null}
            </div>
            <div>
              <p className={styles.hint}>
                Hint: The new password should be at least 8 characters long and
                should contain at least one upper case letter, one number, one
                special character and no spaces.
              </p>
            </div>
            <div className={styles.login_button_container}>
              <Button
                className={styles.button}
                type="submit"
                disabled={!props.validity}
              >
                Reset password
              </Button>
            </div>
          </FormGroup>
        </form>
        <div className={styles.forgot_password}>
          <h5>
            Back to <Link to="/login">login</Link>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default resetPasswordComponent;
