import React, { Component } from "react";
import { Card } from "@material-ui/core";
import Breadcrumbs from "@material-ui/lab/Breadcrumbs";
import { Link } from "react-router-dom";
import styles from "./CompleteSalesNewsComponent.module.scss";
import moment from "moment";
import { imageType } from '../../../common/common-types'
class CompleteSalesNewsComponent extends Component {
  render() {
    const { salesNews } = this.props.history.location.state;
    if(!salesNews){
      this.props.history.push("/sales-news");
    }
    return (
      <div className="main-wrapper">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumbs">
              <h2 className="heading">SALES NEWS</h2>
              <div className="breadcrumbs_container">
                <Breadcrumbs separator="›" arial-label="Breadcrumb">
                  <Link to="/sales-news">Home</Link>
                  <Link to="/sales-news">Sales news</Link>
                  <span>{salesNews.title && salesNews.title}</span>
                </Breadcrumbs>
              </div>
            </div>
            <div className="row container-body btm-space">
              <div className="col-12">
                <Card className={styles.sales_news}>
                  <div className={styles.sales_news_header}>
                    <div className={styles.sales_news_inner_header}>
                      <div className={styles.sales_names_icon_container}>
                      { 
                        salesNews.user && salesNews.user.user_avatar ? 
                        imageType.test(salesNews.user.user_avatar) ?
                          <img src={
                            salesNews.user.user_avatar.slice(0, salesNews.user.user_avatar.lastIndexOf('/')) + "/small_" +
                            salesNews.user.user_avatar.substring(salesNews.user.user_avatar.lastIndexOf("/") + 1)
                          } /> :
                          <img src={require("../../../../assets/userProfile.jpeg")} />
                          : <img src={require("../../../../assets/userProfile.jpeg")} />
                        }
                      </div>
                      <div className={styles.sales_names_user_name}>
                        {salesNews.user && salesNews.user.name}
                      </div>
                    </div>
                    <div className="news-time">
                      {moment(salesNews.updated_at).fromNow()}
                    </div>
                  </div>
                  <div className={styles.sales_news_image_container}>
                    <img
                      className={styles.imgSizeWrapper}
                      src={
                        salesNews.cover_image.slice(0, salesNews.cover_image.lastIndexOf('/')) + "/large_" +
                        salesNews.cover_image.substring(salesNews.cover_image.lastIndexOf("/") + 1)
                      }
                    />
                  </div>
                  <div className={styles.sales_news_content}>
                    <h5 className={styles.heading}>{salesNews.title}</h5>
                    <p className={styles.content}>{salesNews.news_body}</p>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompleteSalesNewsComponent;
