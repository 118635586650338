import * as types from "./types";

const initialState = {
  callStatus: false,
  salesNews: null,
  errorLoadingSalesNews: false,
  totalRecords: 0,
  inDraftSalesNews: [],
  snackbarStatus: "",
  callFlag: null,
  publishSalesNewsdata: null,
  totalPublishedNews: 0,
  inDraftNewsTotalRecords: 0,
  activeIndex: 0,
  loadingSalesNews: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INITIALIZE_SALES_NEWS_SUCCESS:
      return {
        ...state,
        errorLoadingSalesNews: false,
        salesNews: action.data,
        totalRecords: action.totalRecords,
        totalPublishedNews: action.totalPublishedNews,
        inDraftNewsTotalRecords: action.inDraftNewsTotalRecords
      };

    case types.INITIALIZE_SALES_NEWS_FAIL:
      return {
        ...state,
        errorLoadingSalesNews: true,
        salesNews: null
      };

    case types.LOAD_MORE_SALES_NEWS_SUCCESS:
      return {
        ...state,
        errorLoadingSalesNews: false,
        salesNews: [...state.salesNews, ...action.data]
      };

    case types.SALES_NEWS_LOADING:
      return {
        ...state,
        callStatus: false,
        snackbarStatus: action.snackbarStatus,
        loadingSalesNews: true
      };

    case types.SALES_NEWS_SUCCESS:
      return {
        ...state,
        callStatus: true,
        callFlag: action.callFlag,
        snackbarStatus: action.snackbarStatus,
        loadingSalesNews: false
      };
    case types.SALES_NEWS_ERROR:
      return {
        ...state,
        errorUpdatingSalesNews: true,
        callFlag: action.callFlag,
        callStatus: true,
        snackbarStatus: action.snackbarStatus,
        loadingSalesNews: false
      };

    case types.INITIALIZE_PUBLISH_NEWS_SUCCESS:
      return {
        ...state,
        errorLoadingSalesNews: false,
        publishSalesNewsdata: action.data,
        totalRecords: action.totalRecords,
        totalPublishedNews: action.totalPublishedNews,
        inDraftNewsTotalRecords: action.inDraftNewsTotalRecords,
        loadingSalesNews: false
      };

    case types.INITIALIZE_IN_DRAFT_SALES_NEWS_SUCCESS:
      return {
        ...state,
        inDraftSalesNews: action.data,
        totalRecords: action.totalRecords,
        totalPublishedNews: action.totalPublishedNews,
        inDraftNewsTotalRecords: action.inDraftNewsTotalRecords,
        loadingSalesNews: false
      };

    case types.LOAD_MORE_IN_DRAFT_SALES_NEWS_SUCCESS:
      return {
        ...state,
        inDraftSalesNews: [...state.inDraftSalesNews, ...action.data],
        inDraftNewsTotalRecords: action.totalRecords
      };

    case types.INITIALIZE_IN_DRAFT_SALES_NEWS_FAIL:
      return {
        ...state,
        errorLoadingSalesNews: true,
        inDraftNewsTotalRecords: 0,
        inDraftSalesNews: [],
        loadingSalesNews: false
      };

    case types.LOAD_MORE_PUBLISHED_NEWS_SUCCESS:
      return {
        ...state,
        errorLoadingSalesNews: false,
        publishSalesNewsdata: [...state.publishSalesNewsdata, ...action.data]
      };
    case types.INITIALIZE_ACTIVE_TAB:
      return {
        ...state,
        activeIndex: action.activeIndex,
        loadingSalesNews: false
      };

    case types.INITIALIZE_PUBLISHED_NEWS_FAIL:
      return {
        ...state,
        errorLoadingSalesNews: true,
        publishSalesNewsdata: [],
        totalPublishedNews: 0,
        loadingSalesNews: false
      };

    case types.RESET_ACTIVE_INDEX:
      return {
        ...state,
        activeIndex: 0
      };

    default:
      return state;
  }
};

export default reducer;
