import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import SalesCommunity from './component/sales-community';
import leadDetailActions from '../LeadDetailPopup/redux/actions';
import OopsError from "../../components/ErrorMessages/OopsError/OopsError";
import { getStorageItem } from "../../common/common-functions";

class SalesCommunityContainer extends Component {
    render () {
        const salesCommunityProps = {...this.props};
        // const currentUser = JSON.parse(localStorage.getItem('user'));
        const currentUser = JSON.parse(getStorageItem('user'));
        return (
            <>
                {
                    this.props.errorLoadingCommunityLeads || this.props.errorLoadingCommunityRevenue ?
                    <div>
                        <OopsError />
                    </div>
                    :
                    <SalesCommunity {...salesCommunityProps} currentUser={currentUser} />
                }
            </>
            // leads={this.props.leads} leadsCount={this.props.leadsCount} 
            // revenueData={this.props.revenueData} />
        );
    }
}

const mapStateToProps = state => {
    return {
        communityLeads: state.salesCommunity.communityLeads,
        communityLeadsCount: state.salesCommunity.communityLeadsCount, 
        revenueData: state.salesCommunity.revenueData,
        errorLoadingCommunityLeads: state.salesCommunity.errorLoadingCommunityLeads,
        errorLoadingCommunityRevenue: state.salesCommunity.errorLoadingCommunityRevenue,
        statusLogs: state.leadDetailReducer.statusLogs,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCommunityLeadList: requestBody => dispatch(actions.getCommunityLeadList(requestBody)),
        getRevenue: requestBody => dispatch(actions.getRevenue(requestBody)),
        getStatusLogs: requestBody => dispatch(leadDetailActions.getStatusLogs(requestBody)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesCommunityContainer);