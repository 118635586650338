import React from "react";
import "./sales-community.scss";
import Divider from "@material-ui/core/Divider";
import LinearProgress from "@material-ui/core/LinearProgress";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import Breadcrumbs from "@material-ui/lab/Breadcrumbs";
import moment from "moment";
import { Link } from "react-router-dom";
import * as commonFunctions from "../../../common/common-functions";
import handshakeIconGrey from "../../../../assets/images/content-images/beforeHandshake.png";
import handshakeIconOne from "../../../../assets/images/content-images/afterHandshake.png";
import handshakeIconTwo from "../../../../assets/images/content-images/handshakeIconTwo.png";
import Dialog from "@material-ui/core/Dialog";
import LargeScreenLoader from "../../../common/Loaders/PulseLoader/pulse-loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Stepper from "../../../common/Stepper/stepper";
import Filter from "../../../components/Filter/filter";
import LeadDialog from "../../../components/LeadDialog/LeadDialog";
import asideLeftStyles from "../../aside-left/component/aside-left.module.scss";

class SalesCommunity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageIndex: "0",
      pageLimit: "10",
      totalPages: 0,
      isLoading: false,
      openMobilePopup: false,
      filterValue: [2, 3, 4, 5, 6], //all lead status
      showDialog: false,
      lead: null
      //hideFilter: false,
      //checkFilterFirstTime: false
    };
  }

  componentDidMount() {
    this.getLeadListByFilter({});

    let params = {
      revenueType: "sales_community"
    };
    this.props.getRevenue(params);
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.state.checkFilterFirstTime === false && (prevProps.communityLeads === null || prevProps.communityLeads.length === 0) && this.props.communityLeads.length === 0) {
    //     this.setState({
    //         hideFilter: true,
    //         checkFilterFirstTime: true
    //     });
    // }
  }

  getMoreLeads = async () => {
    let maxPage = Math.ceil(
      parseInt(this.props.communityLeadsCount) / parseInt(this.state.pageLimit)
    );
    if (parseInt(this.state.pageIndex) < maxPage - 1) {
      let pageIndex = parseInt(this.state.pageIndex);
      await this.setState({
        pageIndex: ++pageIndex + ""
      });
      if (this.state.filterValue && this.state.filterValue.length > 0) {
        this.getLeadListByFilter({
          lead_current_status_id: this.state.filterValue
        });
      } else {
        this.getLeadListByFilter({});
      }
    }
  };

  addCustomClass = async item => {
    await this.setState({
      showDialog: true,
      lead: item
    });
    var headerElement = document.getElementsByClassName(asideLeftStyles.appBar);
    headerElement[0].classList.add(asideLeftStyles.modal_zindex);
    var leftNavElement = document.getElementsByClassName(asideLeftStyles.brand);
    leftNavElement[0].parentNode.classList.add(asideLeftStyles.modal_zindex);
    let params = {
      lead_id: item.id
    };
    this.props.getStatusLogs(params);
  };

  hideDialog = () => {
    this.setState({
      showDialog: false,
      lead: null
    });
  };

  getLeadListByFilter(arrayFilters, pageIndex) {
    if (arrayFilters && arrayFilters.lead_current_status_id) {
      this.setState({
        filterValue: arrayFilters.lead_current_status_id
      });
    }

    if (pageIndex) {
      this.setState({
        pageIndex: pageIndex + ""
      });
    }
    let params = {
      from: "sales_community",
      arrayFilters: [arrayFilters],
      selectFilters: [
        "id",
        "lead_title",
        "expected_closing_date",
        "lead_value",
        "is_confidential",
        "notes",
        "is_hand_over",
        "is_won",
        "is_bell_ringed",
        "project_location",
        "created_at"
      ],
      sort: {
        field: "updated_at",
        sortOrder: "DESC"
      },
      paginate: {
        page: this.state.pageIndex,
        limit: this.state.pageLimit
      }
    };
    this.props.getCommunityLeadList(params);
  }

  render() {
    const { lead } = this.state;
    const maxPage = Math.ceil(
      this.props.communityLeadsCount / parseInt(this.state.pageLimit)
    );

    return this.props.communityLeads && this.props.revenueData ? (
      <div className="main-wrapper">
        <div className="breadcrumbs">
          <h3 className="heading">SALES COMMUNITY</h3>
          <div className="breadcrumbs_container">
            <Breadcrumbs separator="›" arial-label="Breadcrumb">
              <Link to="/sales-news">Home</Link>
              <span>Sales community</span>
            </Breadcrumbs>
          </div>
        </div>
        <div className="container-body">
          <div className="card shadow bg-white card-rounded">
            <div className="card-title community-header">
              <img
                className="logo"
                src={
                  this.props.revenueData && this.props.revenueData.account.logo
                    ? this.props.revenueData.account.logo
                    : require("../../../../assets/companyImage.png")
                }
                height="70"
                alt="logo"
              />
              <div className="title">
                {this.props.revenueData && this.props.revenueData.account
                  ? this.props.revenueData.account.name
                  : ""}
              </div>
            </div>
            <Divider />
            <div className="card-body card-image">
              <div className="row header-content">
                <div className="col-md-4">
                  <div className="content-values">
                    {this.props.revenueData && this.props.revenueData.revenue !== 0
                      ? commonFunctions.getConvertedCurrency(
                          this.props.revenueData.revenue
                        )
                      : 0}
                  </div>
                  <div className="sales-content-text">
                    Revenue,{" "}
                    {this.props.revenueData && this.props.revenueData.account
                      ? this.props.revenueData.account.currency.short_name
                      : ""}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="content-values">
                    {this.props.revenueData
                      ? this.props.revenueData.leadsTotal
                      : 0}
                  </div>
                  <p className="sales-content-text">Leads total</p>
                </div>
                <div className="col-md-4">
                  <div className="content-values">
                    {this.props.revenueData
                      ? this.props.revenueData.hitRate
                      : 0}
                  </div>
                  <p className="sales-content-text">Hit rate, %</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card shadow mb-space bg-white card-rounded ">
            <div className="card-title sales-header mb-0">
              <span className="sales-title">Ongoing sales</span>
            </div>
            <div className="card-body sales_community_cardBody pt-0 pb-0">
              <div className="row sales-item-row">
                {
                  //!this.state.hideFilter &&
                  <Filter
                    getLeadListByFilter={(params, pageIndex) =>
                      this.getLeadListByFilter(params, pageIndex)
                    }
                    filterKey="sales_community"
                  />
                }
                {this.props.communityLeads &&
                this.props.communityLeads.length > 0 ? (
                  this.props.communityLeads.map((item, index) => {
                    return (
                      <div
                        className="col-md-12 sales-item padding_left padding_right"
                        key={index}
                        id={index}
                        onClick={() => this.addCustomClass(item)}
                      >
                        <div
                          id={"sales_item_" + index}
                          className="corrected_height corrected_height_left_right"
                        >
                          <div className="item display-inline">
                            <img
                              className="item-img display-inline item-img-display-inline-top"
                              src={
                                item.assignedTo && item.assignedTo.user_avatar
                                  ? item.assignedTo.user_avatar.slice(
                                      0,
                                      item.assignedTo.user_avatar.lastIndexOf(
                                        "/"
                                      )
                                    ) +
                                    "/small_" +
                                    item.assignedTo.user_avatar.substring(
                                      item.assignedTo.user_avatar.lastIndexOf(
                                        "/"
                                      ) + 1
                                    )
                                  : require("../../../../assets/userProfile.jpeg")
                              }
                              alt="profile"
                              width="40"
                              height="40"
                            />
                            <div className="item-desc display-inline">
                              <div className="item-title">
                                {item.lead_title}
                              </div>
                              <div className="item-sub-title">
                                {item.contact_company
                                  ? item.contact_company.company_name
                                  : ""}
                              </div>
                            </div>
                            <div className="item-desc display-inline float-right">
                              <div>
                                <KeyboardArrowRight className="item-icon" />
                              </div>
                              <div className="item-status item-status-width">
                                {item.lead_status &&
                                item.lead_status.name &&
                                item.lead_status.id !== 6
                                  ? " " + item.lead_status.name
                                  : item.lead_status &&
                                    item.lead_status.name &&
                                    item.lead_status.id === 6
                                  ? " " +
                                    item.lead_status.name +
                                    (item.is_won ? " (Won)" : " (Lost)")
                                  : ""}
                              </div>
                            </div>
                          </div>
                          <div className="handoverIcon">
                            {item.createdBy &&
                            item.createdBy.license_type &&
                            item.createdBy.license_type.actual_name ==
                              "BASIC" ? (
                              item.is_hand_over === 0 ? (
                                <img src={handshakeIconGrey} width="33" />
                              ) : item.is_hand_over &&
                                item.is_hand_over === 1 ? (
                                <img src={handshakeIconOne} width="33" />
                              ) : item.is_hand_over &&
                                item.is_hand_over === 2 ? (
                                <img src={handshakeIconTwo} width="33" />
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="item-progress">
                            {item &&
                            item.lead_status &&
                            item.lead_status.name ? (
                              item.lead_status.name === "New" ? (
                                <LinearProgress
                                  className="newLinearProgress"
                                  variant="determinate"
                                  value={16}
                                />
                              ) : item.lead_status.name === "Lead" ? (
                                <LinearProgress
                                  className="leadLinearProgress"
                                  variant="determinate"
                                  value={32}
                                />
                              ) : item.lead_status.name === "Opportunity" ? (
                                <LinearProgress
                                  className="opportunityLinearProgress"
                                  variant="determinate"
                                  value={48}
                                />
                              ) : item.lead_status.name === "Proposal" ? (
                                <LinearProgress
                                  className="proposalLinearProgress"
                                  variant="determinate"
                                  value={64}
                                />
                              ) : item.lead_status.name === "Negotiation" ? (
                                <LinearProgress
                                  className="negotiationLinearProgress"
                                  variant="determinate"
                                  value={80}
                                />
                              ) : item.lead_status.name === "Closed" ? (
                                <LinearProgress
                                  className="closedLinearProgress"
                                  variant="determinate"
                                  value={item.is_won ? 100 : 0}
                                />
                              ) : (
                                <LinearProgress
                                  variant="determinate"
                                  value={5}
                                />
                              )
                            ) : (
                              <LinearProgress variant="determinate" value={5} />
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : this.state.filterValue.length === 5 ? (
                  // this.props.communityLeads.length === 0 ?
                  <div className="col-md-12 no-records">
                    Be the first one to add a new lead by clicking the 'Create
                    new lead' button on the top header.
                  </div>
                ) : (
                  <div className="col-md-12 no-records">No records found.</div>
                )}
                {this.props.communityLeadsCount &&
                maxPage - 1 > parseInt(this.state.pageIndex) ? (
                  <div className="col-md-12 sales-view-all">
                    <div
                      className="view-all"
                      onClick={() => this.getMoreLeads()}
                    >
                      View more
                      <span>
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          className="view-all-icon"
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        {/* lead detail popup */}
        <div>
          <LeadDialog
            lead={this.state.lead}
            showDialog={this.state.showDialog}
            statusLogs={this.props.statusLogs}
            hideDialog={this.hideDialog}
          />
        </div>
      </div>
    ) : (
      <LargeScreenLoader />
    );
  }
}

export default SalesCommunity;
