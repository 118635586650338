import React, { Component } from "react";
import styles from "./AddTasksComponent.module.scss";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import AutoComplete from "../../../common/AutoComplete/autocomplete";
import Button from "@material-ui/core/Button";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import DatePicker from "react-date-picker";
import moment from "moment";
import axiosInstance from "../../../common/api-config";
import CircularProgress from "../../../common/Loaders/CircularLoader/circular-loader";

const DialogTitle = withStyles(theme => ({
  root: {
    width: "100%",
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    width: "100%",
    margin: 0,
    // padding: "30px"
    padding: "24px"
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    width: "100%",
    borderTop: `0`,
    margin: 0
  }
}))(MuiDialogActions);

class AddTasksComponent extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
  }
  state = {
    description: "",
    leadId: null,
    deadline: moment().format("YYYY-MM-DD"),
    errors: { descriptionError: "",lead_title : "" },
    leadListing: [],
    submitButton: false
  };

  componentDidMount() {
    this.textInput.focus();
    this.getLeadList();
    if (this.props.updateTask) {
      const { updateTask } = this.props;
      this.setState({
        description: updateTask.description ? updateTask.description : "",
        leadId: updateTask.lead_id ? updateTask.lead_id : null,
        deadline: updateTask.deadline
          ? moment(updateTask.deadline).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD")
      });
    }
  }

  getLeadList = () => {
    const reqLeadList = {
      arrayFilters: [{}],
      selectFilters: ["id", "lead_title"],
      sort: {
        field: "lead_title",
        sortOrder: "ASC"
      }
      //user_id: JSON.parse(localStorage.getItem("user")).user_id
    };
    axiosInstance
      .post("/lead/getLeadList", reqLeadList)
      .then(response => {
        if (response && response.data && response.data.statusCode === 200) {
          this.setState({ leadListing: response.data.data.rows });
        } else {
          this.setState({ leadListing: [] });
        }
      })
      .catch(error => {
        if (!error.status) {
          openSnackbar("Oops, something went wrong!", "snack-error");
          // network error
          this.props.close();
        }
        this.setState({ leadListing: [] });
      });
  };

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.errorMessage !== "") {
      nextProps.resetTask();
      nextProps.resetUpdateTask();
      openSnackbar(nextProps.errorMessage, "snack-error");
      nextProps.close("");
    } else if (nextProps && nextProps.addSalesTask) {
      nextProps.resetTask();
      openSnackbar("Task added successfully.", "snack-success");
      nextProps.close("after_save_task");
    } else if (nextProps && nextProps.updateSalesTask) {
      nextProps.resetUpdateTask();
      openSnackbar("Task updated successfully.", "snack-success");
      nextProps.close("after_save_task");
    }
    return nextProps;
  }

  toggleCalendar = () => {
    if (this.state.isOpen) {
      this.setState({
        flag: "calendar",
        isOpen: false
      });
    } else {
      this.setState({
        flag: "calendar",
        isOpen: true
      });
    }
  };

  handleDateChange = date =>
    this.setState({ deadline: moment(date).format("YYYY-MM-DD") });

  dateChangeHandler = e => {};

  handleSaveTask = () => {
    if (this.state.description.trim() !== "" && this.state.errors["lead_title"] == "") {
      this.setState({submitButton:true}, () => {
      if (this.props.updateTask) {
        const reqParam = {
          id: this.props.updateTask.id,
          lead_id: this.state.leadId,
          description: this.state.description,
          deadline: this.state.deadline,
          is_completed: 0
          //user_id: JSON.parse(localStorage.getItem("user")).user_id
        };
        this.props.editTask(reqParam);
      } else {
        const requestBody = {
          lead_id: this.state.leadId,
          description: this.state.description,
          deadline: this.state.deadline,
          is_completed: 0
          //user_id: JSON.parse(localStorage.getItem("user")).user_id
        };
        this.props.addTask(requestBody);
      }
    });
    } else {
      this.validateAllFields();
    }
  };

  validateAllFields = () => {
    let errors = { ...this.state.errors };
    if (this.state.description.trim() === "") {
      errors.descriptionError = "This field is required";
    }else{
      errors.descriptionError = "";
    }
    this.setState({ errors: errors });
  };

  handleChange = e => {
    if (e && e.target) {
      const name = e.target.name;
      const value = e.target.value;
      this.setState({ [name]: value }, () => {
        this.validateField(name, value);
      });
    } else if (e && e.name) {
      const name = e.name;
      const value = e.value;
      this.setState({ [name]: value }, () => {
        this.validateField(name, value);
      });
    }
  };

  validateField = (fieldName, value) => {
   
    let errors = { ...this.state.errors };
    switch (fieldName) {
      case "description":
        if (value.trim() === "") {
          errors["description"] = "This field is required";
        } else {
          errors["description"] = "";
        }
        break;
      case "leadId":
          if (value === 0 || value === "") {
            errors["lead_title"] = "Please select lead title from suggestions.";
          } else {
            errors["lead_title"] = "";
          }
        break;
      default:
        break;
    }
    this.setState({
      errors: errors
    });
  };

  checkValidLead = async (event, newValue, autocompleteName) => {
    let errors = { ...this.state.errors };
    let leadId = this.state.leadId;
    if (newValue && newValue != "") {
      let selectedType = this.state.leadListing.filter(type => {
        return type.lead_title == newValue;
      });
      if(!selectedType || selectedType.length == 0){
        errors["lead_title"] = "Please select lead title from suggestions.";
        leadId = null;
      }
    } else if (newValue.length === 0) {
      leadId = null;
      errors["lead_title"] = "";
    }
    await this.setState({ leadId: leadId,errors });
  };

  render() {
    const { leadListing } = this.state;
 
    return (
      <div className={styles.add_tasks}>
        <div className="overflow_x_hidden">
        <DialogTitle id="customized-dialog-title" onClose={this.props.close}>
          <div className={styles.add_tasks_dialog_title}>New task</div>
        </DialogTitle>
        <DialogContent className={styles.add_tasks_dialog_content}>
          <div className="row">
            <div className="col-12">
              <div className={styles.form_group}>
                <label className={styles.form_title} htmlFor="description">
                  Task<span className={styles.required_field}>*</span>
                </label>
                <div className="notesHint">{(500 - this.state.description.length) +" characters left"}</div>
                <textarea
                  className={styles.taxtArea}
                  id="description"
                  name="description"
                  value={this.state.description}
                  onChange={this.handleChange}
                  ref={elem => (this.textInput = elem)}
                  maxLength={500}
                />
                <span className={styles.errorMessage}>
                  {this.state.errors.descriptionError}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <label className={styles.form_title} htmlFor="deadline">
                Deadline<span className={styles.required_field}>*</span>
              </label>
              <div className={styles.datepicker + " " + styles.form_group}>
                <input
                  value={this.state.deadline}
                  className={styles.inputStyle + " add_cursor_pointer"}
                  onClick={this.toggleCalendar}
                  onChange={this.dateChangeHandler}
                />
                <DatePicker
                  value={
                    new Date(
                      moment(this.state.deadline).year(),
                      moment(this.state.deadline).month(),
                      moment(this.state.deadline).date()
                    )
                  }
                  clearIcon={null}
                  calendarIcon={null}
                  isOpen={this.state.isOpen}
                  onCalendarClose={this.toggleCalendar}
                  onChange={this.handleDateChange}
                  minDate={new Date()}
                  maxDate={new Date(moment().add(20, 'years'))}
                />
              </div>
              <i className="date-picker-icon" />
            </div>
            <div className="col-12 col-md-6">
              <div className={styles.form_group}>
                <label className={styles.form_title} htmlFor="leadId">
                  Lead title<span className={styles.optional}> (optional)</span>
                </label>
                <div>
                  <AutoComplete
                    suggestions={leadListing}
                    autoCompleteName="leadId"
                    placeholder="Select lead title"
                    value={this.state.leadId}
                    name="leadId"
                    id="leadId"
                    change={this.handleChange}
                    onChange={(event, newValue, autocompleteName) =>
                      this.checkValidLead(event, newValue, autocompleteName)
                    }
                    disabled={this.props.updateTask}
                  />
                </div>
                <span className={styles.errorMessage}>
                  {this.state.errors.lead_title}
                </span>
              </div>
            </div>
          </div>
          <DialogActions>
            {/* {this.state.submitButton ? (
              <div className={styles.submit_loader}>
                <CircularProgress
                  style={{
                    color: "#19D98C"
                  }}
                />
              </div>
            ) : ( */}
              <Button
              disabled={this.state.submitButton}
                className={styles.saveButton}
                onClick={this.handleSaveTask}
                color="primary"
              >
                Save
              </Button>
            {/* )} */}
          </DialogActions>
        </DialogContent>
        </div>
      </div>
    );
  }
}

export default AddTasksComponent;
