import React, { Component } from "react";
import AddActivitiesComponent from "./component/AddActivitiesComponent";
import { connect } from "react-redux";
import * as actions from "./redux/actions";

class AddActivitiesContainer extends Component {
  render() {
    const addActivitiesComponentProps = { ...this.props };
    return <AddActivitiesComponent {...addActivitiesComponentProps} />;
  }
}

const mapStateToProps = state => {
  return {
    activityTypes: state.addActivities.activityTypes,
    companyList: state.asideLeft.companyList,
    addSalesActivity: state.addActivities.addSalesActivity,
    updateSalesActivity: state.addActivities.updateSalesActivity,
    errorMessage: state.addActivities.errorMessage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getActivityTypes: requestBody =>
      dispatch(actions.getActivityTypes(requestBody)),
    addActivity: requestBody => dispatch(actions.addActivity(requestBody)),
    resetAddActivity: () => dispatch(actions.resetAddActivity()),
    updatingActivity: requestBody =>
      dispatch(actions.updatingActivity(requestBody)),
    resetUpdateActivity: () => dispatch(actions.resetUpdateActivity())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddActivitiesContainer);
