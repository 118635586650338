import React, { Component } from "react";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import styles from "./ActivityTaskNote.module.scss";
import moment from "moment";
import axiosInstance from "../../../common/api-config";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import CircularProgress from "../../../common/Loaders/CircularLoader/circular-loader";

const DialogTitle = withStyles(theme => ({
  root: {
    width: "100%",
    margin: 0,
    padding: theme.spacing.unit
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    width: "100%",
    margin: 0,
    padding: "0px 30px 30px 30px"
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    width: "100%",
    borderTop: `0`,
    margin: 0
  }
}))(MuiDialogActions);

class NoteForm extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
  }
  state = {
    notes: "",
    errors: { notes: "" }
  };

  componentDidMount() {
    this.textInput.focus();
  }

  validateAllFields = () => {
    let errors = { ...this.state.errors };
    if (this.state.notes.trim() === "") {
      errors.notes = "This field is required";
    }
    this.setState({ errors: errors });
  };

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  validateField = (fieldName, value) => {
    let errors = { ...this.state.errors };
    switch (fieldName) {
      case "notes":
        if (value.trim() === "") {
          errors["notes"] = "This field is required";
        } else {
          errors["notes"] = "";
        }
        break;
      default:
        break;
    }
    this.setState({
      errors: errors
    });
  };

  handleSaveNote = () => {
    if (this.state.notes !== "") {
      const req = {
        id: this.props.lead_id,
        notes: this.state.notes
      };
      this.setState({ submitButton: true });
      axiosInstance
        .put("/lead/update", req)
        .then(response => {
          if (response && response.data && response.data.statusCode === 200) {
            openSnackbar("Note added successfully.", "snack-success");
            this.props.close({value:"note_added",updatedLead:response.data.data});
          } else {
            openSnackbar(response.data.errorMessage, "snack-error");
            this.props.close("");
          }
        })
        .catch(error => {
          openSnackbar(error.errorMessage, "snack-error");
          this.props.close("");
        });
    } else {
      this.validateAllFields();
    }
  };

  render() {
    return (
      <div>
        <DialogTitle id="customized-dialog-title" onClose={this.props.close} />
        <DialogContent className={styles.add_activity_dialog_content}>
          <div className="row">
            <div className="col-12">
              <div className={styles.form_group}>
                <label className={styles.form_title} htmlFor="notes">
                  Add new note<span className={styles.required_field}>*</span>
                </label>
                <div className="notesHint">{(1024 - this.state.notes.length) +" characters left"}</div>
                <textarea
                  className={styles.textArea}
                  id="notes"
                  name="notes"
                  value={this.state.notes}
                  onChange={this.handleChange}
                  ref={elem => (this.textInput = elem)}
                  maxLength={1024}
                />
                <span className={styles.errorMessage}>
                  {this.state.errors.notes}
                </span>
              </div>
            </div>
          </div>
          <DialogActions className={styles.removeBorder}>
            {this.state.submitButton ? (
              <div className={styles.submit_loader}>
                <CircularProgress
                  style={{
                    color: "#19D98C"
                  }}
                />
              </div>
            ) : (
              <Button
                className={styles.saveButton}
                onClick={this.handleSaveNote}
                color="primary"
              >
                Save
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </div>
    );
  }
}

export default NoteForm;
