import React from 'react';
import { connect } from "react-redux";
import * as actions from "../SalesNews/redux/actions";
import { Grid, Typography, Button } from '@material-ui/core';
import NewsCard from '../../components/NewsCard/NewsCard';
import NoRecordFound from '../../components/NoRecordFound/NoRecordFound'
import PublishedNews from '../SalesNews/PublishNews/PublishNews';
import InDraft from '../SalesNews/InDraft/InDraft';
import Breadcrumbs from "@material-ui/lab/Breadcrumbs";
import { Link } from 'react-router-dom';
import ManageNewsStyle from './ManageNews.module.css'
import { openSnackbar } from '../../common/Snackbar/snackbar';
import { getStorageItem } from "../../common/common-functions";
class ManageNews extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            // currentUser: JSON.parse(localStorage.getItem('user')),
            currentUser: JSON.parse(getStorageItem('user')),
            activeIndex: 0,

        }

    }
    componentDidUpdate = (prevProps, prevState) => {

        if (prevProps.snackbarStatus !== this.props.snackbarStatus) {
            this.props.snackbarStatus && openSnackbar(this.props.snackbarStatus, this.props.callFlag ? "snack-success" : "snack-error")

        }
    }
    componentDidMount() {

        // const params = {
        //     arrayFilters: [
        //         {
        //             "is_active": 1,
        //             "is_deleted": 0,
        //             "created_by": this.state.currentUser.user_id
        //         }
        //     ],
        //     selectFilters: [],
        //     sort: {
        //         field: "id",
        //         sortOrder: "ASC"
        //     },
        //     paginate: {
        //         page: 0,
        //         limit: 10
        //     },
        // }
        // this.props.initializeInDraftSalesNews(params)

    }

    changeTab = (activeIndex) => this.setState({ activeIndex })
    render() {
        const salesNewsProps = {
            ...this.props
        };
        // const currentUser = JSON.parse(localStorage.getItem('user'));
        const currentUser = JSON.parse(getStorageItem('user'));

        return (
            <Grid className={ManageNewsStyle.containerWrap} container>

                <Grid item md={12} lg={12} xs={12} sm={12}>
                    <h2 className={ManageNewsStyle.heading}>MANAGE NEWS</h2>
                </Grid>
                <Grid className={ManageNewsStyle.breadcrumbsWrap} item md={6} lg={6} xs={12} sm={12}>
                    <Breadcrumbs separator="›" arial-label="Breadcrumb">
                        <Link to="/sales-news">Home</Link>
                        <span>Manage news</span>
                        <span>{this.state.activeIndex === 0 ? `Published` : `InDraft`}</span>
                    </Breadcrumbs>
                </Grid>

                {<Grid container justify="flex-end" className={ManageNewsStyle.containerBtn} item md={6} lg={6} xs={6} sm={6}>
                    <Button className={this.state.activeIndex === 0 ? "btn-text active-btn" : "btn-text"} onClick={() => this.changeTab(0)}>
                        Published
                    </Button>
                    <span className={ManageNewsStyle    .separator}>|</span>
                    <Button disableFocusRipple disableRipple className={this.state.activeIndex !== 0 ? "btn-text active-btn" : "btn-text"} onClick={() => this.changeTab(1)}>
                        In draft
                    </Button>
                </Grid>}
                <Grid item md={12} lg={12} xs={12} sm={12}>
                    {
                        this.state.activeIndex === 0 && <PublishedNews  {...salesNewsProps} currentUser={this.state.currentUser} />
                    }
                    {
                        this.state.activeIndex !== 0 && <InDraft {...salesNewsProps} currentUser={this.state.currentUser} />
                    }
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        snackbarStatus: state.salesNews.snackbarStatus,
        errorLoadingSalesNews: state.salesNews.errorLoadingSalesNews,
        salesNews: state.salesNews.salesNews,
        totalRecords: state.salesNews.totalRecords,
        inDraftSalesNews: state.salesNews.inDraftSalesNews,
        loadingSalesNews: state.salesNews.loadingSalesNews,
        callStatus: state.salesNews.callStatus,
        inDraftNewsTotalRecords: state.salesNews.inDraftNewsTotalRecords,
        callFlag: state.salesNews.callFlag
    };
};

const mapDispatchToProps = dispatch => {
    return {
        initializeInDraftSalesNews: requestBody => dispatch(actions.initializeInDraftSalesNews(requestBody)),
        loadMoreSalesNews: requestBody => dispatch(actions.loadMoreSalesNews(requestBody)),
        updatePublishNews: requestBody => dispatch(actions.updatePublishNews(requestBody)),
        publishSalesNews: requestBody => dispatch(actions.publishSalesNews(requestBody)),
        initializeSalesNews: requestBody => dispatch(actions.initializeSalesNews(requestBody)),
        deleteSalesNews: requestBody => dispatch(actions.deleteSalesNews(requestBody)),
        loadMoreInDraftSalesNews: requestBody => dispatch(actions.loadMoreInDraftSalesNews(requestBody))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageNews);
