export const ADD_NEW_PERSON = 'ADD_NEW_PERSON';
export const ADD_NEW_PERSON_FAILED = 'ADD_NEW_PERSON_FAILED';
export const ADD_NEW_PERSON_SUCCESS = 'ADD_NEW_PERSON_SUCCESS';

export const ADD_NEW_COMPANY = 'ADD_NEW_COMPANY';
export const ADD_NEW_COMPANY_FAILED = 'ADD_NEW_COMPANY_FAILED';
export const ADD_NEW_COMPANY_SUCCESS = 'ADD_NEW_COMPANY_SUCCESS';

export const GET_ALL_STATES = 'GET_ALL_STATES';
export const GET_ALL_STATES_ERROR = 'GET_ALL_STATES_ERROR';

export const GET_ALL_CITIES = 'GET_ALL_CITIES';
export const GET_ALL_CITIES_ERROR = 'GET_ALL_CITIES_ERROR';

export const RESET_ALL_STATES_AND_CITIES = 'RESET_ALL_STATES_AND_CITIES';


export const UPDATE_PERSON = 'UPDATE_PERSON';
export const UPDATE_PERSON_FAILED = 'UPDATE_PERSON_FAILED';
export const UPDATE_PERSON_SUCCESS = 'UPDATE_PERSON_SUCCESS';

export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_COMPANY_FAILED = 'UPDATE_COMPANY_FAILED';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';

export const RESET_CONTACT = "RESET_CONTACT";