import React, { Component } from "react";
import "./App.scss";
import { Route, Redirect, Switch} from 'react-router-dom';
import Login from "./app/pages/Login/LoginContainer";
import CompleteSalesNews from "./app/pages/CompleteSalesNews/CompleteSalesNewsConatiner";
import {PrivateRoute} from './app/auth/privateRoute';
import SalesNewsContainer from './app/pages/SalesNews/SalesNewsContainer';
import SalesFeedContainer from './app/pages/SalesFeed/SalesFeedContainer';
import SalesCommunityContainer from './app/pages/SalesCommunity/sales-community-container';
import MyPipelineContainer from './app/pages/MyPipeline/my-pipline-container';
import ActivitiesContainer from './app/pages/Activities/ActivitiesContainer';
import SnackbarComponent from './app/common/Snackbar/snackbar';
import ForgotPassword from './app/pages/ForgotPassword/component/forgotPassword';
import ProfileContainer from './app/pages/Profile/profileContainer';
import ResetPassword from "./app/pages/ResetPassword/ResetPasswordContainer";
import RedirectComponent from "./app/pages/Redirect/redirect";
import ManageNews from './app/pages/ManageNews/ManageNews';
import { openSnackbar } from "./app/common/Snackbar/snackbar";
class App extends Component {
  render() {

    window.addEventListener('load', function() {
      function updateOnlineStatus(event) {
        var condition = navigator.onLine ? "online" : "offline";
        if(condition === "offline"){
          openSnackbar("No internet connection", "snack-error")
        }else{
          openSnackbar("Internet available", "snack-success")
        }
      }

      window.addEventListener('online',  updateOnlineStatus);
      window.addEventListener('offline', updateOnlineStatus);
    });

    return (
      <div>
        <SnackbarComponent/>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword}/>
          <Route exact path="/reset-password/:token" component={ResetPassword}/>
          <Route exact path="/redirect" component={RedirectComponent}/>
          <PrivateRoute path="/sales-news/sales-feed/:id" component={SalesFeedContainer} />
          <PrivateRoute path="/sales-news/:id" component={CompleteSalesNews} />
          <PrivateRoute exact path="/sales-news" component={SalesNewsContainer} />
          <PrivateRoute exact path="/sales-community" component={SalesCommunityContainer} />
          <PrivateRoute exact path="/my-pipeline" component={MyPipelineContainer} />
          <PrivateRoute exact path="/activities" component={ActivitiesContainer} />
          <PrivateRoute exact path="/profile" component={ProfileContainer} />
          {/* <PrivateRoute exact path="/managenews" component={ManageNews} /> */}
          <Route
            path="/"
            render={() => {
              return <Redirect to="/login" />;
            }}
          />
        </Switch>
      </div>
    );
  }
}

export default App;
