import axios from "../../../common/api-config";

export const addTask = requestBody => {
  return dispatch => {
    axios
      .post("/task/add", requestBody)
      .then(response => {
        if (response && response.data && response.data.statusCode === 200) {
          dispatch({ type: "ADD_TASK_SUCCESS", data: response.data.data });
        } else {
          dispatch({
            type: "ADD_TASK_FAILED",
            errorMessage: response.data.errorMessage
          });
        }
      })
      .catch(error => {
        dispatch({
          type: "ADD_TASK_FAILED",
          errorMessage: error.errorMessage
        });
      });
  };
};

export const editTask = requestBody => {
  return dispatch => {
    axios
      .put("/task/update", requestBody)
      .then(response => {
        if (response && response.data && response.data.statusCode === 200) {
          dispatch({ type: "UPDATE_TASK_SUCCESS", data: response.data.data });
        } else {
          dispatch({
            type: "UPDATE_TASK_FAILED",
            errorMessage: response.data.errorMessage
          });
        }
      })
      .catch(error => {
        dispatch({
          type: "UPDATE_TASK_FAILED",
          errorMessage: error.errorMessage
        });
      });
  };
};

export const resetTask = () => {
  return dispatch => {
    dispatch({ type: "RESET_TASK" });
  };
};

export const resetUpdateTask = () => {
  return dispatch => {
    dispatch({ type: "RESET_UPDATE_TASK" });
  };
};
