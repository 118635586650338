import React from "react";
import sound from "../../../../assets/sounds/ring_bell.mp3";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Stepper from "../../../common/Stepper/stepper";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import handshakeIconGrey from "../../../../assets/images/content-images/beforeHandshake.png";
import handshakeIconOne from "../../../../assets/images/content-images/afterHandshake.png";
import handshakeIconTwo from "../../../../assets/images/content-images/handshakeIconTwo.png";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import * as commonFunctions from "../../../common/common-functions";
import * as actions from "../redux/actions";
import { connect } from "react-redux";
import { openSnackbar } from "../../../common/Snackbar/snackbar";
import styles from "./leadDetail.module.scss";
import addIcon from "../../../../assets/add_icon.png";
import Dialog from "@material-ui/core/Dialog";
//import AddActivitiesContainer from "../../AddActivities/AddActivitiesContainer";
import ActivityTaskNote from "../../../components/ActivityTaskNote/component/ActivityTaskNote";
import SalesActivities from "../../../components/SalesActivities/SalesActivities";
import NotesListingContainer from "../../../components/NotesListing/NotesListingContainer";
import axiosInstance from "../../../common/api-config";
import * as actionsNoteListing from "../../../components/NotesListing/redux/actions";
import bell from "../../../../assets/images/content-images/bell.png";

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    //padding: '30px 30px 15px 30px',
    padding: "0px 30px 0px 30px", //theme.spacing.unit * 2,
    //width: "1209px"
    width: "100%",
    position: "relative"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {/* {onClose ? ( */}
      <IconButton
        aria-label="Close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      {/* ) : null}  */}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    paddingBottom: 0,
    overflowX: "hidden",
    //width: "1209px"
    width: "100%"
  }
}))(MuiDialogContent);

class LeadDetailPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addSalesActivities: false,
      value: 0,
      item: null,
      isRingedBell: false
    };
  }

  componentDidMount() {
    this.getNotes();
    if (this.props.currentSalesItem) {
      let params = {
        lead_id: this.props.currentSalesItem.id
      };
      this.props.getStatusLogs(params);

      const requestBodySalesActivitiesTasks = {
        arrayFilters: [
          {
            dateFrom: moment().format("YYYY-MM-DD"),
            dateTo: null,
            lead_id: this.props.currentSalesItem.id
          }
        ],
        selectFilters: [],
        sort: {
          field: "activity_time",
          sortOrder: "ASC"
        },
        paginate: {
          page: 0,
          limit: 1000
        },
        type: "BOTH"
        //user_id: JSON.parse(localStorage.getItem("user")).user_id
      };

      this.props.myPipelineGetPlannedActivities(
        requestBodySalesActivitiesTasks
      );

      const paramsHistoryActivities = {
        arrayFilters: [
          {
            dateFrom: null,
            dateTo: moment().format("YYYY-MM-DD"),
            lead_id: this.props.currentSalesItem.id
          }
        ],
        selectFilters: [],
        sort: {
          field: "activity_time",
          sortOrder: "ASC"
        },
        paginate: {
          page: 0,
          limit: 1000
        },
        type: "BOTH"
        //user_id: JSON.parse(localStorage.getItem("user")).user_id
      };

      this.props.myPipelineGetHistoryActivities(paramsHistoryActivities);
    }
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps && nextProps.ringedBell) {
      nextProps.resetRingedBell();
      openSnackbar("Lead updated successfully.", "snack-success");

      let fromValue =
        window.location.pathname === "/my-pipeline" ? "my_pipeline" : "";
      const filterVals = {};

      if (fromValue === "my_pipeline" && nextProps.parkedSelected) {
        filterVals.is_won = 2;
      }
      if (nextProps.filterList.length !== 0) {
        filterVals.lead_current_status_id = nextProps.filterList;
      }
      let params = {
        // user_id:
        //   nextProps.currentUser && nextProps.currentUser.user_id
        //     ? nextProps.currentUser.user_id
        //     : "",
        from: "my_pipeline",
        arrayFilters: [filterVals],
        selectFilters: [
          "id",
          "lead_title",
          "expected_closing_date",
          "lead_value",
          "is_confidential",
          "notes",
          "is_hand_over",
          "is_won",
          "is_bell_ringed",
          "project_location",
          "created_at"
        ],
        sort: {
          field: "id",
          sortOrder: "DESC"
        },
        paginate: {
          page: fromValue === "sales-community" ? "0" : "",
          limit: fromValue === "sales-community" ? "10" : ""
        }
      };
      nextProps.getPipelineLeadList(params);
    }
    if (nextProps && nextProps.errorMessage) {
      openSnackbar(nextProps.errorMessage, "snack-error");
      nextProps.resetRingedBell();
    }

    return nextProps;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.unparkLeadFlag &&
      prevProps.unparkLeadFlag != this.props.unparkLeadFlag
    ) {
      openSnackbar("Lead unparked successfully.", "snack-success");
      this.props.resetUnparkLeadFlag();
      this.props.openUpdateLeadPopup();
    }
  }

  ringTheBell = async () => {
    var myAudio = document.getElementById("myTune");
    await myAudio.play();
    await this.setState({
      isRingedBell: true
    });

    //notify everyone about win lead by ringing the bell
    let item = this.props.currentSalesItem;
    let updateLeadParams = {
      is_bell_ringed: 1,
      id: item.id,
      // user_id: this.props.currentUser.user_id,
      company_id: item.contact_company ? item.contact_company.id : null
    };
    this.props.updateRingBellLead(updateLeadParams);
    setTimeout(() => {
      this.props.onClose();
    }, 1000);
  };

  handleOpenAddActivity = val => {
    this.setState({ addSalesActivities: true, value: val });
  };

  handleUpdateActivity = item => {
    this.setState({ item: item, addSalesActivities: true, value: 0 });
  };

  handleUpdateTask = item => {
    this.setState({ item: item, addSalesActivities: true, value: 1 });
  };

  handleDeleteTask = id => {
    const req = {
      id: id,
      is_deleted: 1
      //user_id: JSON.parse(localStorage.getItem("user")).user_id
    };
    axiosInstance
      .put("/task/update", req)
      .then(response => {
        if (response && response.data && response.data.statusCode === 200) {
          openSnackbar("Task deleted successfully.", "snack-success");
          this.getPlannedActivities();
        } else {
          openSnackbar("Task deleting failed.", "snack-error");
        }
      })
      .catch(error => {
        openSnackbar("Task deleting failed.", "snack-error");
      });
  };

  handleDeleteActivity = id => {
    const req = {
      id: id,
      is_deleted: 1
      //user_id: JSON.parse(localStorage.getItem("user")).user_id
    };
    axiosInstance
      .put("/salesActivity/update", req)
      .then(response => {
        if (response && response.data && response.data.statusCode === 200) {
          openSnackbar("Activity deleted successfully.", "snack-success");
          this.getPlannedActivities();
        } else {
          openSnackbar("Activity deleting failed.", "snack-error");
        }
      })
      .catch(error => {
        openSnackbar("Activity deleting failed.", "snack-error");
      });
  };

  getPlannedActivities = () => {
    const requestBodySalesActivitiesTasks = {
      arrayFilters: [
        {
          dateFrom: moment().format("YYYY-MM-DD"),
          dateTo: null,
          lead_id: this.props.currentSalesItem.id
        }
      ],
      selectFilters: [],
      sort: {
        field: "activity_time",
        sortOrder: "ASC"
      },
      paginate: {
        page: 0,
        limit: 1000
      },
      type: "BOTH"
      //user_id: JSON.parse(localStorage.getItem("user")).user_id
    };
    this.props.myPipelineGetPlannedActivities(requestBodySalesActivitiesTasks);
  };

  getNotes = () => {
    const req = {
      arrayFilters: [
        {
          note_parent_table: "leads",
          note_id: this.props.currentSalesItem && this.props.currentSalesItem.id
        }
      ],
      selectFilters: [],
      sort: {
        field: "updated_at",
        sortOrder: "DESC"
      },
      paginate: {
        page: "0",
        limit: ""
      }
    };
    this.props.getNotesListing(req);
  };

  handleClose = value => {
    if (value === "save_activity") {
      this.getPlannedActivities();
    }
    if (value.value === "note_added") {
      let currentSalesItem = this.props.currentSalesItem;
      this.setState({
        recentNotes: value.updatedLead[0].notes
      });
      this.getNotes();
    }
    this.setState({ addSalesActivities: false, item: null });
  };

  openUpdateLeadPopup = isParked => {
    if (isParked) {
      //unpark the lead
      let params = {
        is_won: null,
        id: this.props.currentSalesItem.id
      };
      this.props.unparkLead(params);
    } else {
      this.props.openUpdateLeadPopup();
    }
  };

  render() {
    let { currentSalesItem, classes } = this.props;

    return (
      <div className="leadDeatails_popup_wrapper overflow_x_hidden">
        <div className="overflow_x_hidden">
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.props.onClose}
          >
            <div className={styles.lead_dialog + " title_top_padding"}>
              <div className="dialog-title">
                {currentSalesItem ? currentSalesItem.lead_title : ""}
              </div>
              <div className="dialog-company-name title_bottom_padding">
                {currentSalesItem && currentSalesItem.contact_company
                  ? currentSalesItem.contact_company.company_name
                  : ""}
              </div>
            </div>
            <div className={styles.handshakeIconSetion}>
              {currentSalesItem ? (
                currentSalesItem.is_hand_over ? (
                  <div className="handoverIconOnPopup">
                    {currentSalesItem.createdBy &&
                    currentSalesItem.createdBy.license_type &&
                    currentSalesItem.createdBy.license_type.actual_name ==
                      "BASIC" ? (
                      currentSalesItem.is_hand_over == 0 ? (
                        <img src={handshakeIconGrey} width="40" />
                      ) : currentSalesItem.is_hand_over &&
                        currentSalesItem.is_hand_over == 1 ? (
                        <img src={handshakeIconOne} width="40" />
                      ) : currentSalesItem.is_hand_over &&
                        currentSalesItem.is_hand_over == 2 ? (
                        <img src={handshakeIconTwo} width="40" />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                ) : null
              ) : null}
            </div>
          </DialogTitle>
          <DialogContent>
            {currentSalesItem &&
            currentSalesItem.lead_status &&
            currentSalesItem.lead_status.id > 1 ? (
              <div className="popover-breadcrumb pipeline-popover-breadcrumb">
                <div>
                  <span className={styles.stage_label}>Pipeline stage : </span>
                  <span className={styles.stage_name}>
                    {currentSalesItem
                      ? currentSalesItem.lead_status.id == 6
                        ? currentSalesItem.lead_status.name +
                          (currentSalesItem.is_won == 1
                            ? " (Won)"
                            : currentSalesItem.is_won == 0
                            ? " (Lost)"
                            : currentSalesItem.is_won == 2
                            ? " (Parked)"
                            : "")
                        : currentSalesItem.lead_status.name
                        ? currentSalesItem.lead_status.name +
                          (currentSalesItem.is_won == 2 ? " (Parked)" : "")
                        : ""
                      : ""}
                  </span>
                </div>
                <Stepper
                  currentSalesItem={currentSalesItem}
                  statusLogs={this.props.statusLogs}
                />
                {//ring the bell icon
                this.props.currentUser &&
                this.props.currentUser.license_type &&
                (this.props.currentUser.license_type.actual_name ===
                  "PROFESSIONAL" ||
                  this.props.currentUser.license_type.actual_name ===
                    "MANAGER") &&
                (currentSalesItem &&
                  currentSalesItem.lead_status &&
                  currentSalesItem.lead_status.id == 6 &&
                  currentSalesItem.is_won == 1) &&
                (currentSalesItem && currentSalesItem.is_bell_ringed != 1) ? (
                  <div className={"row  " + styles.dialogBtn}>
                    <div className={"col-md-12 " + styles.textCenter}>
                      <audio id="myTune">
                        <source src={sound} />
                      </audio>
                      <button
                        type="button"
                        disabled={this.state.isRingedBell}
                        className={"update-btn " + styles.ringBtn}
                        onClick={() => this.ringTheBell()}
                      >
                        Ring the bell
                        <img
                          src={bell}
                          className={styles.bellIcon}
                          width="40"
                        />
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            <div>
              <div className={"row " + styles.popup_row}>
                <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                  <span className="dialog-content-title">Lead id:</span>
                  <span className="dialog-content-value">
                    {currentSalesItem && currentSalesItem.id
                      ? currentSalesItem.id
                      : " - "}
                  </span>
                </div>
                <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                  <span className="dialog-content-title">
                    Contact person name:
                  </span>
                  <span className="dialog-content-value">
                    {currentSalesItem && currentSalesItem.contact_person
                      ? currentSalesItem.contact_person.name
                      : " - "}
                  </span>
                </div>
                <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                  <span className="dialog-content-title">
                    Contact person phone:
                  </span>
                  <span className="dialog-content-value">
                    {currentSalesItem &&
                    currentSalesItem.contact_person &&
                    currentSalesItem.contact_person.countryCode
                      ? "+" +
                        currentSalesItem.contact_person.countryCode
                          .dialing_code +
                        " " +
                        currentSalesItem.contact_person.phone_number
                      : " - "}{" "}
                  </span>
                </div>
              </div>
              <div className={"row " + styles.popup_row}>
                <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                  <span className="dialog-content-title">
                    Expected closing date:
                  </span>
                  <span className="dialog-content-value">
                    {currentSalesItem &&
                    currentSalesItem.expected_closing_date &&
                    moment(currentSalesItem.expected_closing_date).format(" MMMM D, YYYY") != "Invalid date"
                      ? moment(currentSalesItem.expected_closing_date).format(" MMMM D, YYYY")
                      : " - "}
                  </span>
                </div>

                <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                  <span className="dialog-content-title">Category:</span>
                  <span className="dialog-content-value">
                    {currentSalesItem &&
                    currentSalesItem.category &&
                    currentSalesItem.category.name
                      ? currentSalesItem.category.name
                      : " - "}
                  </span>
                </div>

                <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                  <span className="dialog-content-title">Sales value: </span>
                  {currentSalesItem && currentSalesItem.is_confidential ? (
                    <span className="dialog-content-value">
                      {currentSalesItem
                        ? commonFunctions.getConvertedCurrency(
                            currentSalesItem.lead_value
                          )
                        : " - "}
                      {currentSalesItem && currentSalesItem.currency
                        ? " " + currentSalesItem.currency.short_name
                        : ""}{" "}
                      (Confidential)
                    </span>
                  ) : (
                    <span className="dialog-content-value">
                      {currentSalesItem
                        ? currentSalesItem.lead_value
                          ? commonFunctions.getConvertedCurrency(
                              currentSalesItem.lead_value
                            )
                          : " - "
                        : " - "}
                      {currentSalesItem &&
                      currentSalesItem.currency &&
                      currentSalesItem.lead_value
                        ? " " + currentSalesItem.currency.short_name
                        : ""}
                    </span>
                  )}
                </div>
              </div>

              <div className={"row " + styles.popup_row}>
                <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                  <span className="dialog-content-title">
                    Project location:
                  </span>
                  <span className="dialog-content-value">
                    {currentSalesItem && currentSalesItem.project_location
                      ? currentSalesItem.project_location
                      : " - "}
                  </span>
                </div>
                <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                  <span className="dialog-content-title">Lead creation date:</span>
                  <span className="dialog-content-value">
                  {currentSalesItem && currentSalesItem.created_at
                      ? moment(currentSalesItem.created_at).format(" MMMM D, YYYY")
                      : " - "}
                  </span>
                </div>
                <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                  <span className="dialog-content-title">Lead source:</span>
                  <span className="dialog-content-value">
                    {currentSalesItem && currentSalesItem.lead_source
                      ? currentSalesItem.lead_source.name
                      : " - "}
                  </span>
                </div>
              </div>
              <div className={"row " + styles.popup_row}>
                <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                  <span className="dialog-content-title">Created by:</span>
                  <span className="dialog-content-value">
                    {currentSalesItem && currentSalesItem.createdBy
                      ? currentSalesItem.createdBy.name
                      : " - "}
                  </span>
                </div>
                <div className="col-md-12 col-lg-4 pipeline-popup-grid padd_top_remove">
                  <span className="dialog-content-title">Assigned to:</span>
                  <span className="dialog-content-value">
                    {currentSalesItem && currentSalesItem.assignedTo
                      ? currentSalesItem.assignedTo.name
                      : " - "}
                  </span>
                </div>
              </div>
              <div className={"row " + styles.popup_row}>
                <div className="col-md-12 col-lg-12 pipeline-popup-grid padd_top_remove">
                  <span className="dialog-content-title">
                    Most recent note:
                  </span>
                  <span className="dialog-content-value">
                    {this.state.recentNotes
                      ? this.state.recentNotes
                      : currentSalesItem && currentSalesItem.notes
                      ? " " + currentSalesItem.notes
                      : " - "}
                  </span>
                </div>
              </div>
            </div>

            {//lead has been handovered to sales manager && it is not closed
            (this.props.currentUser &&
            this.props.currentUser.license_type &&
            this.props.currentUser.license_type.actual_name == "MANAGER" &&
            currentSalesItem && //if handover, either created by or assigned to == current user or assigned to should be null
              (((currentSalesItem.is_hand_over == 1 ||
                currentSalesItem.is_hand_over == 2) &&
                ((currentSalesItem &&
                  currentSalesItem.createdBy &&
                  currentSalesItem.createdBy.id ==
                    this.props.currentUser.user_id) ||
                  (currentSalesItem &&
                    currentSalesItem.assignedTo &&
                    currentSalesItem.assignedTo.id ==
                      this.props.currentUser.user_id) ||
                  (currentSalesItem && currentSalesItem.assignedTo == null))) ||
                //if no handover, lead should be created by or assigned to == currentuser
                (!currentSalesItem.is_hand_over &&
                  // (currentSalesItem && currentSalesItem.createdBy && currentSalesItem.createdBy.id == this.props.currentUser.user_id) ||
                  (currentSalesItem &&
                    currentSalesItem.assignedTo.id ==
                      this.props.currentUser.user_id))) &&
              currentSalesItem.lead_status &&
              currentSalesItem.lead_status.id < 6) ||
            //non-sales created lead without handover
            (this.props.currentUser &&
              this.props.currentUser.license_type &&
              this.props.currentUser.license_type.actual_name == "BASIC" &&
              currentSalesItem &&
              (!currentSalesItem.is_hand_over ||
                currentSalesItem.is_hand_over < 1) &&
              (currentSalesItem != undefined &&
                currentSalesItem.createdBy.id ==
                  this.props.currentUser.user_id)) ||
            //for sales - professional, lead update will be available if lead is created by himself
            // OR lead is assigned to himself
            (this.props.currentUser &&
              this.props.currentUser.license_type &&
              this.props.currentUser.license_type.actual_name ==
                "PROFESSIONAL" &&
              //  (currentSalesItem && currentSalesItem.createdBy && currentSalesItem.createdBy.id == this.props.currentUser.user_id) ||
              (currentSalesItem &&
                currentSalesItem.assignedTo &&
                currentSalesItem.assignedTo.id ==
                  this.props.currentUser.user_id) &&
              (currentSalesItem &&
                currentSalesItem.lead_status &&
                currentSalesItem.lead_status.id < 6)) ? (
              <div className="row dialog-btn dialog-btn-bottom">
                <div className="col-md-12 text-right">
                  <button
                    type="button"
                    // className="update-btn"
                    className={`update-btn ${
                      this.props.currentUser.license_type.actual_name ===
                        "BASIC" || currentSalesItem.lead_status.id === 1
                        ? styles.update_btn_margin_space
                        : ""
                    }`}
                    onClick={() =>
                      this.openUpdateLeadPopup(currentSalesItem.is_won == 2)
                    }
                  >
                    {currentSalesItem.is_won == 2 ? "Unpark" : "Update"}
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            {this.props.currentUser ? (
              this.props.currentUser.license_type ? (
                this.props.currentUser.license_type.actual_name !== "BASIC" &&
                currentSalesItem.lead_status.id !== 1 &&
                currentSalesItem.assignedTo &&
                currentSalesItem.assignedTo.id ==
                  this.props.currentUser.user_id ? (
                  <div className={`${styles.container_body} row`}>
                    <div className={`col-xl-4 col-md-6 col-12`}>
                      <div className={styles.activities_container}>
                        <div className={styles.activities_container_header}>
                          <h1 className={styles.activities_container_heading}>
                            Planned events
                          </h1>
                          <div className={styles.activities_container_actions}>
                            {/* <IconButton>
                      <img src={filterIcon} />
                    </IconButton> */}
                            {currentSalesItem ? (
                              currentSalesItem.lead_status.name !== "Closed" ? (
                                <IconButton
                                  style={{
                                    marginTop: "8px",
                                    marginBottom: "8px",
                                    marginLeft: 0,
                                    marginRight: 0
                                  }}
                                  onClick={() => this.handleOpenAddActivity(0)}
                                >
                                  <img src={addIcon} />
                                </IconButton>
                              ) : null
                            ) : null}
                            {/* <IconButton
                      style={{
                        marginTop: "8px",
                        marginBottom: "8px",
                        marginLeft: 0,
                        marginRight: 0
                      }}
                      onClick={this.handleOpenAddActivity}
                    >
                      <img src={addIcon} />
                    </IconButton> */}
                            <Dialog
                              className="width"
                              aria-labelledby="customized-add-activities"
                              open={this.state.addSalesActivities}
                              onClose={this.handleClose}
                              maxWidth={"lg"}
                            >
                              {currentSalesItem && (
                                <ActivityTaskNote
                                  close={this.handleClose}
                                  value={this.state.value}
                                  lead_id={currentSalesItem.id}
                                  company_id={
                                    currentSalesItem.contact_company
                                      ? currentSalesItem.contact_company.id
                                      : ""
                                  }
                                  company_name={
                                    currentSalesItem.contact_company
                                      ? currentSalesItem.contact_company
                                          .company_name
                                      : ""
                                  }
                                  person_id={
                                    currentSalesItem.contact_person
                                      ? currentSalesItem.contact_person.id
                                      : ""
                                  }
                                  person_name={
                                    currentSalesItem.contact_person
                                      ? currentSalesItem.contact_person.name
                                      : ""
                                  }
                                  item={this.state.item}
                                />
                              )}
                            </Dialog>
                          </div>
                        </div>
                        {currentSalesItem && (
                          <SalesActivities
                            startDate={moment().format("YYYY-MM-DD")}
                            endDate={null}
                            salesActivitesTasks={this.props.plannedActivities}
                            from="my-pipeline"
                            errorLoadingSalesActivitesTasks={
                              this.props.errorLoadingPlannedActivites
                            }
                            loadingSalesActivitiesTasks={
                              this.props.loadingPlannedActivities
                            }
                            editDelete={true}
                            leadTitle={false}
                            handleUpdateActivity={this.handleUpdateActivity}
                            handleDeleteActivity={this.handleDeleteActivity}
                            handleUpdateTask={this.handleUpdateTask}
                            handleDeleteTask={this.handleDeleteTask}
                            lead_id={currentSalesItem.id}
                          />
                        )}
                      </div>
                    </div>
                    <div className={`col-xl-4 col-md-6 col-12`}>
                      <div className={styles.activities_container}>
                        <div className={styles.activities_container_header}>
                          <h1 className={styles.activities_container_heading}>
                            History
                          </h1>
                        </div>
                        {currentSalesItem && (
                          <SalesActivities
                            startDate={null}
                            endDate={moment().format("YYYY-MM-DD")}
                            salesActivitesTasks={this.props.historyActivities}
                            from="my-pipeline"
                            errorLoadingSalesActivitesTasks={
                              this.props.errorLoadingHistoryActivites
                            }
                            loadingSalesActivitiesTasks={
                              this.props.loadingHistoryActivities
                            }
                            editDelete={false}
                            leadTitle={false}
                            lead_id={currentSalesItem.id}
                          />
                        )}
                      </div>
                    </div>
                    <div className={`col-xl-4 col-md-6 col-12`}>
                      <div className={styles.activities_container}>
                        <div className={styles.activities_container_header}>
                          <h1 className={styles.activities_container_heading}>
                            Notes
                          </h1>
                          <div className={styles.activities_container_actions}>
                            {currentSalesItem ? (
                              currentSalesItem.lead_status.name !== "Closed" ? (
                                <IconButton
                                  style={{
                                    marginTop: "8px",
                                    marginBottom: "8px",
                                    marginLeft: 0,
                                    marginRight: 0
                                  }}
                                  onClick={() => this.handleOpenAddActivity(2)}
                                >
                                  <img src={addIcon} />
                                </IconButton>
                              ) : null
                            ) : null}
                          </div>
                        </div>
                        {currentSalesItem && (
                          <NotesListingContainer
                            lead_id={currentSalesItem.id}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ) : null
              ) : null
            ) : null}
          </DialogContent>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.leadDetailReducer.errorMessage,
    ringedBell: state.leadDetailReducer.ringedBell,
    isFetching: state.leadDetailReducer.isFetching,
    statusLogs: state.leadDetailReducer.statusLogs,
    plannedActivities: state.leadDetailReducer.plannedActivities,
    historyActivities: state.leadDetailReducer.historyActivities,
    errorLoadingPlannedActivites:
      state.leadDetailReducer.errorLoadingPlannedActivites,
    loadingPlannedActivities: state.leadDetailReducer.loadingPlannedActivities,
    errorLoadingHistoryActivites:
      state.leadDetailReducer.errorLoadingHistoryActivites,
    loadingHistoryActivities: state.leadDetailReducer.loadingHistoryActivities,
    leadUpdated: state.newLead.leadUpdated,
    parkedSelected: state.filterData.parkedSelected,
    filterList: state.filterData.filterValue
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateRingBellLead: requestBody =>
      dispatch(actions.updateRingBellLead(requestBody)),
    resetRingedBell: requestBody =>
      dispatch(actions.resetRingedBell(requestBody)),
    getStatusLogs: requestBody => dispatch(actions.getStatusLogs(requestBody)),
    myPipelineGetPlannedActivities: requestBody =>
      dispatch(actions.myPipelineGetPlannedActivities(requestBody)),
    myPipelineGetHistoryActivities: requestBody =>
      dispatch(actions.myPipelineGetHistoryActivities(requestBody)),
    getNotesListing: requestBody =>
      dispatch(actionsNoteListing.getNotesListing(requestBody))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadDetailPopup);
