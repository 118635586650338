import React, { Component } from "react";
import { connect } from "react-redux";
import ActivitiesComponent from "./component/ActivitiesComponent";
import * as actionsTasks from "../Tasks/redux/actions";
import * as actions from "./redux/actions";

class ActivitiesConatiner extends Component {
  render() {
    const activitiesComponentProps = { ...this.props };
    return <ActivitiesComponent {...activitiesComponentProps} />;
  }
}

const mapStateToProps = state => {
  return {
    loadingSalesActivitiesTasks: state.activities.loadingSalesActivitiesTasks,
    salesActivitesTasks: state.activities.salesActivitesTasks,
    errorLoadingSalesActivitesTasks:
      state.activities.errorLoadingSalesActivitesTasks,
    loadingTasks: state.tasks.loadingTasks
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSalesActivititesTasks: requestBody =>
      dispatch(actions.getSalesActivititesTasks(requestBody)),
    getSalesTasks: requestBody =>
      dispatch(actionsTasks.getSalesTasks(requestBody))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivitiesConatiner);
