import axios from "../../../common/api-config";
import { openSnackbar } from "../../../common/Snackbar/snackbar";

export const userLogout = () => {
  return dispatch => {
    axios
      .post("/login/logout", {})
      .then(response => {
        localStorage.removeItem("user");
        localStorage.removeItem("_access_token_");
        dispatch(userLogoutSuccess());
        openSnackbar("You've been logged out successfully.", "snack-success");
      })
      .catch(error => {
        dispatch(userLogoutFailed(error.errorMessage));
      });
  };
};

const userLogoutFailed = message => {
  return {
    type: "USER_LOGOUT_FAILED",
    errorMessage: message
  };
};

const userLogoutSuccess = () => {
  return {
    type: "USER_LOGOUT_SUCCESS"
  };
};

export const getCompanies = requestBody => {
  return dispatch => {
    axios
      .post("/contact/company/get", requestBody)
      .then(response => {
        dispatch({ type: "COMPANY_LIST_SUCCESS", data: response.data.data });
      })
      .catch(error => {
        dispatch({ type: "COMPANY_LIST_ERROR", data: error.errorMessage });
      });
  };
};

export const getCountries = requestBody => {
  return dispatch => {
    axios
      .post("/master/territory/country", requestBody)
      .then(response => {
        dispatch({ type: "COUNTRY_LIST_SUCCESS", data: response.data.data });
      })
      .catch(error => {
        dispatch({ type: "COUNTRY_LIST_ERROR", data: error.errorMessage });
      });
  };
};

export const getPoints = () => {
  return dispatch => {
    axios
      .post("/bonusPoint/getUserPointsTotal", {})
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: "GET_USER_POINT",
            points:
              response.data.data &&
              response.data.data.pointsData &&
              response.data.data.pointsData.points
                ? parseInt(response.data.data.pointsData.points)
                : 0
          });
        }
      })
      .catch(error => {});
  };
};

export default {
  userLogout,
  getCompanies,
  getCountries,
  getPoints
};
