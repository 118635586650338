import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import styles from './circular-loader.module.scss';

class SmallLoader extends React.Component{
    render(){
        return (
            <CircularProgress color="#17D98B" className={styles.circular_progress} />
        );
    }
}

export default SmallLoader;