const initialState = {
  user: null,
  companyList: [],
  countryList: null,
  companyCount: 0,
  countryCount: 0,
  errorMessage: "",
  points: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_LOGOUT_SUCCESS":
      return {
        ...state,
        user: {},
        errorMessage: ""
      };

    case "USER_LOGOUT_FAILED":
      return {
        ...state,
        user: null,
        errorMessage: action.errorMessage
      };

    case "COMPANY_LIST_SUCCESS":
      return {
        ...state,
        companyList: action.data.rows,
        companyCount: action.data.count,
        errorMessage: ""
      };

    case "COMPANY_LIST_ERROR":
      return {
        ...state,
        companyList: [],
        companyCount: 0,
        errorMessage: action.errorMessage
      };

    case "COUNTRY_LIST_SUCCESS":
      return {
        ...state,
        countryList: action.data.rows,
        countryCount: action.data.count,
        errorMessage: ""
      };

    case "COUNTRY_LIST_ERROR":
      return {
        ...state,
        countryList: null,
        countryCount: 0,
        errorMessage: action.errorMessage
      };
      case "SALES_NEWS_ADD_SUCCESS" :
        return{
          
        };
      case "SALES_NEWS_ADD_ERROR" :
        return{
              
        };

      case "GET_USER_POINT":
        return {
          ...state,
          points: action.points
        };

    default:
      return state;
  }
};

export default reducer;
