import React from "react";
import Dialog from "@material-ui/core/Dialog";
import handshakeIconGrey from "../../../assets/images/content-images/beforeHandshake.png";
import handshakeIconOne from "../../../assets/images/content-images/afterHandshake.png";
import handshakeIconTwo from "../../../assets/images/content-images/handshakeIconTwo.png";
import styles from "./LeadDialog.module.scss";
import moment from "moment";
import * as commonFunctions from "../../common/common-functions";
import Stepper from "../../common/Stepper/stepper";

const leadDialog = props => {
  const { lead, showDialog } = props;
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={showDialog ? showDialog : false}
      onClose={props.hideDialog}
      maxWidth={"lg"}
      className="width"
    >
      <div className={styles.popover_content}>
        <div className={styles.popover_header}>
          <div className="row align-items-center">
            <div className="col-md-10">
              <img
                className="popover-img display-inline"
                src={
                  lead && lead.assignedTo && lead.assignedTo.user_avatar
                    ? lead.assignedTo.user_avatar.slice(
                        0,
                        lead.assignedTo.user_avatar.lastIndexOf("/")
                      ) +
                      "/small_" +
                      lead.assignedTo.user_avatar.substring(
                        lead.assignedTo.user_avatar.lastIndexOf("/") + 1
                      )
                    : require("../../../assets/userProfile.jpeg")
                }
                alt="profile"
                width="40"
                height="40"
              />
              <div className="popover-username display-inline">
                <div className={"popover-content-title " + styles.user_name}>
                  {lead && lead.assignedTo ? lead.assignedTo.name : ""}
                </div>
                <div className="popover-content-value">
                  {lead && lead.assignedTo && lead.assignedTo.role
                    ? lead.assignedTo.role.display_name
                    : ""}
                </div>
              </div>
            </div>
            <div
              className={"col-xs-12 col-md-2 display-inline " + styles.handIcon}
            >
              {lead &&
              lead.createdBy &&
              lead.createdBy.license_type &&
              lead.createdBy.license_type.actual_name == "BASIC" ? (
                lead.is_hand_over === 0 ? (
                  <img src={handshakeIconGrey} width="33" />
                ) : lead.is_hand_over && lead.is_hand_over === 1 ? (
                  <img src={handshakeIconOne} width="33" />
                ) : lead.is_hand_over && lead.is_hand_over === 2 ? (
                  <img src={handshakeIconTwo} width="33" />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className={styles.popover_body}>
          <div className="popover-breadcrumb community-popover-breadcrumb">
            <div className={"pipelineStage_section " + styles.pipeline_border}>
              <span className="pipelineStageLabel">Pipeline stage: </span>
              <span className="pipelineStageValue">
                {lead &&
                lead.lead_status &&
                lead.lead_status.name &&
                lead.lead_status.id !== 6
                  ? " " + lead && lead.lead_status.name
                  : lead &&
                    lead.lead_status &&
                    lead.lead_status.name &&
                    lead.lead_status.id === 6
                  ? " " + lead &&
                    lead.lead_status.name + (lead.is_won ? " (Won)" : " (Lost)")
                  : ""}
              </span>
            </div>
            <Stepper currentSalesItem={lead} statusLogs={props.statusLogs} />
            <div className={"popover-title "+styles.lead_name}>{lead && lead.lead_title}</div>
            <div className={"popover-companyname "+styles.company_name}>
              {lead && lead.contact_company && lead.contact_company.company_name
                ? lead.contact_company.company_name
                : ""}
            </div>
          </div>
          <div className="popover-content">
            <div className="row popover-row">
              <div className="col-xs-12 col-md-6 popover-border">
                <span className="popover-content-title">Lead id: </span>
                <span className="popover-content-value">
                  {lead && lead.id ? lead.id : " - "}
                </span>
              </div>
              <div className="col-xs-12 col-md-6 popover-border">
                <span className="popover-content-title">
                  Expected closing date:{" "}
                </span>
                <span className="popover-content-value">
                  {lead && lead.expected_closing_date
                    ? moment(lead.expected_closing_date).format(" MMMM D, YYYY")
                    : " - "}
                </span>
              </div>
            </div>
            <div className="row popover-row">
              <div className="col-xs-12 col-md-6 popover-border">
                <span className="popover-content-title">Sales value: </span>
                <span className="popover-content-value">
                  {lead && lead.is_confidential ? (
                    "(Confidential)"
                  ) : (
                    <>
                      {
                        lead && lead.lead_value
                        ? commonFunctions.getConvertedCurrency(
                              lead.lead_value
                            ) +
                            " " +
                            (lead.currency && lead.currency.short_name
                              ? " " + lead.currency.short_name
                              : "")
                        : " - "
                      }
                    </>
                  )}
                </span>
              </div>
              <div className="col-xs-12 col-md-6 popover-border">
                <span className="popover-content-title">
                  Project location:{" "}
                </span>
                <span className="popover-content-value">
                  {lead && lead.project_location
                    ? lead.project_location
                    : " - "}
                </span>
              </div>
            </div>
            <div className="row popover-row">
              <div className="col-xs-12 col-md-6 popover-border">
                <span className={"popover-content-title "+styles.popover_content_title}>Created by: </span>
                <span className={"popover-content-value "+styles.assigned_to}>
                  {lead && lead.createdBy ? lead.createdBy.name : ""}
                </span>
              </div>
              <div className="col-xs-12 col-md-6 popover-border">
                <span className={"popover-content-title "+styles.popover_content_title}>Assigned to: </span>
                <span className={"popover-content-value "+styles.assigned_to}>
                  {lead && lead.assignedTo ? lead.assignedTo.name : ""}
                </span>
              </div>
            </div>
            <div className="row popover-row">
              <div className="col-xs-12 col-md-6 popover-border">
                <span className={"popover-content-title "+styles.popover_content_title}>Lead creation date: </span>
                <span className={"popover-content-value "+styles.assigned_to}>
                  {lead && lead.created_at ? moment(lead.created_at).format(" MMMM D, YYYY") : ""}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default leadDialog;
