import React, { Component } from "react";
import { connect } from "react-redux";
import NotesListComponent from "./component/NotesListingComponent";
import actions from "./redux/actions";

class NotesListingContainer extends Component {
  componentDidMount() {
    // const req = {
    //   arrayFilters: [
    //     {
    //       note_parent_table: "leads",
    //       note_id: this.props.lead_id
    //     }
    //   ],
    //   selectFilters: [],
    //   sort: {
    //     field: "id",
    //     sortOrder: "DESC"
    //   },
    //   paginate: {
    //     page: "0",
    //     limit: ""
    //   }
    // };
    // this.props.getNotesListing(req);
  }
  componentWillUnmount() {
    this.props.resetNotesListing();
  }

  render() {
    return (
      <NotesListComponent
        notesList={this.props.notesList}
        errorLoadingNotes={this.props.errorLoadingNotes}
        loadingNotes={this.props.loadingNotes}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    notesList: state.notes.notesList,
    errorLoadingNotes: state.notes.errorLoadingNotes,
    loadingNotes: state.notes.loadingNotes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getNotesListing: requestBody =>
      dispatch(actions.getNotesListing(requestBody)),
    resetNotesListing: requestBody =>
      dispatch(actions.resetNotesListing(requestBody))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotesListingContainer);
