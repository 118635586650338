import * as types from "./types";
import axios from "../../../common/api-config";

export const getCommunityLeadList = requestBody => {
  return dispatch => {
    axios.post("/lead/getList", requestBody)
      .then(async response => {
        if(response && response.data && response.data.statusCode == 200){
          if(requestBody && requestBody.paginate && requestBody.paginate.page === "0"){
            dispatch(leadListSuccess(response.data.data));
          }else{
            await dispatch(loadMoreLeadListSuccess(response.data.data));
          }
        }else{
          dispatch(leadListFailed(response.data.errorMessage));
        }
      })
      .catch(error => {
        dispatch(leadListFailed(error.errorMessage));
      });
  };
};

const leadListFailed = (message) => {
  return {
    type: types.LEAD_LIST_FAILED,
    errorMessage: message
  };
};

const leadListSuccess = data => {
  return {
    type: types.LEAD_LIST_SUCCESS,
    data: data
  };
};

const loadMoreLeadListSuccess = data => {
  return {
    type: 'LOAD_MORE_LEAD_LIST_SUCCESS',
    data
  }
}

export const getRevenue = requestBody => {
  return dispatch => {
    axios.post("/lead/revenueTotal", requestBody)
      .then(response => {
        if(response && response.data && response.data.statusCode == 200){
          dispatch({type:types.GET_REVENUE_SUCCESS, data: response.data.data});
        }else{
          dispatch({type: types.GET_REVENUE_ERROR, data: response.data.errorMessage});  
        }
      })
      .catch(error => {
        dispatch({type: types.GET_REVENUE_ERROR, data: error.errorMessage});
      });
  };
}

export default {
  getCommunityLeadList, getRevenue
};