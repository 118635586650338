import * as types from "./types";

const initialState = {
  communityLeads: [],
  communityLeadsCount: 0,
  revenueData: null,
  errorMessage: '',
  errorLoadingCommunityLeads: false,
  errorLoadingCommunityRevenue: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LEAD_LIST_SUCCESS:
      return { 
        ...state, 
        communityLeads: action.data.rows,
        communityLeadsCount: action.data.count,
        errorLoadingCommunityLeads: false
      };
    case 'LOAD_MORE_LEAD_LIST_SUCCESS':
      let finalArr = [...state.communityLeads, ...action.data.rows];
      return{
        ...state, 
        communityLeads: finalArr,
        communityLeadsCount: action.data.count,
      }
    case types.LEAD_LIST_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        errorLoadingCommunityLeads: true
      }
    case types.GET_REVENUE_SUCCESS:
      return {
        ...state,
        revenueData: action.data,
        errorLoadingCommunityRevenue: false
      }
    case types.GET_REVENUE_ERROR:
      return {
        ...state,
        revenueData: null,
        errorMessage : action.errorMessage,
        errorLoadingCommunityRevenue: true
      }
    default:
      return state;
  }
};

export default reducer;
