import React from 'react';
import styles from './filter.module.scss';
import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";
import { connect } from "react-redux";
import * as actionFilters from "./redux/actions";

class Filter extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            filterValue: 0, // this.props.filterKey && this.props.filterKey === 'my_pipeline' ? [1,2,3,4,5,6] : [2,3,4,5,6],
            showFilters: false,
            selectedParkedFilter: false,
        }
    }

    componentDidMount() {
        this.setState({
            filterValue: 0 // this.props.filterKey && this.props.filterKey === 'my_pipeline' ? [1,2,3,4,5,6] : [2,3,4,5,6],
        });
    }

    componentWillUnmount () {
        this.props.filterSelected(7);
    }

    async filterLeads(status_id) {
        if(!this.props.filterValue.includes(status_id)) {
            this.props.filterSelected(status_id);
        } else {
            this.props.filterDeselected(status_id);
        }
        if (status_id == 0) {  //for all
            await this.setState({
                filterValue: 0, //this.props.filterKey && this.props.filterKey === 'my_pipeline' ? [1,2,3,4,5,6] : [2,3,4,5,6],
                pageIndex: "0",
                selectedParkedFilter: false
            }, () => {
                this.props.parkFlag && this.props.parkFlag(false);
            });
            //get all leads 
            let params = {
                lead_current_status_id: (this.props.filterKey && this.props.filterKey === 'my_pipeline' ? [1, 2, 3, 4, 5, 6] : [2, 3, 4, 5, 6])
            };
            if (this.state.selectedParkedFilter) {
                params.is_won = 2;
            }
            this.props.getLeadListByFilter(params, this.state.pageIndex);

        } else if (status_id == 7) {
            if(this.props.parkedSelected) {
                this.props.parkFilterDeselected();
            } else {
                this.props.parkFilterSelected();
            }
            await this.setState({
                selectedParkedFilter: !this.state.selectedParkedFilter,
                pageIndex: "0",
                filterValue: []
            }, () => {
                this.props.parkFlag && this.props.parkFlag(this.state.selectedParkedFilter);
                if (!this.state.selectedParkedFilter) this.setState({ filterValue: 0 });
            });

            //get eads with selected stages and parked status
            let params = {
                lead_current_status_id: this.state.filterValue == 0 ?
                    (this.props.filterKey && this.props.filterKey === 'my_pipeline' ? [1, 2, 3, 4, 5, 6] : [2, 3, 4, 5, 6])
                    : this.state.filterValue
            }
            if (this.state.selectedParkedFilter) {
                params.is_won = 2;
            }
            this.props.getLeadListByFilter(params, this.state.pageIndex);

        } else if ((Array.isArray(this.state.filterValue) && !this.state.filterValue.includes(status_id)) || this.state.filterValue == 0) {
            let tempFilterValue = [];
            if (this.state.filterValue == 0) {
                await this.setState({
                    filterValue: tempFilterValue.concat(status_id),
                    pageIndex: "0",
                    selectedParkedFilter: false
                }, () => {
                    this.props.parkFlag && this.props.parkFlag(false);
                });
            } else {
                await this.setState({
                    filterValue: this.state.filterValue.concat(status_id),
                    pageIndex: "0",
                    selectedParkedFilter: false
                }, () => {
                    this.props.parkFlag && this.props.parkFlag(false);
                });
            }
            //get leads with selected stages
            let params = { lead_current_status_id: this.state.filterValue }
            if (this.state.selectedParkedFilter) {
                params.is_won = 2;
            }
            this.props.getLeadListByFilter(params, this.state.pageIndex);

        } else {
            let index = this.state.filterValue.indexOf(status_id);
            if (index >= 0) {
                this.state.filterValue.splice(index, 1);
            }
            await this.setState({
                filterValue: this.state.filterValue.length > 0 ? this.state.filterValue : 0,
                pageIndex: "0"
            });
            //get leads with selected stages
            let params = {
                lead_current_status_id:
                    (
                        this.state.filterValue == 0 ?
                            (this.props.filterKey && this.props.filterKey === 'my_pipeline' ? [1, 2, 3, 4, 5, 6] : [2, 3, 4, 5, 6])
                            : this.state.filterValue
                    )
            }
            if (this.state.selectedParkedFilter) {
                params.is_won = 2;
            }
            this.props.getLeadListByFilter(params, this.state.pageIndex);
        }
    }

    toggle = () => {
        this.setState(state => ({ showFilters: !state.showFilters }));
    }

    render() {
        return (
            <div className={styles.filter_section}>
                <div className={styles.filter_header_section} onClick={() => this.toggle()} >
                    Filter by stages
                    {
                        this.state.showFilters ?
                            <FontAwesomeIcon
                                icon={faArrowUp} className={styles.arrow_up}
                            /> :
                            <FontAwesomeIcon
                                icon={faArrowDown} className={styles.arrow_down}
                            />
                    }
                </div>
                <Collapse isOpen={this.state.showFilters}>
                    <div className={styles.filterSection}>
                        <div className={
                            cx(styles.filter, styles.all_filter, {
                                [styles.width_15]: this.props.filterKey && this.props.filterKey === 'my_pipeline',
                                [styles.width_9]: this.props.filterKey && this.props.filterKey === 'sales_community',
                                [styles.selected_all_filter]: this.state.filterValue === 0
                                // (this.props.filterKey && this.props.filterKey === 'my_pipeline' && this.state.filterValue.length == 6) || 
                                //         (this.props.filterKey && this.props.filterKey === 'sales_community' && this.state.filterValue.length == 5 )
                            })}
                            onClick={() => this.filterLeads(0)}>All</div>
                        {
                            this.props.filterKey && this.props.filterKey === 'my_pipeline' ?
                                <div className={
                                    cx(styles.filter, styles.new_filter, {
                                        [styles.width_25]: this.props.filterKey && this.props.filterKey === 'my_pipeline',
                                        [styles.width_17]: this.props.filterKey && this.props.filterKey === 'sales_community',
                                        [styles.selected_new_filter]: Array.isArray(this.state.filterValue) && this.state.filterValue.includes(1) //|| this.state.filterValue.length == 0
                                    })}
                                    onClick={() => this.filterLeads(1)}>New
                                </div>
                                : ''
                        }
                        <div className={
                            cx(styles.filter, styles.lead_filter, {
                                [styles.width_25]: this.props.filterKey && this.props.filterKey === 'my_pipeline',
                                [styles.width_17]: this.props.filterKey && this.props.filterKey === 'sales_community',
                                [styles.selected_lead_filter]: Array.isArray(this.state.filterValue) && this.state.filterValue.includes(2) // || this.state.filterValue.length == 0
                            })}
                            onClick={() => this.filterLeads(2)}>Lead</div>
                        <div className={
                            cx(styles.filter, styles.opportunity_filter, {
                                [styles.width_25]: this.props.filterKey && this.props.filterKey === 'my_pipeline',
                                [styles.width_17]: this.props.filterKey && this.props.filterKey === 'sales_community',
                                [styles.selected_opportunity_filter]: Array.isArray(this.state.filterValue) && this.state.filterValue.includes(3) // || this.state.filterValue.length == 0
                            })}
                            onClick={() => this.filterLeads(3)}>Opportunity</div>
                        <div className={
                            cx(styles.filter, styles.proposal_filter, {
                                [styles.width_22]: this.props.filterKey && this.props.filterKey === 'my_pipeline',
                                [styles.width_17]: this.props.filterKey && this.props.filterKey === 'sales_community',
                                [styles.selected_proposal_filter]: Array.isArray(this.state.filterValue) && this.state.filterValue.includes(4) // || this.state.filterValue.length == 0
                            })}
                            onClick={() => this.filterLeads(4)}>Proposal</div>
                        <div className={
                            cx(styles.filter, styles.negotiation_filter, {
                                [styles.width_25]: this.props.filterKey && this.props.filterKey === 'my_pipeline',
                                [styles.width_17]: this.props.filterKey && this.props.filterKey === 'sales_community',
                                [styles.selected_negotiation_filter]: Array.isArray(this.state.filterValue) && this.state.filterValue.includes(5) // || this.state.filterValue.length == 0
                            })}
                            onClick={() => this.filterLeads(5)}>Negotiation</div>
                        <div className={
                            cx(styles.filter, styles.closed_filter, {
                                [styles.width_22]: this.props.filterKey && this.props.filterKey === 'my_pipeline',
                                [styles.width_17]: this.props.filterKey && this.props.filterKey === 'sales_community',
                                [styles.selected_closed_filter]: Array.isArray(this.state.filterValue) && this.state.filterValue.includes(6) // || this.state.filterValue.length == 0
                            })}
                            onClick={() => this.filterLeads(6)}>Closed</div>
                        {
                            this.props.filterKey && this.props.filterKey === 'my_pipeline' ?
                                <div className={
                                    cx(styles.filter, styles.parked_filter, {
                                        [styles.width_22]: this.props.filterKey && this.props.filterKey === 'my_pipeline',
                                        [styles.selected_parked_filter]: this.state.selectedParkedFilter // || this.state.filterValue.length == 0
                                    })}
                                    onClick={() => this.filterLeads(7)}>Parked</div>
                                : ""
                        }
                    </div>
                </Collapse>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        filterSelected: (num) => { dispatch(actionFilters.filterSelected(num)) },
        filterDeselected: (num) => { dispatch(actionFilters.filterDeselected(num)) },
        parkFilterSelected: () => { dispatch(actionFilters.parkFilterSelected()) },
        parkFilterDeselected: () => { dispatch(actionFilters.parkFilterDeselected()) }
    };
};

const mapStateToProps = state => {
    return {
        filterValue: state.filterData.filterValue,
        parkedSelected: state.filterData.parkedSelected
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);