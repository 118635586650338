import * as types from "./types";
import axios from "../../../common/api-config";

export const resetLead = requestBody => {
  return dispatch => {
    dispatch({ type: 'RESET_LEAD', data: null });
  }
}

export const addLead = requestBody => {
  return async dispatch => {
    await axios.post("/lead/add", requestBody)
      .then(response => {
        if (response && response.data && response.data.statusCode == 200) {
          dispatch(leadAdditionSuccess(response.data.data));
        } else {
          dispatch(leadAdditionFailed(response.data.errorMessage));
        }
      })
      .catch(error => {
        dispatch(leadAdditionFailed(error.errorMessage));
      });
  };
};

const leadAdditionFailed = (message) => {
  return {
    type: types.ADD_NEW_LEAD_FAILED,
    errorMessage: message
  };
};

const leadAdditionSuccess = data => {
  return {
    type: types.ADD_NEW_LEAD_SUCCESS,
    data
  };
};

export const updateLead = requestBody => {
  return async dispatch => {
    await axios.put("/lead/update", requestBody)
      .then(response => {
        if (response && response.data && response.data.statusCode == 200) {
          dispatch({ type: types.UPDATE_NEW_LEAD_SUCCESS, data: response.data.data });
        } else {
          dispatch({ type: types.UPDATE_NEW_LEAD_FAILED, data: response.data.errorMessage });
        }
      })
      .catch(error => {
        dispatch({ type: types.UPDATE_NEW_LEAD_FAILED, data: error.errorMessage });
      });
  };
};


// export const getPersons = requestBody => {
//   return dispatch => {

//   };
// };

// export const resetPersonList = requestBody => {
//   return dispatch => {
//     dispatch({type: 'RESET_PERSON_LIST', data: null});
//   }
// }

export const getLeadSources = requestBody => {
  return dispatch => {
    axios.post('/master/getMstLeadSource', requestBody)
      .then(response => {
        if (response && response.data && response.data.statusCode == 200) {
          dispatch({ type: 'LEAD_SOURCE_LIST_SUCCESS', data: response.data.data });
        } else {
          dispatch({ type: 'LEAD_SOURCE_LIST_ERROR', data: response.data.errorMessage });
        }
      })
      .catch(error => {
        dispatch({ type: 'LEAD_SOURCE_LIST_ERROR', data: error.errorMessage });
      })
  }
}

// get category sources
export const getCategorySources = requestBody => {
  return dispatch => {
    axios.post('/master/getCategories', requestBody)
      .then(response => {
        if (response && response.data && response.data.statusCode == 200) {
          dispatch({ type: 'CATEGORY_SOURCE_LIST_SUCCESS', data: response.data.data });
        } else {
          dispatch({ type: 'CATEGORY_SOURCE_LIST_ERROR', data: response.data.errorMessage });
        }
      })
      .catch(error => {
        dispatch({ type: 'CATEGORY_SOURCE_LIST_ERROR', data: error.errorMessage });
      })
  }
}

export const getCurrencyMaster = requestBody => {
  return dispatch => {
    axios.post('/master/getMstCurrency', requestBody)
      .then(response => {
        if (response && response.data && response.data.statusCode == 200) {
          dispatch({ type: 'CURRENCY_LIST_SUCCESS', data: response.data.data });
        } else {
          dispatch({ type: 'CURRENCY_LIST_ERROR', data: response.data.errorMessage });
        }
      })
      .catch(error => {
        dispatch({ type: 'CURRENCY_LIST_ERROR', data: error.errorMessage });
      })
  }
}

export const getAssignedToList = requestBody => {
  return dispatch => {
    axios.post('/user/getUserList', requestBody)
      .then(response => {
        if (response && response.data && response.data.statusCode == 200) {
          dispatch({ type: 'ASSIGNEDTO_LIST_SUCCESS', data: response.data.data });
        } else {
          dispatch({ type: 'ASSIGNEDTO_LIST_ERROR', data: response.data.errorMessage });
        }
      })
      .catch(error => {
        dispatch({ type: 'ASSIGNEDTO_LIST_ERROR', data: error.errorMessage });
      })
  }
}
export default {
  resetLead, addLead, updateLead, getLeadSources, getAssignedToList
};