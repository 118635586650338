import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import contactIcon from "../../../assets/images/content-images/contact-icon.png";
import companyIcon from "../../../assets/images/content-images/company-icon.png";
import styles from "./SalesActivities.module.scss";
import moment from "moment";
import SmallLoader from "../../common/Loaders/CircularLoader/circular-loader";
import ActivitiesCheckbox from "../../common/UI/ActivitiesCheckbox/ActivitiesCheckbox";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import Popover, { PopoverAnimationVertical } from "@material-ui/core/Popover";
//import { Popover } from "reactstrap";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import NoRecordsFound from "../ErrorMessages/NoRecordsFound/NoRecordsFound";
import OopsError from "../ErrorMessages/OopsError/OopsError";

const stylesMaterialUI = theme => ({
  button: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginLeft: 0,
    marginRight: 0
  }
});

class SalesActivitiesComponent extends Component {
  state = {
    currentItem: null,
    type: ""
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      const requestBodySalesActivitiesTasks = {
        arrayFilters: [
          {
            dateFrom: this.props.startDate,
            dateTo: this.props.endDate
          }
        ],
        selectFilters: [],
        sort: {
          field: "activity_time",
          sortOrder: "ASC"
        },
        paginate: {
          page: 0,
          limit: 1000
        },
        type: "BOTH"
        //user_id: JSON.parse(localStorage.getItem("user")).user_id
      };

      this.props.update(requestBodySalesActivitiesTasks);
    }
  }

  dotsClicked = (e, item, id) => {
    e.preventDefault();
    this.setState({
      [id]: true,
      anchorEl: e.currentTarget,
      currentItem: item,
      type: item.is_type
    });
  };

  handleClose = id => {
    this.setState({
      [id]: undefined,
      currentItem: null,
      type: ""
    });
  };

  handlePopoverMenuClicked = (type, value, id) => {
    if (type === "SALES_ACTIVITY") {
      if (value === "edit") {
        this.props.handleUpdateActivity(this.state.currentItem);
      } else {
        this.props.handleDeleteActivity(this.state.currentItem.id);
      }
    } else if (type === "TASK") {
      if (value === "edit") {
        this.props.handleUpdateTask(this.state.currentItem);
      } else {
        this.props.handleDeleteTask(this.state.currentItem.id);
      }
    }
    this.handleClose(id);
  };

  render() {
    const dates = this.props.salesActivitesTasks
      ? this.props.salesActivitesTasks.map(date => Object.keys(date)[0])
      : null;

    const {
      salesActivitesTasks,
      errorLoadingSalesActivitesTasks,
      loadingSalesActivitiesTasks,
      classes
    } = this.props;

    return (
      <div
        className={styles.activities_container_body}
        style={
          this.props.from === "my-pipeline" ? { maxHeight: "242px" } : null
        }
      >
        {errorLoadingSalesActivitesTasks ? (
          <div>
            <OopsError />
          </div>
        ) : loadingSalesActivitiesTasks ? (
          <SmallLoader />
        ) : salesActivitesTasks ? (
          salesActivitesTasks.length === 0 ? (
            <div>
              <NoRecordsFound />
            </div>
          ) : (
            salesActivitesTasks.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div className={styles.date_container}>
                    <p className={styles.date}>
                      {moment().format("MMMM Do, YYYY") ===
                        moment(dates[index]).format("MMMM Do, YYYY") &&
                      ((this.props.from === "activities" && this.props.today) ||
                        this.props.from === "my-pipeline")
                        ? `Today, ${moment(dates[index]).format(
                            "D MMMM, YYYY"
                          )}`
                        : `${moment(dates[index]).format(
                            "dddd, D MMMM, YYYY"
                          )}`}
                    </p>
                  </div>
                  <div className={styles.activities}>
                    {item[dates[index]].map((activityTask, i) => {
                      if (activityTask.is_type === "SALES_ACTIVITY") {
                        return (
                          <div
                            key={"activityTask_" + i}
                            className={`${styles.activity}
                            ${
                              activityTask.activity_type_id === 1
                                ? styles.meeting
                                : activityTask.activity_type_id === 2
                                ? styles.email
                                : activityTask.activity_type_id === 3
                                ? styles.phone
                                : activityTask.activity_type_id === 4
                                ? styles.other
                                : null
                            } 
                            `}
                          >
                            <h6 className={styles.timing}>
                              {moment(
                                activityTask.activity_time,
                                "HH:mm:ss"
                              ).format("HH:mm")}
                            </h6>
                            <div className={styles.sales_activity}>
                              <div style={{ width: "95%" }}>
                                <h6 className={styles.sales_activity_header}>
                                  {activityTask.activity_type
                                    ? activityTask.activity_type.name
                                    : null}
                                </h6>
                                {this.props.leadTitle ? (
                                  <h6
                                    className={styles.sales_activity_lead_title}
                                  >
                                    {activityTask.lead
                                      ? activityTask.lead.lead_title !== ""
                                        ? activityTask.lead.lead_title
                                        : null
                                      : null}
                                  </h6>
                                ) : null}
                                <div className={styles.contact_detail}>
                                  <div className={styles.contact}>
                                    <img src={companyIcon} />
                                    <h6
                                      className={
                                        this.props.from === "activities"
                                          ? styles.contact_name
                                          : ""
                                      }
                                      title={
                                        activityTask.contact_company &&
                                        activityTask.contact_company
                                          .company_name
                                      }
                                    >
                                      {activityTask.contact_company
                                        ? activityTask.contact_company
                                            .company_name
                                        : null}
                                    </h6>
                                  </div>
                                  <div className={styles.contact}>
                                    {activityTask.contact_person ? (
                                      <img src={contactIcon} />
                                    ) : null}
                                    <h6
                                      className={
                                        this.props.from === "activities"
                                          ? styles.contact_name
                                          : ""
                                      }
                                      title={
                                        activityTask.contact_person &&
                                        activityTask.contact_person.name
                                      }
                                    >
                                      {activityTask.contact_person
                                        ? activityTask.contact_person.name
                                        : null}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                              {this.props.editDelete ? (
                                <div style={{ position: "relative" }}>
                                  <IconButton
                                    onClick={e =>
                                      this.dotsClicked(
                                        e,
                                        activityTask,
                                        activityTask.id
                                      )
                                    }
                                  >
                                    <span className={styles.dots} />
                                    <span className={styles.dots} />
                                    <span className={styles.dots} />
                                  </IconButton>
                                  <Popover
                                    open={
                                      this.state[`${activityTask.id}`] ===
                                      undefined
                                        ? false
                                        : this.state[`${activityTask.id}`]
                                    }
                                    anchorEl={this.state.anchorEl}
                                    targetorigin={{
                                      vertical: "top",
                                      horizontal: "left"
                                    }}
                                    onClose={() =>
                                      this.handleClose(`${activityTask.id}`)
                                    }
                                  >
                                    <MenuList>
                                      {activityTask.lead &&
                                      activityTask.lead
                                        .lead_current_status_id === 6 ? null : (
                                        <MenuItem
                                          onClick={() =>
                                            this.handlePopoverMenuClicked(
                                              this.state.type,
                                              "edit",
                                              activityTask.id
                                            )
                                          }
                                        >
                                          <span className={styles.editicon} />
                                          Edit
                                        </MenuItem>
                                      )}
                                      <MenuItem
                                        onClick={() =>
                                          this.handlePopoverMenuClicked(
                                            this.state.type,
                                            "delete",
                                            activityTask.id
                                          )
                                        }
                                      >
                                        <span className={styles.deleteicon} />
                                        Delete
                                      </MenuItem>
                                    </MenuList>
                                  </Popover>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        );
                      } else {
                        if (Object.keys(activityTask)[0] === "NO_LEAD") {
                          return (
                            <div
                              className={styles.tasks}
                              key={"activityTask_" + i}
                            >
                              {activityTask[Object.keys(activityTask)[0]].map(
                                task => {
                                  return (
                                    <div key={task.id} className={styles.task}>
                                      <ActivitiesCheckbox
                                        task={task}
                                        startDate={this.props.startDate}
                                        endDate={this.props.endDate}
                                        from={this.props.from}
                                      />
                                      {this.props.editDelete ? (
                                        <div>
                                          <IconButton
                                            onClick={e =>
                                              this.dotsClicked(
                                                e,
                                                task,
                                                `${task.id}_activity`
                                              )
                                            }
                                          >
                                            <span className={styles.dots} />
                                            <span className={styles.dots} />
                                            <span className={styles.dots} />
                                          </IconButton>
                                          <Popover
                                            open={
                                              this.state[
                                                `${task.id}_activity`
                                              ] === undefined
                                                ? false
                                                : this.state[
                                                    `${task.id}_activity`
                                                  ]
                                            }
                                            anchorEl={this.state.anchorEl}
                                            targetorigin={{
                                              vertical: "top",
                                              horizontal: "left"
                                            }}
                                            onClose={() =>
                                              this.handleClose(
                                                `${task.id}_activity`
                                              )
                                            }
                                            animation={PopoverAnimationVertical}
                                          >
                                            <MenuList>
                                              {!task.is_completed ? (
                                                <MenuItem
                                                  onClick={() =>
                                                    this.handlePopoverMenuClicked(
                                                      this.state.type,
                                                      "edit",
                                                      `${task.id}_activity`
                                                    )
                                                  }
                                                >
                                                  <span
                                                    className={styles.editicon}
                                                  />
                                                  Edit
                                                </MenuItem>
                                              ) : null}
                                              <MenuItem
                                                onClick={() =>
                                                  this.handlePopoverMenuClicked(
                                                    this.state.type,
                                                    "delete",
                                                    `${task.id}_activity`
                                                  )
                                                }
                                              >
                                                <span
                                                  className={styles.deleteicon}
                                                />
                                                Delete
                                              </MenuItem>
                                            </MenuList>
                                          </Popover>
                                        </div>
                                      ) : null}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          );
                        } else {
                          if (this.props.from === "activities") {
                            return (
                              <div
                                className={styles.tasks}
                                key={"activityTask_" + i}
                              >
                                <div className={styles.tasks_with_lead}>
                                  <p className={styles.lead_name}>
                                    {Object.keys(activityTask)[0]}
                                  </p>
                                  {activityTask[
                                    Object.keys(activityTask)[0]
                                  ].map(task => {
                                    return (
                                      <div
                                        className={styles.task}
                                        key={task.id}
                                      >
                                        <ActivitiesCheckbox
                                          task={task}
                                          startDate={this.props.startDate}
                                          endDate={this.props.endDate}
                                          from={this.props.from}
                                        />
                                        {this.props.editDelete ? (
                                          <div>
                                            <IconButton
                                              onClick={e =>
                                                this.dotsClicked(
                                                  e,
                                                  task,
                                                  `${task.id}_activity`
                                                )
                                              }
                                            >
                                              <span className={styles.dots} />
                                              <span className={styles.dots} />
                                              <span className={styles.dots} />
                                            </IconButton>
                                            <Popover
                                              open={
                                                this.state[
                                                  `${task.id}_activity`
                                                ] === undefined
                                                  ? false
                                                  : this.state[
                                                      `${task.id}_activity`
                                                    ]
                                              }
                                              anchorEl={this.state.anchorEl}
                                              targetorigin={{
                                                vertical: "top",
                                                horizontal: "left"
                                              }}
                                              onClose={() =>
                                                this.handleClose(
                                                  `${task.id}_activity`
                                                )
                                              }
                                              animation={
                                                PopoverAnimationVertical
                                              }
                                            >
                                              <MenuList>
                                                {!task.is_completed &&
                                                !(
                                                  task.lead &&
                                                  task.lead
                                                    .lead_current_status_id ===
                                                    6
                                                ) ? (
                                                  <MenuItem
                                                    onClick={() =>
                                                      this.handlePopoverMenuClicked(
                                                        this.state.type,
                                                        "edit",
                                                        `${task.id}_activity`
                                                      )
                                                    }
                                                  >
                                                    <span
                                                      className={
                                                        styles.editicon
                                                      }
                                                    />
                                                    Edit
                                                  </MenuItem>
                                                ) : null}
                                                <MenuItem
                                                  onClick={() =>
                                                    this.handlePopoverMenuClicked(
                                                      this.state.type,
                                                      "delete",
                                                      `${task.id}_activity`
                                                    )
                                                  }
                                                >
                                                  <span
                                                    className={
                                                      styles.deleteicon
                                                    }
                                                  />
                                                  Delete
                                                </MenuItem>
                                              </MenuList>
                                            </Popover>
                                          </div>
                                        ) : null}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          } else if (this.props.from === "my-pipeline") {
                            return (
                              <div
                                className={styles.tasks}
                                key={"activityTask_" + i}
                              >
                                {activityTask[Object.keys(activityTask)[0]].map(
                                  task => {
                                    return (
                                      <div
                                        className={styles.task}
                                        key={task.id}
                                      >
                                        <ActivitiesCheckbox
                                          task={task}
                                          startDate={this.props.startDate}
                                          endDate={this.props.endDate}
                                          from={this.props.from}
                                          lead_id={this.props.lead_id}
                                        />
                                        {this.props.editDelete ? (
                                          <div>
                                            <IconButton
                                              onClick={e =>
                                                this.dotsClicked(
                                                  e,
                                                  task,
                                                  `${task.id}_activity`
                                                )
                                              }
                                            >
                                              <span className={styles.dots} />
                                              <span className={styles.dots} />
                                              <span className={styles.dots} />
                                            </IconButton>
                                            <Popover
                                              open={
                                                this.state[
                                                  `${task.id}_activity`
                                                ] === undefined
                                                  ? false
                                                  : this.state[
                                                      `${task.id}_activity`
                                                    ]
                                              }
                                              anchorEl={this.state.anchorEl}
                                              targetorigin={{
                                                vertical: "top",
                                                horizontal: "right"
                                              }}
                                              onClose={() =>
                                                this.handleClose(
                                                  `${task.id}_activity`
                                                )
                                              }
                                              animation={
                                                PopoverAnimationVertical
                                              }
                                            >
                                              <MenuList>
                                                {!task.is_completed &&
                                                !(
                                                  task.lead &&
                                                  task.lead
                                                    .lead_current_status_id ===
                                                    6
                                                ) ? (
                                                  <MenuItem
                                                    onClick={() =>
                                                      this.handlePopoverMenuClicked(
                                                        this.state.type,
                                                        "edit",
                                                        `${task.id}_activity`
                                                      )
                                                    }
                                                  >
                                                    <span
                                                      className={
                                                        styles.editicon
                                                      }
                                                    />
                                                    Edit
                                                  </MenuItem>
                                                ) : null}
                                                <MenuItem
                                                  onClick={() =>
                                                    this.handlePopoverMenuClicked(
                                                      this.state.type,
                                                      "delete",
                                                      `${task.id}_activity`
                                                    )
                                                  }
                                                >
                                                  <span
                                                    className={
                                                      styles.deleteicon
                                                    }
                                                  />
                                                  Delete
                                                </MenuItem>
                                              </MenuList>
                                            </Popover>
                                          </div>
                                        ) : null}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            );
                          }
                        }
                      }
                    })}
                  </div>
                </React.Fragment>
              );
            })
          )
        ) : null}
      </div>
    );
  }
}

export default withStyles(stylesMaterialUI)(SalesActivitiesComponent);
