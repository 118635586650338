import React, { Component } from 'react';

class DirectWin extends Component {
    render() {
        return (
            <div>
                <p>Lead-title is created & won successfully</p>
                <button>Ring the bel</button>
            </div>
        );
    }
}

export default DirectWin;