import React from 'react';
import { Snackbar } from '@material-ui/core';
import './snackbar.css';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

let openSnackbarFn;

class SnackbarComponent extends React.Component {
      state = {
            open: false,
            message: '',
            className: ''
        };

  componentDidMount() {
    openSnackbarFn = this.openSnackbar;
  }

  openSnackbar = ( message , className,timmer) => {
    this.setState({
      open: true,
      message,
      className,
      timmer:timmer ? timmer : 5000
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      open: false,
      message: '',
      className : ''
    });
  };

  render() {
    const message = (
      <span
        id="snackbar-message-id"
        dangerouslySetInnerHTML={{ __html: this.state.message }}
      />
    );

    return (
      <Snackbar
      style={{zIndex:77777777777777}}
        className={this.state.className}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        message={this.state.message}
        autoHideDuration={this.state.timmer}
        onClose={this.handleSnackbarClose}
        open={this.state.open}
        snackbarcontentprops={{
          'aria-describedby': 'snackbar-message-id',
        }}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.handleSnackbarClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  }
}

export function openSnackbar( message, className,timmer, callback ) {
  openSnackbarFn( message, className,timmer );
  callback && callback();
}

export default SnackbarComponent;