import React, { Component } from 'react'
import TasksComponent from './component/TasksComponent';
import { connect } from 'react-redux';
import * as actions from "./redux/actions";

class TasksContainer extends Component {
  render() {
      const taskComponentProps = {...this.props}
    return (
        <TasksComponent {...taskComponentProps} />
    )
  }
}

const mapStateToProps = state => {
    return {
        salesTasks: state.tasks.salesTasks,
        errorLoadingSalesTasks: state.tasks.errorLoadingSalesTasks,
        loadingTasks: state.tasks.loadingTasks
    };
}

const mapDispatchToProps = dispatch => {
    return {
        getSalesTasks: requestBody => dispatch(actions.getSalesTasks(requestBody))
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(TasksContainer);