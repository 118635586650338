import * as types from "./types";
import axios from "../../../common/api-config";
import { setStorageItem } from "../../../common/common-functions";



export const userLogin = requestBody => {
  return dispatch => {
    axios
      .post("/login/checkAuth", requestBody)
      .then(response => {
        if (response && response.data && response.data.statusCode == 200) {
          let tokenData = response.data.data.data;
          localStorage.setItem('_access_token_', tokenData.token_type + " " + tokenData.access_token);
          // setStorageItem('_access_token_', tokenData.token_type + " " + tokenData.access_token)
          dispatch(userLoginSuccess(response.data.data.user));
        } else {
          dispatch(userLoginFailed(response.data.errorMessage));
        }
      })
      .catch(error => {
        dispatch(userLoginFailed(error.errorMessage));
      });
  };
};

const userLoginFailed = message => {
  return {
    type: types.USER_LOGIN_FAILED,
    errorMessage: message
  };
};

const userLoginSuccess = (data) => {
  return {
    type: types.USER_LOGIN_SUCCESS,
    data
  };
};

export const resetUserLogin = () => {
  return {
    type: types.RESET_USER_LOGIN
  };
};

export default {
  userLogin
};


export const userLogout = () => {
  axios.post("/login/logout", {})
  .then(response => {
    if(localStorage.getItem('user') || localStorage.getItem("_access_token_")) {
      localStorage.removeItem('user');
      localStorage.removeItem("_access_token_");
    }
  })
  .catch(error => {
  });
 
};

const userLogoutFailed = (message) => {
  return {
    type: 'USER_LOGOUT_FAILED',
    errorMessage: message
  };
};

const userLogoutSuccess = () => {
  return {
    type: 'USER_LOGOUT_SUCCESS'
  };
};