const initialState = {
  ringedBell: null,
  errorMessage: "",
  isFetching: false,
  statusLogs: null,
  plannedActivities: null,
  errorLoadingPlannedActivites: false,
  loadingPlannedActivities: true,
  historyActivities: null,
  errorLoadingHistoryActivites: false,
  loadingHistoryActivities: true
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "RING_BELL_SUCCESS":
      return {
        ...state,
        ringedBell: action.data,
        errorMessage: ""
      };
    case "RING_BELL_ERROR":
      return {
        ...state,
        ringedBell: null,
        errorMessage: action.errorMessage
      };
    case "RESET_RINGED_BELL":
      return {
        ...state,
        ringedBell: null,
        errorMessage: null,
        isFetching: false
      };
    case "GET_STATUS_LOGS_SUCCESS":
      return {
        ...state,
        statusLogs: action.data,
        errorMessage: null
      };
    case "GET_STATUS_LOGS_ERROR":
      return {
        ...state,
        statusLogs: null,
        errorMessage: action.errorMessage
      };

    case "MY_PIPELINE_PLANNED_ACTIVITIES_SUCCESS":
      return {
        ...state,
        plannedActivities: action.data,
        errorLoadingPlannedActivites: false,
        loadingPlannedActivities: false
      };

    case "MY_PIPELINE_PLANNED_ACTIVITIES_FAIL":
      return {
        ...state,
        plannedActivities: null,
        errorLoadingPlannedActivites: true,
        loadingPlannedActivities: false
      };

    case "LOADING_PLANNED_ACTIVITIES":
      return {
        ...state,
        plannedActivities: null,
        errorLoadingPlannedActivites: false,
        loadingPlannedActivities: true
      };

    case "MY_PIPELINE_HISTORY_ACTIVITIES_SUCCESS":
      return {
        ...state,
        historyActivities: action.data,
        errorLoadingHistoryActivites: false,
        loadingHistoryActivities: false
      };

    case "MY_PIPELINE_HISTORY_ACTIVITIES_FAIL":
      return {
        ...state,
        historyActivities: null,
        errorLoadingHistoryActivites: true,
        loadingHistoryActivities: false
      };

    case "LOADING_HISTORY_ACTIVITIES":
      return {
        ...state,
        historyActivities: null,
        errorLoadingHistoryActivites: false,
        loadingHistoryActivities: true
      };

    default:
      return state;
  }
};

export default reducer;
